import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import Tooltip from '@material-ui/core/Tooltip';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles } from '../../../styles/AppTheme';
import { StatCard } from '../../../common/components/StatCard';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { Controller } from '../../../common/Controller';

function countUnique(enrolments, key) {
  const numUnique = enrolments.reduce((p, c) => p + ((c[key] || 0) > 0 ? 1 : 0), 0)
  return numUnique
}

function getUniquePercent(value, total) {
  let numUniquePercent = Math.round((value / total) * 100)
  numUniquePercent = isNaN(numUniquePercent) ? '0%' : `${numUniquePercent}%`
  return numUniquePercent
}

export class CrmSequenceEnrolments extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-sequence-enrolments')
    this.campaignId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-sequences'), to: `${this.baseRoute()}/campaigns/sequences` },
    ]

    this.toFetch = [
      { key: 'campaign', ms: this.ms, method: 'GET', paths: ['campaigns', this.campaignId], queryParams: { orgId: this.orgId, pb: 'fieldDefCampaignDripSteps', limit: 1 } },
      { key: 'crminboxes', ms: this.ms, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      { key: 'contactenrolments', ms: this.ms, method: 'GET', paths: ['miscs', 'get-enrolled-contacts'], queryParams: { orgId: this.orgId, campaignId: this.campaignId, includeEnrolments: 'true' } },
    ]
    this.pageKey = 'crm-sequence-enrolments'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        const crmContact = this.crmContacts.find(c => c.userId === current.userId)
        const crmInbox = this.crmInboxes.find(i => i.id === current.inboxId)
        const steps = crmContact.enrolmentSlots && crmContact.enrolmentSlots.steps ? Object.values(crmContact.enrolmentSlots.steps) : []
        let infoText = ''
        if (current.state === 'COMPLETED' && Array.isArray(current.stepsExecuted) && current.stepsExecuted.length > 0) {
          infoText = 'Completed on: ' + Helper.formatTimestamp(Math.max(...current.stepsExecuted.map(s => s.createdTs)))
        } else if (['ERROR', 'PAUSED'].includes(current.state)  && Array.isArray(current.stepsExecuted) && current.stepsExecuted.length > 0) {
          infoText = 'Last email sent on: ' + Helper.formatTimestamp(Math.max(...current.stepsExecuted.map(s => s.createdTs)))
        }
        if (!infoText && steps.length > 0) {
          const startDate = Math.min(...steps)
          const endDate = Math.max(...steps)
          if (Array.isArray(current.stepsExecuted) && current.stepsExecuted.length > 0) {
            infoText = 'Complete by: ' + Helper.formatTimestamp(Helper.getNextHourTs(endDate))
          } else if (current.cstate === 'LIVE' && current.state === 'LIVE') {
            infoText = 'Start by: ' + Helper.formatTimestamp(Helper.getNextHourTs(startDate))
          }
        }
        if (!infoText) {
          infoText = 'Created: ' + Helper.formatTimestamp(current.createdTs)
        }
        infoText = infoText + ` | Sending inbox: ${crmInbox ? crmInbox.email : current.inboxId}`
        return <div>
          {crmContact && <span className={css(Styles.line1)}>{`${Helper.formatName(crmContact)} <${crmContact.email}>`}</span>}
          {crmContact && <br/>}
          <span className={css(Styles.line2)}>{infoText}</span>
        </div>
      },
      Status: (current) => {
        const emailClickTrail = UiHelper.renderEmailClickTrail(current.emailClickTrail || [])
        return <div>
          <span className={css(Styles.line1)}>{current.state}</span>
          {current.state === 'COMPLETED' && current.completionReason && current.completionReason.trim() && <span className={css(Styles.line2)}>&nbsp;[{current.completionReason}]</span>}
          <br/>
          <span className={css(Styles.line2)}>Sent: {current.stepsExecuted.length}</span>
          <span className={css(Styles.line2)}>{'\u00A0'}|{'\u00A0'}</span>
          <span className={css(Styles.line2)}>Open: {current.emailOpenCount || 0}</span>
          <span className={css(Styles.line2)}>{'\u00A0'}|{'\u00A0'}</span>
          <span title={emailClickTrail} className={css(Styles.line2)}>Click: {current.emailClickCount || 0}</span>
          <span className={css(Styles.line2)}>{'\u00A0'}|{'\u00A0'}</span>
          <span className={css(Styles.line2)}>Reply: {current.emailReplyCount || 0}</span>
          <span className={css(Styles.line2)}>{'\u00A0'}|{'\u00A0'}</span>
          <span className={css(Styles.line2)}>Bounce: {current.emailBounceCount || 0}</span>
          {current.emailOpenCountUpdateTs > 0 && <><br/><span className={css(Styles.line2)}>Last opened on {Helper.formatTimestamp(current.emailOpenCountUpdateTs)}</span></>}
        </div>
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.noAdd = true
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems(),
      }
    ]
  }

  async onFetchSuccess(results) {
    this.campaign = this.getResult(results, 'campaign')
    this.enrolments = this.getResult(results, 'contactenrolments').enrolments
    this.crmContacts = this.getResult(results, 'contactenrolments').contacts
    this.crmInboxes = this.getResult(results, 'crminboxes').items
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-sequences'), to: `${this.baseRoute()}/campaigns/sequences` },
      { title: this.campaign.title },
      { title: Helper.getString('crm-sequence-enrolments') },
    ]

    this.setState({
      items: this.enrolments.sort((a, b) => b.enrolmentStartTs - a.enrolmentStartTs),
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  async updateEnrolmentState(current, newState) {
    this.apiData = {
      orgId: this.orgId,
    }
    const fields = [{
      key: 'state',
      label: 'New enrolment state',
      type: 'edit_text',
      // eslint-disable-next-line camelcase
      v_required: { value: true, err: 'Provide new state' },
      placement: 'left',
      value: newState,
      defaultValue: newState,
      disabled: true,
    }]
    const currentCp = JSON.parse(JSON.stringify(current))
    currentCp.state = newState
    if (newState === 'COMPLETED') {
      this.apiData.completionReason = 'STOPPED'
      currentCp.completionReason = 'STOPPED'
    }
    super.openModalForEdit(currentCp, {steps: [{ fields }]});
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['seqenrolments', current.id], queryParams: {orgId: this.orgId} }),
    });
  }

  renderAboveTable() {
    const numEnrolments = this.enrolments.length
    const numLiveEnrolments = this.enrolments.reduce((p, c) => p + (c.state === 'LIVE' ? 1 : 0), 0)
    const numAllStepsDone = this.enrolments.reduce((p, c) => p + (c.state === 'COMPLETED' && ['ALL_STEPS_DONE', 'SUCCESS'].includes(c.completionReason) ? 1 : 0), 0)
    const numUnenrols = this.enrolments.reduce((p, c) => p + (c.state === 'COMPLETED' && c.completionReason && c.completionReason.includes('UNENROL') ? 1 : 0), 0)
    const numSent = this.enrolments.reduce((p, c) => {
      const emailSteps = (c.stepsExecuted || []).filter(s => s.action === 'SEND_EMAIL')
      return p + emailSteps.length
    }, 0)
    const numOpened = this.enrolments.reduce((p, c) => p + (c.emailOpenCount || 0), 0)
    const numClicked = this.enrolments.reduce((p, c) => p + (c.emailClickCount || 0), 0)
    const numReplied = this.enrolments.reduce((p, c) => p + (c.emailReplyCount || 0), 0)
    const numBounced = this.enrolments.reduce((p, c) => p + (c.emailBounceCount || 0), 0)
    const numUniqueSent = this.enrolments.reduce((p, c) => {
      const emailSteps = (c.stepsExecuted || []).filter(s => s.action === 'SEND_EMAIL')
      return p + (emailSteps.length > 0 ? 1 : 0)
    }, 0)
    const numUniqueOpened = countUnique(this.enrolments, 'emailOpenCount')
    const numUniqueOpenedPercent = getUniquePercent(numUniqueOpened, numUniqueSent)
    const numUniqueClicked = countUnique(this.enrolments, 'emailClickCount')
    const numUniqueClickedPercent = getUniquePercent(numUniqueClicked, numUniqueSent)
    const numUniqueReplied = countUnique(this.enrolments, 'emailReplyCount')
    const numUniqueRepliedPercent = getUniquePercent(numUniqueReplied, numUniqueSent)
    const numUniqueBounced = countUnique(this.enrolments, 'emailBounceCount')
    const numUniqueBouncedPercent = getUniquePercent(numUniqueBounced, numUniqueSent)

    let campaignStartDate = Helper.currentTs(), campaignEndDate = -1, isExceedingEndDate = false
    this.crmContacts.forEach(crmContact => {
      const steps = crmContact.enrolmentSlots && crmContact.enrolmentSlots.steps ? Object.values(crmContact.enrolmentSlots.steps) : []
      if (steps.length > 0) {
        const startDate = Math.min(...steps)
        const endDate = Math.max(...steps)
        campaignStartDate = Math.min(campaignStartDate, startDate)
        campaignEndDate = Math.max(campaignEndDate, endDate)
        isExceedingEndDate = isExceedingEndDate || steps.includes(-1)
      }
    })

    return <>
      <div className={css(Styles.aboveTableContainer)}>
        <Grid container className={css(Styles.numericStatContainer)}>
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'enrolments'}
            text={numEnrolments}
            subText={'Enrolments'}
            cardIndex={1}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueSent'}
            text={numUniqueSent}
            subText={'Unique Sent'}
            cardIndex={1}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'liveEnrolments'}
            text={numLiveEnrolments}
            subText={'Live'}
            cardIndex={2}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'allStepsDone'}
            text={numAllStepsDone}
            subText={'All Steps Done'}
            cardIndex={3}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'numUnenrols'}
            text={numUnenrols}
            subText={'Unenrols'}
            cardIndex={4}
            padding='small'
          />
        </Grid>
        <Grid container className={css(Styles.numericStatContainer)}>
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueOpened'}
            text={numUniqueOpened}
            subText={'Unique Opened'}
            cardIndex={2}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueClicked'}
            text={numUniqueClicked}
            subText={'Unique Clicked'}
            cardIndex={3}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueReplied'}
            text={numUniqueReplied}
            subText={'Unique Replied'}
            cardIndex={4}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueBounced'}
            text={numUniqueBounced}
            subText={'Unique Bounced'}
            cardIndex={5}
            padding='small'
          />
        </Grid>
        <Grid container className={css(Styles.numericStatContainer)}>
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueOpenedPercent'}
            text={numUniqueOpenedPercent}
            subText={'Open Rate'}
            cardIndex={2}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueClickedPercent'}
            text={numUniqueClickedPercent}
            subText={'Click Rate'}
            cardIndex={3}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueRepliedPercent'}
            text={numUniqueRepliedPercent}
            subText={'Reply Rate'}
            cardIndex={4}
            padding='small'
          />
          <StatCard
            className={css(Styles.numericStatItem)}
            xs={12}
            sm={3}
            key={'uniqueBouncedPercent'}
            text={numUniqueBouncedPercent}
            subText={'Bounce Rate'}
            cardIndex={5}
            padding='small'
          />
        </Grid>
        {this.campaign.state === 'DRAFT' && <p className={css(Styles.campaignTimeline)}>{'The campaign is currently in DRAFT state.'}</p>}
        {this.campaign.state === 'PAUSED' && <p className={css(Styles.campaignTimeline)}>{'The campaign is currently paused.'}</p>}
        {this.campaign.state === 'COMPLETED' && <p className={css(Styles.campaignTimeline)}>{'The campaign has completed.'}</p>}
        {this.campaign.state === 'LIVE' && campaignEndDate !== -1 && <p className={css(Styles.campaignTimeline)}>{`All live enrolments are expected to complete ${isExceedingEndDate ? 'beyond' : 'by'} ${Helper.formatTimestamp(Helper.getNextHourTs(campaignEndDate))}.`}</p>}
      </div>
    </>
  }

  onActions = (current) => {
    const actions = []

    function addStopAction() {
      actions.push(
        <Tooltip title={'Stop'} placement='top-start' key={current.id + 'stop'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.updateEnrolmentState(current, 'COMPLETED')}>stop</i>
          </span>
        </Tooltip>
      )
    }
    if (current.state === 'LIVE') {
      actions.push(
        <Tooltip title={'Pause'} placement='top-start' key={current.id + 'pause'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.updateEnrolmentState(current, 'PAUSED')}>pause</i>
          </span>
        </Tooltip>
      )
      addStopAction.bind(this)()
    } else if (current.state === 'PAUSED') {
      actions.push(
        <Tooltip title={'Resume'} placement='top-start' key={current.id + 'resume'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.updateEnrolmentState(current, 'LIVE')}>send</i>
          </span>
        </Tooltip>
      )
      addStopAction.bind(this)()
    } else if (current.state === 'COMPLETED' && ['ERROR', 'TIMEOUT', 'WAIT_TIMEOUT', 'UNENROL_ON_REPLY'].includes(current.completionReason)) {
      actions.push(
        <Tooltip title={'Reset'} placement='top-start' key={current.id + 'reset'}>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined' onClick={() => this.updateEnrolmentState(current, 'LIVE')}>restart_alt</i>
          </span>
        </Tooltip>
      )
    }

    actions.push(
      <Link to={this.baseRoute() + '/crm-contacts/' + current.userId} key={current.userId}>
        <Tooltip title='View Contact' placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <i className='material-icons-outlined'>person</i>
          </span>
        </Tooltip>
      </Link>
    )

    return actions
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'gray'
  },
  aboveTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  numericStatContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
    marginBottom: 16,
    gap: '16px',
  },
  campaignTimeline: {
    textAlign: 'center',
    fontSize: 16,
    color: 'gray'
  },
})