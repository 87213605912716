import { RoleHelper } from '../common/helpers/RoleHelper'

export class AppRoleHelper extends RoleHelper {

  getHome() {
    if (this.isProductSU()) {
      return '/admin'
    }
    if (this.isOrgSU()) {
      if(window.innerWidth < 1024) {
        return '/admin/orgs/' + this.getOrgId() + '/crm-contacts'
      }
      return '/admin/orgs/' + this.getOrgId()
    }
    if (this.hasOrgRole(this.getOrgId(), 'BLOGGER')) {
      return '/admin/orgs/' + this.getOrgId()
    }
    if (this.hasOrgRole(this.getOrgId(), 'CRM')) {
      if(window.innerWidth < 1024) {
        return '/admin/orgs/' + this.getOrgId() + '/crm-contacts'
      }
      return '/admin/orgs/' + this.getOrgId()
    }
    if (this.isPartnerSU()) {
      return '/admin/partners/' + this.getOrgId()
    }
    return '/no-permission'
  }

  isAccessAllowed(options) {
    if (this.isProductSU()) {
      return true
    }
    if (this.isOrgSU() && (options.path.startsWith('/admin/orgs/' + this.getOrgId()))) {
      return true
    }
    if (this.isPartnerSU() && (
      options.path === ('/admin/partners/' + this.getOrgId())
    )) {
      return true
    }
    return false
  }
}
