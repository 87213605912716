import { CampaignsBase } from './CampaignsBase';
import { Helper } from '../../../common/helpers/Helper';

export class CrmSequences extends CampaignsBase {
  constructor(props, options) {
    super(props, {
      ...options,
      pageTitle: Helper.getString('crm-sequences'),
      pageKey: 'crm-sequences',
      campaignType: 'DRIP',
    });
  }
}
