import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppTheme } from '../../../styles/AppTheme'

import {BarElement, CategoryScale, Chart, ArcElement, Legend, LinearScale, Title, Tooltip as ChartJsTooltip, LineElement, PointElement } from 'chart.js'
import { Bar, Pie, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Helper } from '../../helpers/Helper';
Chart.register(ArcElement, BarElement, CategoryScale, Legend, LinearScale, Title, ChartJsTooltip, LineElement, PointElement);

class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.defaultErrMsg = 'An error occured.'
    this.state = {
      inProcess: false
    };
  }
}

export class BarChartCard extends ChartCard {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(this.props.isTabChart ? [Styles.outerContainer, Styles.tabChartOuterContainer] : Styles.outerContainer)}>
        {this.props.item.title && <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
        </div>}
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <div className={css(Styles.chartContainer)}>
          <Bar
            options={{
              maintainAspectRatio: false,
              type: 'bar',
              barThickness: 16,
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    precision: 0,
                  },
                },
              },
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
              },
            }}
            data={this.props.item.data}
          />
        </div>
      </div>
    );
  }
}

export class PieChartCard extends ChartCard {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(this.props.isTabChart ? [Styles.outerContainer, Styles.tabChartOuterContainer] : Styles.outerContainer)}>
        {this.props.item.title && <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
        </div>}
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <div className={css(Styles.chartContainer)}>
          {(this.props.item.data && this.props.item.data.datasets && this.props.item.data.datasets[0] && Array.isArray(this.props.item.data.datasets[0].data) && this.props.item.data.datasets[0].data.length > 0) ? (<Pie
            plugins= {this.props.item.showDataLabels && [ChartDataLabels]}
            options={{
              maintainAspectRatio: false,
              type: 'pie',
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    font: {
                      size: 10
                    }
                  },
                },
                datalabels: {
                  // display: function(context) {
                  //   const dataset = context.dataset;
                  //   const value = dataset.data[context.dataIndex];
                  //   const total = dataset.data.reduce((acc, cur) => acc + cur, 0);
                  //   const percentage = (value / total) * 100;
                  //   return percentage > 10;
                  // },
                  color: '#ffffff',
                  font: {
                    weight: 'bold'
                  },
                  formatter: (value, context) => {
                    return value;
                  }
                }
              },
            }}
            data={this.props.item.data}
          />) : (<div className={css(Styles.emptyViewContainer)}><p className={css(Styles.emptyViewText)}>{Helper.getString('noDataAvailable')}</p></div>)}
        </div>
      </div>
    );
  }
}

export class LineChartCard extends ChartCard {
  constructor(props) {
    super(props);
  }

  render() {
    const xLabelsDifference = (this.props.item && this.props.item.xLabelsDifference) ? this.props.item.xLabelsDifference : 2
    return (
      <div className={css(this.props.isTabChart ? [Styles.outerContainer, Styles.tabChartOuterContainer] : Styles.outerContainer)}>
        {this.props.item.title && <div className={css(Styles.paperTitle)} elevation={0}>
          <p className={css(Styles.paragraphMargin)}>{this.props.item.title}</p>
        </div>}
        <div>
          {this.state.inProcess &&
            <div className={css(Styles.loader)}>
              <CircularProgress size={48} className={css(Styles.progress)} />
            </div>
          }
        </div>
        <div className={css(Styles.chartContainer)}>
          {(this.props.item && this.props.item.data && this.props.item.data.datasets && this.props.item.data.datasets[0] && Array.isArray(this.props.item.data.datasets[0].data) && this.props.item.data.datasets[0].data.length > 0) ? (<Line
            options={{
              clip: false,
              maintainAspectRatio: false,
              scales: {
                x: {
                  display: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    callback: function(val, index) {
                      return index % xLabelsDifference === 0 ? this.getLabelForValue(val) : '';
                    },
                  }
                },
                y: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    precision: 0,
                  },
                  beginAtZero: true,
                },
              },
              elements: {
                point: {
                  radius: 0,
                  hoverRadius: 5,
                  hitRadius: 10,
                  backgroundColor: 'rgba(90, 140, 245, 1)',
                  hoverBackgroundColor: 'rgba(90, 140, 245, 1)',
                }
              },
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
              },
            }}
            data={this.props.item.data}
          />) : (<div className={css(Styles.emptyViewContainer)}><p className={css(Styles.emptyViewText)}>{Helper.getString('noDataAvailable')}</p></div>)}
        </div>
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  outerContainer: {
    border: '1px solid ' + AppTheme.borderColor,
    padding: 20,
    borderRadius: AppTheme.borderRadius,
    flex: 1,
    '@media (max-width: 767px)': {
      padding: '20px 10px',
      borderRadius: 8,
    },
  },
  tabChartOuterContainer: {
    border: 0,
  },
  chartContainer: {
    position: 'relative',
    height: '210px',
    '@media (max-width: 767px)': {
      width: '80vw',
    },
  },
  barChartContainer: {
    height: '240px',
  },
  paperFooter: {
    marginBottom: 0,
    display: 'flex',
    verticalAlign: 'top',
    justifyContent: 'flex-end'
  },
  paperTitle: {
    fontWeight: 'bold',
    color: AppTheme.white,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center'
  },
  subText: {
    display: 'inline-block',
    fontSize: 24,
    fontWeight: 'bold',
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    '@media(max-width: 768px)': {
      fontSize: 12
    }
  },
  arrowUp: {
    color: 'green'
  },
  arrowDown: {
    color: 'red'
  },
  videoContainerTile: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paragraphMargin: {
    margin: 0,
    flex: 1,
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 767px)': {
      fontSize: 14
    }
  },
  progress: {
    color: AppTheme.white
  },
  loader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  colorCode1: {
    width: 15,
    height: 15,
    display: 'flex'
  },
  colorCode2: {
    width: 15,
    height: 15,
    display: 'flex'
  },
  colorInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    fontWeight: 'normal'
  },
  emptyViewContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyViewText: {
    color: 'grey'
  }
});
