/* eslint-disable camelcase */
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { AppConfig } from '../../../AppConfig';
import { Controller } from '../../../common/Controller';

export class CrmInbox extends BaseEntityPage {

  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('crm-inbox')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.inboxId = this.props.match.params.inboxId
    this.staffUserId = Controller.get().getUserId()
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('crm-inboxes'), to: this.baseRoute() + '/crm-inboxes'},
    ]
    this.toFetch = [
      { key: 'crminbox', ms: this.ms, method: 'GET', paths: ['crminboxs', this.inboxId], queryParams: { orgId: this.orgId } },
      { key: 'inboxenrolmentdata', ms: this.ms, method: 'GET', paths: ['miscs', 'get-inbox-enrolment-data'], queryParams: { orgId: this.orgId, inboxId: this.inboxId } },
    ]
    this.apiData = {
      orgId: this.orgId,
      userId: this.staffUserId,
    }
    this.pageKey = 'crm-inbox'
    this.noAdd = true
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Campaign: (current) => current._campaignName,
      Contact: (current) => current._contactEmail,
      'Complete by': (current) => current._completeBy === -1 ? 'Unknown' : Helper.formatTimestamp(Helper.getNextHourTs(current._completeBy)),
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.crmInbox = this.getResult(results, 'crminbox')
    this.inboxEnrolments = this.getResult(results, 'inboxenrolmentdata').map(e => {
      const stepSlots = Object.values(e._slots ? e._slots.steps || {} : {})
      const _completeBy = stepSlots.length > 0 && !stepSlots.includes(-1) ? Math.max(...stepSlots) : -1
      return {
        ...e,
        _completeBy,
      }
    }).sort((a, b) => b._completeBy - a._completeBy)
    this.breadCrumbs.push({title: this.crmInbox.email })
    this.setState({
      items: this.inboxEnrolments,
    })
  }

  onActions(current) {
    return []
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 14
  },
  line2: {
    fontSize: 12,
    color: 'grey'
  },
})