/* eslint-disable camelcase */
import React from 'react';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';

function getCdpSettingsFormDef(current) {
  return {
    steps: [
      {
        fields: [
          {
            type: 'edit_text',
            label: 'Key',
            key: 'settingsKey',
            placement: 'left',
            value: current ? current.key : '',
            // eslint-disable-next-line camelcase
            v_required: { value: true, err: 'Key is required' },
          },
          {
            type: 'edit_text',
            label: 'Value',
            key: 'settingsValue',
            placement: 'right',
            value: current ? current.value : '',
            // eslint-disable-next-line camelcase
            v_required: { value: false },
          },
        ]
      }
    ]
  }
}

export class CdpSettings extends BaseEntityPage {
  constructor(props, options) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = options.pageTitle || Helper.getString('cdp-settings')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('cdp-settings') },
    ]
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['miscs', 'cdp-settings'], queryParams: { orgId: this.orgId } },
    ]
    this.apiData = {}
    this.pageKey = 'cdp-settings'
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Key: (current) => current.key,
      Value: (current) => current.value,
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
  }

  onFetchSuccess(results) {
    this.cdpSettings = results[0] || {}
    const allSettings = []
    for (const [key, value] of Object.entries(this.cdpSettings)) {
      if (!key.startsWith('_')) allSettings.push({key, value})
    }
    this.setState({
      items: allSettings,
    })
  }

  openModalForAdd() {
    this.formDefinition = getCdpSettingsFormDef()
    this.apiData = {}
    super.openModalForAdd(this.formDefinition)
  }

  openModalForEdit(current) {
    this.formDefinition = getCdpSettingsFormDef(current)
    this.apiData = {previousKey: current.key, previousValue: current.value}
    super.openModalForEdit({settingsKey: current.key, settingsValue: current.value}, this.formDefinition)
  }

  customSubmit(options) {
    const { formType, formData, caller } = options
    if (!['add','edit'].includes(formType)) {
      return caller.submit.call(caller, formData, true);
    }

    const previousKey = formData.previousKey
    const previousValue = formData.previousValue
    const settingsKey = formData.settingsKey
    const settingsValue = formData.settingsValue

    ApiHelper.callAwait({
      method: 'POST',
      jsonBody: { orgId: this.orgId, settingsKey, settingsValue, previousKey, previousValue },
      endPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['miscs', 'update-cdp-settings'] }),
    }).then(() => {
      this.setState({ modalOpen: false })
      this.fetchItems()
    })
      .catch(err => {
        const errorMessage = Helper.getErrorMsg(err)
        caller.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
      })
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }
}
