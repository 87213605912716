import React, { useState, useEffect, useContext, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Divider from '@material-ui/core/Divider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Controller } from '../Controller';
import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme';
import { Helper } from '../helpers/Helper';
import Collapse from '@material-ui/core/Collapse';
import { AppContext } from '../contexts/AppContext';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { AppStyleHelper } from '../helpers/AppStyleHelper';
import { UiHelper } from '../helpers/UiHelper';
import { SwipeableDrawer } from '@material-ui/core';

const MENU_TITLE_SIZE = 14
const TEXT_COLOUR = '#7D7D7D'
const DRAWER_BUTTON_MARGN_TOP = 55

export function DrawerMenuDashboardMobile(props) {
  const [state, setState] = useState({
    drawerIsOpen: false
  })

  const { appData, appContextStateHandler } = useContext(AppContext)

  useEffect(() => {
    props.menuItems.map(items => {
      items.map(listItems => {
        listItems.subItems && listItems.subItems.map(subListItems => {
          subListItems.key === appData.pageKey &&
            setState({
              ...state,
              openId: listItems.key
            })
        })
      })
    })
  }, [appData.pageKey])

  const onMenuClick = (item, closeCollapse) => {
    if (closeCollapse)
      setState({
        ...state,
        openId: null
      })
    if (window.innerWidth <= 1024) {
      toggleDrawer()
    }
    if (item.type === 'LOGOUT') {
      Controller.get().logout()
    }
  }

  const toggleDrawer = () => {
    props.drawerIsOpen && props.drawerIsOpen(!state.drawerIsOpen)
    setState({
      ...state,
      drawerIsOpen: !state.drawerIsOpen
    })
  }

  const styleConditionCheck = (key) => {
    if (appData.pageKey === key) {
      return true
    } else {
      return false
    }
  }

  const accordingListClick = (e) => {
    const openId = state.openId
    if (openId === e) {
      setState({
        ...state,
        openId: null
      });
    } else {
      setState({
        ...state,
        openId: e
      });
    }
  };

  const listItem = (item) => {
    return <ListItem button className={getListStyle(item)}>
      <ListItemText
        disableTypography
        className={css(Styles.listItemText)}
        primary={
          <Typography type='body2' className={getListTitleStyle(item)}>
            {item.title}
          </Typography>
        }
      />
    </ListItem>
  }

  const menuRowItem = (item) => {
    return (
      <Fragment>
        <div
          className={
            styleConditionCheck(item.key)
              ? css([Styles.listStyle, Styles.listSelectedStyle])
              : css(Styles.listStyle)
          }
        />
        {item.route ? <Link to={{
          pathname: item.route,
          state: { isMenuItemClicked: true }
        }} className={css(Styles.itemLink)}>{listItem(item)}</Link> : listItem(item)}
      </Fragment>
    );
  }

  const accordingListItems = (data, index) => {
    return (
      <div key={index}>
        <div onClick={() => accordingListClick(data.key)} className={css(Styles.listInnerContainer)}>
          {menuRowItem(data)}
        </div>
        <Collapse
          className={css(Styles.accordingListContainer)}
          key={data.key}
          component='li'
          in={state.openId === data.key}
          timeout='auto'
          unmountOnExit>
          <List disablePadding>
            {data.subItems.map((nsItem, index) => {
              return (
                <div onClick={() => onMenuClick(nsItem)} key={index} className={css(Styles.listInnerContainer)}>
                  {menuRowItem(nsItem)}
                </div>
              )
            })}
          </List>
        </Collapse>
      </div>
    )
  }

  const getListStyle = (data) => {
    if (styleConditionCheck(data.key)) {
      return css([Styles.listButton, Styles.listSelectedButton])
    } else {
      return css(Styles.listButton)
    }
  }

  const getListTitleStyle = (data) => {
    if (styleConditionCheck(data.key)) {
      if (AppConfig.DRAWER_STYLE === 'TYPE1') {
        return css([Styles.listColor, Styles.listSelectedColorType1])
      } else {
        return css([Styles.listColor, Styles.listSelectedColor])
      }
    } else {
      return css(Styles.listColor)
    }
  }

  const listInnerContainerStyle = () => {
    return css([Styles.listInnerContainer])
  }

  const listItems = (data, index) => {
    return (
      <div onClick={() => onMenuClick(data, true)} key={index} className={listInnerContainerStyle()}>
        {menuRowItem(data)}
      </div>
    )
  }

  const getOrgTitle = () => {
    const currentOrg = Controller.get().roleHelper().getOrg()
    return currentOrg.title || currentOrg.orgId
  }

  const renderList = () => {
    const items = props.menuItems ? props.menuItems : []
    const appUser = Controller.get().userMgr().getAppUser()
    return (
      state.drawerIsOpen &&
      <List className={css(Styles.list)}>
        {items.map((item, index) => {
          return (
            <div key={index}>
              {item.map((data, index) => {
                if (data.subItems && data.subItems.length > 0) {
                  return accordingListItems(data, index)
                } else {
                  return listItems(data, index)
                }
              })}
              <Divider className={css(Styles.divider)} />
            </div>
          )
        })}

        {(appUser && appUser.orgs && appUser.orgs.length > 1 && !Controller.get().userMgr().isProductSU()) &&
          <Fragment>
            <Divider className={css(Styles.divider)} />
            <div onClick={() => {
              toggleDrawer()
              props.orgSwitcherDialogOpen()
            }} className={css(Styles.listInnerContainer)}>
              {menuRowItem({
                key: 'orgSwitcher',
                title: Helper.getString('orgSwitcher'),
                icon: 'exit_to_app',
              })}
            </div>
            <Divider className={css(Styles.divider)} />
          </Fragment>
        }

        <Fragment>
          <div onClick={() => {
            toggleDrawer()
            Controller.get().logout()
          }} className={css(Styles.listInnerContainer)}>
            {menuRowItem({
              key: 'logout',
              title: Helper.getString('logout'),
              icon: 'exit_to_app',
            })}
          </div>
          <Divider className={css(Styles.divider)} />
        </Fragment>

        {appUser &&
          <div className={css([Styles.lowerListContainerType1, Styles.versionContainer, Styles.leftAlign])}>
            <p className={css(Styles.orgInfoText)}>{appUser.email || appUser.phone}</p>
          </div>
        }
        {!Controller.get().userMgr().isProductSU() &&
          <div className={css([Styles.lowerListContainerType1, Styles.versionContainer, Styles.leftAlign])}>
            <p className={css(Styles.orgInfoText)}>{getOrgTitle()}</p>
          </div>
        }
        <div className={css(Styles.versionContainer2)}>{Helper.getString('version')}
          <span className={css(Styles.version)}>{AppConfig.VERSION}</span>
        </div>
      </List>
    )
  }

  return (
    <Fragment>
      <div className={css(Styles.drawerMobileView)} >
        <div className={css(Styles.drawerButtonMobileView)}>
          <div className={css(Styles.drawerButtonContainer)} onClick={() => toggleDrawer()}>
            <ListItemIcon>
              <i className='material-icons-outlined' style={{color: '#000000', fontSize: 22 }}>{'menu'}</i>
            </ListItemIcon>
          </div>
        </div>
      </div>
      <SwipeableDrawer
        anchor='bottom'
        open={state.drawerIsOpen}
        onClose={toggleDrawer}
        //onOpen={props.onOpen}
        className={css(Styles.swipe)}
        classes={{
          paper: css(Styles.drawerPaper),
          modal: css(Styles.drawerModal),
        }}
        //transitionDuration={800}
      >
        {state.drawerIsOpen &&
          <button className={css(Styles.modalCloseButton)} onClick={toggleDrawer}>
            <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          </button>
        }
        <div className={css(Styles.drawerImageContainer)}>
          <div className={css(Styles.drawerImageInnerContainer)}>
            <img
              src={AppConfig.LOGO}
              alt='Logo' className={css(Styles.drawerImage)} />
          </div>
          <span>{Helper.getString('slixtaTagLine')}</span>
        </div>

        <div tabIndex={0}
          role='button'
          className={css(Styles.switchableDrawer)}>
          {renderList()}
        </div>
      </SwipeableDrawer>
    </Fragment>
  );
}

const Styles = AppStyleHelper.modifyStyleSheet({
  switchableDrawer: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0px 30px',
    outline: 'none',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
    },
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  menuButton: {
    color: AppTheme.primaryColor,
    marginLeft: 0,
    marginRight: 5,
  },
  swipe: {
    zIndex: 100000000
  },
  list: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    '@media (max-width: 768px)': {
    }
  },
  drawer: {
    marginTop: 35
  },
  lowerListContainerType1: {
    padding: '13px 15px',
    margin: 0,
    fontSize: 12,
    color: TEXT_COLOUR,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  versionContainer: {
    cursor: 'default'
  },
  versionContainer2: {
    padding: '8px 15px',
    margin: 0,
    fontSize: 12,
    color: TEXT_COLOUR,
    paddingBottom: 30,
  },
  orgInfoText: {
    fontSize: 'inherit',
    color: 'inherit',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: 0
  },
  itemLink: {
    textDecoration: 'none',
    width: '100%'
  },
  listButton: {
    padding: '5px 0px',
    color: TEXT_COLOUR,
    borderRadius: 10,
    ':active': {
      backgroundColor: 'none'
    }
  },
  listSelectedButton: {
    backgroundColor: AppTheme.primaryColor
  },
  divider: {
    color: '#edf2f9',
    margin: '10px 0px'
  },
  version: {
    backgroundColor: 'whitesmoke',
    borderRadius: 8,
    color: 'black',
    marginLeft: 5,
    padding: 5,
    fontSize: 12
  },
  drawerImageContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
    marginBottom: 15
  },
  drawerImageInnerContainer: {
    width: 108,
  },
  drawerCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: DRAWER_BUTTON_MARGN_TOP
  },
  drawerButtonContainer: {
    width: 34,
    height: 34,
    cursor: 'pointer'
  },
  drawerImage: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  drawerButtonFixContainer: {
    position: 'absolute',
    right: -20,
    top: DRAWER_BUTTON_MARGN_TOP,
    display: 'none'
  },
  listInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
    position: 'relative'
  },
  logoutText: {
    margin: 0
  },
  listStyle: {
    width: 4,
    height: 16,
    borderRadius: 2,
    marginRight: 6,
  },
  listSelectedStyle: {
    backgroundColor: AppTheme.primaryColor,
  },
  listItemText: {
    lineHeight: 'unset',
    margin: 0,
    display: 'grid'
  },
  listSelectedColor: {
    color: AppTheme.secondaryBg,
  },
  listSelectedColorType1: {
    color: AppTheme.primaryColor,
  },
  listColor: {
    color: TEXT_COLOUR,
    textTransform: 'capitalize',
    fontSize: MENU_TITLE_SIZE,
    lineHeight: 'unset',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textDecoration: 'none'
  },
  accordingListContainer: {
    marginLeft: 20
  },
  drawerMobileView: {
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'block'
    }
  },
  drawerButtonMobileView: {
    // position: 'absolute',
    // right: 60,
    // top: 5,
    // zIndex: 100000000,
    // '@media (max-width: 767px)': {
    //   right: 24,
    //   top: 14
    // }
  },
  leftAlign: {
    justifyContent: 'flex-start'
  },
  drawerPaper: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16
  },
  modalCloseButton: {
    padding: 20,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    justifyContent: 'flex-end'
  },
  modalCloseIcon: {
    width: 18,
    height: 18,
    padding: 1,
    border: '1.5px solid' + AppTheme.primaryColor,
    fill: AppTheme.primaryColor,
    borderRadius: 8
  },
}, { comp: 'drawerMenuDashboard' })
