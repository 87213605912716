import React, { Fragment, useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link, useParams } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { AppTheme, AppStyles } from '../../styles/AppTheme';
import { BreadCrumbs } from './BreadCrumbs';
import { AppConfig } from '../../AppConfig';
import { AppHelper } from '../../helpers/AppHelper';
import { CustomHeaderbarStyles } from '../../styles/CustomStyles';
import { AppContext } from '../../common/contexts/AppContext';
import Tooltip from '@material-ui/core/Tooltip';
import { Controller } from '../Controller';
import { SettingsMenu } from './SettingsMenu';
import { OrgSwitcher } from './OrgSwitcher';
import { AppButton } from './AppButton';

export function Headerbar(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)
  const pagePadding = AppTheme.adminPagePadding * 2
  const headerbarWidth = () => {
    return (appData && appData.toggleDrawer) ? (window.innerWidth - AppTheme.drawerWidth - pagePadding) : (window.innerWidth)
  }
  const { orgId } = useParams();
  const [state, setState] = useState({
    settingPopover: null,
    orgSwitcherDialog: false,
    selectedOrgId: orgId
  })

  useEffect(() => {
    appContextStateHandler({ pageKey: props.pageKey })
  }, [])

  const settingOpen = (e) => {
    setState({ ...state, settingPopover: e.currentTarget })
  }

  const settingClose = () => {
    setState({ ...state, settingPopover: null })
  }

  const orgSwitcherDialogOpen = () => {
    setState({ ...state, orgSwitcherDialog: true, settingPopover: null });
  }

  const orgSwitcherDialogClose = () => {
    setState({ ...state, orgSwitcherDialog: false, settingPopover: null, selectedOrgId: orgId });
  }

  const onOrgChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const doActionSave = () => {
    Controller.get().roleHelper().setCurrentOrgInfo(state.selectedOrgId)
    setState({ ...state, orgSwitcherDialog: false })
    const pathname = '/admin/orgs/' + state.selectedOrgId
    window.location.href = pathname
    //Controller.get().pushRoute(pathname);
  }

  return (
    <div className={css(Styles.container)} style={{ width: headerbarWidth() }}>
      <Link to={Controller.get().getHomeByRole()} className={css(Styles.logoForMobileApp)}>
        <div className={css(Styles.drawerImageInnerContainer)}>
          <img
            src={AppConfig.LOGO}
            alt='Logo' className={css(Styles.drawerImage)} />
        </div>
      </Link>
      <div className={css(Styles.containerNarrow)}>
        {props.image && <img src={props.image} className={css(Styles.headerImage)} />}
        <div className={css(Styles.containerTitle)}>
          <div className={css(Styles.containerTitleContent)}>
            <h1 className={css(Styles.title)}>{props.title}</h1>
          </div>
          <BreadCrumbs breadCrumbs={props.breadCrumbs} />
        </div>
        <div className={css(Styles.containerButton)}>
          {props.errMsg && <span className={css(Styles.errorMessage)}>{props.errMsg}</span>}
          <div className={css(Styles.containerButtonInner)}>
            {props.actionComponents && props.actionComponents.map((actionComponent, index) => {
              return (
                <div key={'actionComponent_' + index} className={css(Styles.actionComponent)}>
                  {actionComponent}
                </div>
              )
            })}
            {props.actions && props.actions.map((actionItem, index) => {
              if (actionItem.type === 'icon') {
                return (
                  <div className={css(Styles.link)} key={index}>
                    <Tooltip title={actionItem.tooltip} arrow classes={{ popper: css(Styles.tooltip) }}>
                      <div className={css(Styles.imageContainer)} onClick={actionItem.onClick}>
                        <i className='material-icons-outlined' style={{ fontSize: 20, margin: 14, color: '#222222' }} >{actionItem.icon}</i>
                      </div>
                    </Tooltip>
                  </div>
                )
              } else if (actionItem.type === 'icon-label') {
                return (
                  <div className={css(Styles.link)} key={index}>
                    <Tooltip title={actionItem.tooltip} arrow classes={{ popper: css(Styles.tooltip) }}>
                      <div className={css(Styles.iconLabelContainer)} onClick={actionItem.onClick}>
                        <div><i className='material-icons-outlined' style={{ fontSize: 20 }} >{actionItem.icon}</i></div>
                        <p className={css(Styles.iconLabelText)}>{actionItem.label}</p>
                      </div>
                    </Tooltip>
                  </div>
                )
              } else {
                return (
                  <AppButton
                    key={index}
                    buttonStyle={Styles.button}
                    onClick={actionItem.onClick}
                    buttonText={actionItem.label}
                    icon={actionItem.icon || 'add'}
                  />
                )
              }
            })}

            <Fragment>
              <div className={css([Styles.link, Styles.manageAccount])} onClick={(e) => settingOpen(e)}>
                <Tooltip title={'Accounts'} arrow classes={{ popper: css(Styles.tooltip) }}>
                  <div className={css(Styles.imageContainer)}>
                    <i className='material-icons-outlined' style={{ fontSize: 20, margin: 14, color: '#222222' }}>manage_accounts</i>
                  </div>
                </Tooltip>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
      <SettingsMenu
        settingPopover={state.settingPopover}
        settingClose={settingClose}
        orgId={state.selectedOrgId}
        orgSwitcherDialogOpen={orgSwitcherDialogOpen} />
      <OrgSwitcher
        orgSwitcherDialog={state.orgSwitcherDialog}
        orgSwitcherDialogClose={orgSwitcherDialogClose}
        inProcess={state.inProcess}
        selectedOrgId={state.selectedOrgId}
        onOrgChange={onOrgChange}
        doActionSave={doActionSave} />
    </div>
  );
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomHeaderbarStyles : StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    paddingTop: AppTheme.toolbarHeight ? 0 : 20,
    paddingBottom: AppTheme.toolbarHeight ? 0 : 20,
    minHeight: AppTheme.toolbarHeight, //TODO: creates issue in mobile view
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: AppTheme.primaryBg,
    zIndex: 1000,
    maxWidth: AppTheme.viewPort,
    marginLeft: AppTheme.adminPagePadding,
    marginRight: AppTheme.adminPagePadding,
    '@media (max-width: 1024px)': {
      width: '-webkit-fill-available',
    },
    '@media (max-width: 767px)': {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  containerNarrow: {
    maxWidth: AppTheme.viewPort,
    display: 'flex',
    flex: 1
  },
  title: {
    color: 'black',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 30,
    marginBottom: 10
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    // alignItems: 'center',
    '@media (max-width: 767px)': {
      marginBottom: 5,
      marginTop: 5
    }
  },
  containerButtonInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorMessage: {
    marginTop: 5,
    marginLeft: 15,
    alignSelf: 'flex-start',
    color: '#f44336'
  },
  actionComponent: {
    marginLeft: 10
  },
  button: {
    minWidth: 160,
    marginLeft: 10
  },
  buttonLabel: {
    marginLeft: 4
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconLabelContainer: {
    width: 48,
    color: AppTheme.primaryColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconLabelText: {
    margin: 0,
    fontSize: 12,
    textTransform: 'capitalize',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    marginRight: 20
  },
  containerTitleContent: {
    display: 'grid'
  },
  imageContainer: {
    width: 48,
    height: 48,
    backgroundColor: AppTheme.secondaryBg,
    borderRadius: '50%',
    display: 'flex',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    marginLeft: 20,
    '@media (max-width: 767px)': {
      marginLeft: 5,
    }
  }
})
