import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, css } from 'aphrodite';
import ListItem from '@material-ui/core/ListItem';
import { ListItemIcon } from '@material-ui/core';
import { MobileMoreMenu } from '../common/components/MobileMoreMenu';
import { Controller } from '../common/Controller';
import { AppContext } from '../common/contexts/AppContext';
import { AppHelper } from '../helpers/AppHelper';
import { AppTheme } from '../styles/AppTheme'

const FOOTER_ICON_SIZE = 22
const FOOTER_TEXT_COLOUR = '#000000'// '#AAABAF'

export function MobileFooter(props) {
  const { appData, appContextStateHandler } = useContext(AppContext)

  const listItemContainerStyle = (id) => {
    if (appData && appData.pageKey === id) {
      return css(Styles.listItem, Styles.listItemSelectedContainer)
    } else {
      return css(Styles.listItem, Styles.listItemContainer)
    }
  }

  const listTitleContainerStyle = (id) => {
    if (appData && appData.pageKey === id) {
      return css([Styles.listTitle, Styles.listTitleSelectedContainer])
    } else {
      return css(Styles.listTitle)
    }
  }

  const getListIconStyle = (data) => {
    if (appData.pageKey === data.key) {
      return { fontSize: FOOTER_ICON_SIZE, color: AppTheme.primaryColor }
    } else {
      return { fontSize: FOOTER_ICON_SIZE, color: FOOTER_TEXT_COLOUR }
    }
  }

  const scrollTop = () => {
    setTimeout(() => {
      window.scrollTo(0,0)
    }, 0)
  }

  const itemSelected = (item) => {
    if (item.key === 'more') return
    appContextStateHandler({ pageKey: item.key })
    Controller.get().pushRoute(item.route)
    scrollTop()
  }

  const renderMenuItem = (item) => {
    return (
      <div
        className={css(Styles.itemContent)}
        key={item.key}>
        <ListItem button
          key={item.id}
          onClick={() => itemSelected(item)}
          className={listItemContainerStyle(item.key)}>
          <div className={css(Styles.imgContainer)}>
            {item.key === 'more' ?
              <MobileMoreMenu item={item} />
              :
              <ListItemIcon className={css(Styles.linkcolor)}>
                <i
                  className='material-icons-outlined'
                  style={getListIconStyle(item)}
                >
                  {item.icon}
                </i>
              </ListItemIcon>
            }
          </div>
          <div className={css(Styles.listTitleContainer)}>
            <p className={listTitleContainerStyle(item.key)}>{item.title}</p>
          </div>
        </ListItem>
      </div>
    )
  }

  const menuItems = AppHelper.getMobileDrawerMenuItems() || []

  return (
    <footer className={css(Styles.footer)}>
      {menuItems.map(item => {
        return (
          renderMenuItem(item)
        )
      })}
    </footer>
  )
}

const Styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: AppTheme.secondaryBg,
    zIndex: 101,
    padding: '10px 20px',
    paddingTop: 0,
    borderTop: '1px solid #e5e5e5',
    '@media (min-width: 1025px)': {
      display: 'none'
    }
  },
  itemContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  imgContainer: {
    width: 24,
    height: 24,
    display: 'flex',
  },
  listItemSelectedContainer: {
    padding: 0,
    backgroundColor: '#f5f5f5',
    borderTop: '2px solid' + AppTheme.primaryColor,
  },
  listItemContainer: {
    padding: 0,
  },
  listItem: {
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    borderTop: '2px solid' + AppTheme.secondaryBg,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 100
  },
  listTitleContainer: {
    display: 'grid'
  },
  listTitle: {
    margin: 0,
    fontSize: 12,
    color: FOOTER_TEXT_COLOUR,
    marginTop: 5,
    marginLeft: 3,
    marginRight: 3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listTitleSelectedContainer: {
    color: AppTheme.primaryColor
  }
})