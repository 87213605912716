import React, { Component } from 'react';
import { Controller } from '../common/Controller';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import { Helper } from '../common/helpers/Helper';
import { UsernameInput, PasswordInput, NumericOTPInput } from './TextInputs';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme'
// import { CompleteProfile } from './CompleteProfile';
import { ApiHelper } from '../common/helpers/ApiHelper';
import { AppButton } from '../common/components/AppButton';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { Link } from 'react-router-dom';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = AppConfig.USER_PRIMARY === 'email' ?
      UiLoginHelper.UserNameType.EMAIL : UiLoginHelper.UserNameType.PHONE
    this.state = {
      currentPage: UiLoginHelper.Page.LOOKUP,
      loadingAnimation: false,
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    };
    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: false,
      autoLogin: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    //console.log('onSuccessLogin');
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    window.location.href = Controller.get().roleHelper().getHome()
  }

  render() {
    return (
      <div className={css(Styles.container)}>
        <div className={css([Styles.loginPanel, this.props.loginPanelStyle])}>
          <HelmetComponent page={'login'} />
          <h1 className={css(Styles.mainTitle)}>{Helper.getString('login')}</h1>
          <div className={css(Styles.loginMessageContainer)}>
            {this.state.message.length > 0 &&
              <p className={css(Styles.loginMessage)}>{this.state.message}</p>
            }
          </div>
          {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
            this.welcomeView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
            this.signUpView()
          }
          {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
            this.signUpOTPView()
          }
          {this.state.currentPage === UiLoginHelper.Page.LOGIN &&
            this.loginView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
            this.resetPasswordView()
          }
          {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
            this.resetPasswordOTPView()
          }
        </div>
      </div>
    );
  }

  welcomeView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          autoFocus={true}
          value={this.state.userName}
          label={this.userNameType === UiLoginHelper.UserNameType.PHONE ? Helper.getString('mobile') : Helper.getString('email')}
          placeholder={this.userNameType === UiLoginHelper.UserNameType.PHONE ? Helper.getString('enterMobile') : Helper.getString('enterEmail')}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)}
        />

        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><Link target='_blank' to={{ pathname: AppConfig.TERMS }} className={css([Styles.loginLink, Styles.linkColorVariant])}>terms</Link>
          </div>
        }
        <div className={css(Styles.paddingTop30px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            buttonText={'Continue'}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          label={Helper.getString('email')}
          placeholder={Helper.getString('enterEmail')}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <PasswordInput
          value={this.state.pword}
          autoFocus={true}
          onChange={(event) => this.setState({ pword: event.target.value })}
          label={Helper.getString('password')}
          placeholder={Helper.getString('setPassword')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)}
        />

        <p className={css(Styles.pswdPolicy)}>{AppConfig.PASSWORD_POLICY}</p>

        <div className={css(Styles.paddingTop20px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
            buttonText={Helper.getString('signUp')}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>{Helper.getString('startOver')}</p></a>
      </div>
    )
  }

  signUpOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          label={Helper.getString('verificationCode')}
          placeholder={Helper.getString('enterVerificationCode')}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <div className={css(Styles.paddingTop20px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            buttonText={Helper.getString('submit')}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>{Helper.getString('startOver')}</p></a>
      </div>
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          label={Helper.getString('email')}
          placeholder={Helper.getString('enterEmail')}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          autoFocus={true}
          label={Helper.getString('password')}
          placeholder={Helper.getString('enterPassword')}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <div className={css(Styles.paddingTop20px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            buttonText={Helper.getString('login')}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>{Helper.getString('resetPswd')}</p></a>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>{Helper.getString('startOver')}</p></a>
      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <div className={css(Styles.paddingTop30px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            buttonText={Helper.getString('getOtp')}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>

        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>{Helper.getString('startOver')}</p></a>
      </div>
    )
  }

  resetPasswordOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          autoFocus={true}
          placeholder={Helper.getString('enterOTP')}
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          label={Helper.getString('password')}
          placeholder={Helper.getString('setNewPassword')}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitConfirmResetPassword()
            }
          }}
          classes={{
            notchedOutline: css(Styles.notchedOutline)
          }}
          labelRoot={css(Styles.labelRoot)} />

        <p className={css(Styles.pswdPolicy)}>{AppConfig.PASSWORD_POLICY}</p>

        <div className={css(Styles.paddingTop30px)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            inProcess={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            buttonText={Helper.getString('submit')}
            buttonTextHoverStyle={CommonStyles.secondaryButtonTextHoverStyle}
            progressIconSizeStyle={35}
            buttonHoverStyle={CommonStyles.buttonHoverStyle}
          />
        </div>
        <a className={css(Styles.loginLink)} onClick={this.onStartoverClicked.bind(this)}><p>Start over</p></a>
      </div>
    )
  }

  onStartoverClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    backgroundColor: AppTheme.secondaryBg,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    maxWidth: 390,
    alignSelf: 'center',
    width: '100%',
    '@media (max-width: 767px)': {
      alignSelf: 'unset',
    },
  },
  loginPanel: {
    textAlign: 'center'
  },
  loginMessageContainer: {
    margin: 'auto'
  },
  loginMessage: {
    fontSize: 16,
    margin: 0,
    marginTop: 15,
    color: 'red',
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: '#222222',
    fontSize: 16,
    textDecoration: 'none'
  },
  paddingTop30px: {
    paddingTop: 26,
    position: 'relative',
  },
  paddingTop20px: {
    paddingTop: 20
  },
  marginLeft: {
    marginLeft: 10
  },
  termsContainer: {
    paddingTop: 20,
    fontSize: 16
  },
  buttonStyle: {
    boxShadow: 'none',
    color: AppTheme.buttonTextColor,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
    minWidth: 180,
    borderRadius: 38,
  },
  mainTitle: {
    fontSize: 46,
    margin: 0,
    fontWeight: 700,
    color: '#222222',
    '@media (max-width: 767px)': {
      fontSize: 28,
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      fontSize: 28,
    }
  },
  subTitle: {
    color: '#222222',
    fontSize: 18,
  },
  label: {
    color: '#222222',
    fontSize: 18,
    margin: '10px 0px',
    textAlign: 'left'
  },
  circularProgress: {
    color: 'white',
    marginLeft: 10
  },
  pswdPolicy: {
    marginTop: 5,
    color: 'black',
    fontSize: 14,
    opacity: 0.5,
    textAlign: 'start'
  },
  buttonSpec: {
    width: '100%',
    '@media(max-width: 768px)': {
      margin: 0
    }
  },
  linkColorVariant: {
    color: AppTheme.primaryColor
  }
})
