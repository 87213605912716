import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Helper } from '../common/helpers/Helper';
import { StyleSheet, css } from 'aphrodite';
import { CommonTheme } from '../styles/Styles';
import TextField from '@material-ui/core/TextField';
import { UiLoginHelper } from '../common/helpers/UiLoginHelper';
import { AppTheme } from '../styles/AppTheme';

export class NumericOTPInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.placeholder ? this.props.placeholder : Helper.getString('otp')}</p>
        <FormControl
          fullWidth
          className={this.props.formControlMargin}>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: 'OTP',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

export class PasswordInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.label}</p>
        <FormControl
          fullWidth
          className={this.props.formControlMargin}>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              type: 'password',
              htmlFor: 'password',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

export class UsernameInput extends Component {
  constructor(props) {
    super(props);
    this.userNameType = this.props.userNameType
  }

  render() {
    return (
      <div className={css(Styles.formSection)}>
        <p className={css(Styles.label)}>{this.props.label}</p>
        <FormControl
          fullWidth
          className={this.props.formControl}>
          <TextField
            {...this.props}
            className={css(Styles.formControl)}
            label={''}
            InputProps={{
              disableUnderline: true,
              htmlFor: this.userNameType === UiLoginHelper.UserNameType.PHONE ? 'mobile-number' : 'email',
              classes: {
                root: css(Styles.textInputStyles)
              }
            }}
          />
        </FormControl>

      </div>
    )
  }
}

const Styles = StyleSheet.create({
  formSection: {
    margin: '32px 0px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      margin: '16px 0px',
    }
  },
  label: {
    fontSize: 22,
    margin: 0,
    color: CommonTheme.textColor,
    fontWeight: 400,
    marginBottom: 12,
    '@media (max-width: 767px)': {
      fontSize: 16,
    }
  },
  textInputStyles: {
    border: 'none',
    padding: '10px 15px',
    flex: 1,
    textAlign: 'left',
    minHeight: 50,
    fontSize: 18,
    color: CommonTheme.textColor,
    '::placeHolder': {
      color: '#BBBBBB',
    }
  },
  formControl: {
    flex: 1,
    backgroundColor: AppTheme.backgroundColor,
    borderRadius: AppTheme.borderRadius,
  }
})