import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDatsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';
import { CustomCbs } from '../../components/cb/CustomCbs';
import { CbHelper } from '../../components/cb/CbHelper';
import { AppHelper } from '../../helpers/AppHelper';
import { SubEntityCard } from '../../common/components/SubEntity/SubEntityCard';

const queryString = require('query-string');
export class CbDats2 extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('cbdats')
    this.pageImage = null
    // console.log('this.props:', this.props)
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.pageId = queryString.parse(this.props.location.search).pageId
    this.pageType = queryString.parse(this.props.location.search).pageType
    this.cotypeblocks = queryString.parse(this.props.location.search).cotypeblocks
    this.cbScopeId = this.props.match.params.id
    const cbScopeIdSplits = this.cbScopeId.split('-')
    if (cbScopeIdSplits.length === 4) {
      this.currentId = cbScopeIdSplits[1]
      this.cbId = cbScopeIdSplits[2] + '-' + cbScopeIdSplits[3]
    } else {
      throw new Error('Invalid input')
    }
    this.toFetch = [
      { method: 'GET', paths: ['cbdats'], queryParams: { cbId: this.cbScopeId, pb: 'fieldDef,fieldDefCommon' } },
      { method: 'GET', paths: ['cbdefs', this.cbId], queryParams: {} },
    ]
    if (this.cbScopeId.startsWith('o')) {
      const groupId = 'cborg' + this.orgId
      const itemId = groupId + '-all'
      this.toFetch.push({ method: 'GET', paths: ['items', itemId], queryParams: { groupId, pb: 'fieldDef' } })
    } else if (this.pageId) {
      this.toFetch.push({ method: 'GET', paths: ['pages', this.pageId], queryParams: {} })
    }
    this.toFetch.push(
      { method: 'GET', paths: ['forms'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['cotypes'], queryParams: { orgId: this.orgId, pb: 'fieldDef' }}
    )
    if(this.pageId) {
      this.toFetch.push({ method: 'GET', paths: ['pages', this.pageId], queryParams: { pb: 'fieldDefPageBlock' } },)
    }
    this.apiData = {
      cbId: this.cbScopeId
    }
    this.groupId = 'cborg' + this.orgId
    this.itemId = this.groupId + '-all'
    this.entityAttrName = 'items'
  }

  onFetchSuccess(results) {
    // console.log('##onFetchSuccess',results)
    this.widgetData = results[0]
    this.cbdef = results[1]
    this.pageTitle = 'Data for "' + this.cbdef.title + '"'
    this.state.orderUIMode = ApiHelper.ORDER_UI_MODE.INTERACTIVE
    if(this.cbdef.image) {
      this.pageImage = this.cbdef.image
    }
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
    ]
    // if (this.cbScopeId.startsWith('o')) {
    //   this.pageTitle = 'Data for "' + results[2].items[this.cbScopeId.split('-')[1]].title + '"'
    //   this.breadCrumbs.push({ title: Helper.getString('blocks'), to: this.baseRoute() + '/cborgs/' })
    //   this.breadCrumbs.push({ title: 'Data' })
    // } else if (this.pageId) {
    //   this.page = results[2]
    //   // TODO: Handle crash
    //   // const blockTitle = Helper.ellipsis(results[2].blocks[this.cbScopeId.split('-')[1]].title, 20)
    //   // this.pageTitle = 'Data for "'  + blockTitle + '"'
    //   this.breadCrumbs.push({ title: 'Pages', to: this.baseRoute() + '/pages' })
    //   this.breadCrumbs.push({ title: Helper.ellipsis(results[2].title, 20), to: this.baseRoute() + '/pages/' + this.pageId + '/blocks' })
    //   this.breadCrumbs.push({ title: 'Data' })
    // } else {
    //   this.breadCrumbs.push({ title: this.pageTitle })
    // }
    this.forms = results[3]
    this.coTypes = results[4]
    this.pageData = results.length > 5 ? results[5] : null
    if(results[1].fieldscommon && Object.keys(results[1].fieldscommon).length > 0) {
      if(this.cbScopeId.startsWith('o')) {
        if(results[2] && results[2].items && results[2].items[this.currentId] && results[2].items[this.currentId].cbdatscommon ) {
          this.commonData = results[2].items[this.currentId].cbdatscommon
        }
      } else if(results[2] && results[2].blocks && results[2].blocks[this.currentId] && results[2].blocks[this.currentId].cbdatscommon ) {
        this.commonData = results[2].blocks[this.currentId].cbdatscommon
      }
      this.fieldDefWidgetCommonData = AppHelper.preFillWidgetDefaultValues(results[0].fieldDefCommon, { fields: this.cbdef.fieldscommon || {}})
      Helper.populateFormDefinitionValues(this.fieldDefWidgetCommonData,(this.commonData || {}))
    }

    if (this.cbScopeId.startsWith('o')) {
      this.pageTitle = 'Data for "' + results[2].items[this.cbScopeId.split('-')[1]].title + '"'
    }

    if (this.pageId) {
      this.page = results[2]
      if(this.pageType === 'cotypeblocks') {
        this.breadCrumbs = [{ title: Helper.getString('backToSubPage'), to: this.baseRoute() + '/pages/' + this.pageId + '/cotypeblocks', type: 'button', icon: 'arrow_back_ios' }]
      } else {
        this.breadCrumbs = [{ title: Helper.getString('backToPage'), to: this.baseRoute() + '/pages/' + this.pageId + '/blocks', type: 'button', icon: 'arrow_back_ios' }]
      }

    } else {
      this.breadCrumbs = [{ title: Helper.getString('backToWidgets'), to: this.baseRoute() + '/cborgs', type: 'button', icon: 'arrow_back_ios' }]
    }

    this.formDefinition = AppHelper.preFillWidgetDefaultValues(results[0].fieldDef, this.cbdef)
    this.fieldDefWidgetItems = AppHelper.preFillWidgetDefaultValues(results[0].fieldDef, this.cbdef)

    // Modifying number of rows for richtext to be compact
    UiHelper.modifyNuberOfRowsRichtext(this.fieldDefWidgetItems.steps[0].fields,1)

    if(results[5] && this.fieldDefWidgetCommonData && (!results[5].coTypeId || results[5].coTypeId !== 'BLOG' || this.pageType !== 'cotypeblocks')) {
      UiHelper.removeField(this.fieldDefWidgetCommonData.steps[0].fields,'beside')
    }

    if (this.pageData && this.pageType !== 'cotypeblocks') {
      this.pageConf = {
        previewPath: '/' + this.pageData.slug
      }
    }

    if(this.page && this.page.coTypeId && this.cotypeblocks) {
      this.formDefinition.steps[0].fields.forEach(field => {
        if(field.subType === 'image' || field.imageUpload) {
          //make it a text entry for allowing replacement tags
          delete field.subType
          delete field.imageUpload
        }
      })
    }
    const fieldOne = this.formDefinition.steps[0].fields[0]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      Image: undefined,
      Info: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{Helper.ellipsis(AppHelper.infoTextHelper(current[fieldOne.key]))}</span>
            {current.navLink &&
              <Fragment>
                <br />
                <span className={css(Styles.spanValue, Styles.line2)}>{'Links to: ' + current.navLink}</span>
              </Fragment>
            }
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    if (this.formDefinition.steps[0].fields.find(i => i.subType === 'image' || i.imageUpload)) {
      this.tableHeadersMap.Image = (current) => { return UiHelper.itemImage(current) }
    } else {
      delete this.tableHeadersMap.Image
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    const items = Helper.sortDictArray(results[0].items)
    // if(this.isSingleton() && items.length > 0) {
    //   this.noAdd = true
    // }
    this.setState({
      items: items,
      customCbRender: this.isCustomRender(this.cbdef)
    })
    this.isSingleton = results[1].singleton || ''

    if(this.isSingleton && this.isSingleton === 'YES' && results[0].items.length > 0) {
      this.headerActions = []
    } else {
      this.headerActions = [
        {
          label: 'Add Data',
          icon: 'add',
          onClick: () => this.openModalForAdd()
        }
      ]
    }

    // TODO: commented this code, will be enabled when backend fix comes
    // Showing add common button only when data fields are present
    if(this.fieldDefWidgetCommonData && this.fieldDefWidgetCommonData.steps[0].fields && this.fieldDefWidgetCommonData.steps[0].fields.length > 0) {
      UiHelper.modifyNuberOfRowsRichtext(this.fieldDefWidgetCommonData.steps[0].fields,1)
      this.headerActions.push(  {
        label: 'Widget Settings',
        icon: 'settings',
        onClick: () => this.openModalForAddCommonData()
      })
    }

    if (this.pageConf && this.pageConf.previewPath && this.pageData) {
      this.widgetPageId = AppHelper.getWidgetPageId(this.currentId,this.pageData.blocks)

      this.headerActions.push({
        tooltip: Helper.getString('previewWidget'),
        icon: 'desktop_windows',
        type: 'icon-label',
        label:'Preview',
        onClick: () => this.openNewWindow(`page-cb-${this.widgetPageId}`)
      })
    }
  }

  openNewWindow = (widgetPageId) => {
    if(widgetPageId) {
      window.open('/admin/orgs/' + this.orgId + '/preview?ppath=' + this.pageConf.previewPath + `&widgetid=${widgetPageId}`, 'slixtaPreivew');
    } else {
      window.open('/admin/orgs/' + this.orgId + '/preview?ppath=' + this.pageConf.previewPath, 'slixtaPreivew');
    }
  }

  // isSingleton() {
  //   return Helper.inArray(['IMAGE1', 'H1CENTER'], this.cbdef.cbstyle)
  // }

  customSubmitForCommonData(options, callback) {

    const { formType, formData, caller } = options
    const apiMethod  = this.state.apiMethod
    const addApiData  = this.state.apiData
    const apiEndpoint  = this.state.apiEndPoint

    let apiData = {...formData}
    delete apiData.entityAction
    delete apiData.entityAttrName
    delete apiData.id

    apiData = {
      cbdatscommon:{
        ...apiData
      },
      ...addApiData
    }

    if(!apiMethod) {
      caller.setState({
        success: true,
        message: Helper.getString('unknownFieldType'),
        inProcess: false
      })
      return
    }
    ApiHelper.call(
      { method:apiMethod, endPoint:apiEndpoint, jsonBody:apiData },
      function (err, data) {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err);
          caller.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return;
        }
        caller.previousState = caller.state;
        if (Helper.inArray(['delete', 'del'], this.formDefinition.formType)) {
          caller.setState({
            success: true,
            message: '',
            inProcess: false
          })
        } else {
          caller.setState({
            success: true,
            message: Helper.getString('successful'),
            inProcess: false
          })
        }
        this.fetchItems()
        callback()
      }.bind(this));
  }


  onEditCommonData() {
    if(this.cbScopeId.startsWith('o')) {
      this.setState({
        apiMethod: 'PUT',
        apiData: { entityAction: 'edit', entityAttrName:'items' , id: this.currentId },
        apiEndPoint: ApiHelper.makeUrlPath(['items', this.itemId], { groupId: this.groupId }),
      });
      return
    }
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName:'blocks' , id: this.currentId, cbGlobalId:this.cbId },
      apiEndPoint: ApiHelper.makeUrlPath(['pages', this.pageId], {}),
    });
  }

  openModalForAddCommonData() {

    this.customSubmit = this.customSubmitForCommonData.bind(this)
    this.formDefinition = this.fieldDefWidgetCommonData
    this._addFormHeaderTags()

    this.formDefinition.formType = 'edit'
    this.onEditCommonData();
    this.setState({
      modalOpen: true
    });
  }

  isCustomRender(cbdef) {
    if (cbdef.cbstyle === 'IMAGEHOTSPOT1') {
      this.customSubmit = this.customSubmitForCb.bind(this)
      this.noAdd = true
      return true
    }
    return false
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  _addFormHeaderTags() {
    if(this.cbScopeId.startsWith('o')) {
      this.formDefinition.steps[0].formHeaderTags = [
        {
          title: Helper.getString('sharedWidget'),
          toolTip: Helper.getString('sharedWidgetInfo'),
        }
      ]
    } else {
      this.formDefinition.steps[0].formHeaderTags = []
    }
  }

  renderAboveTable() {
    if(!this.isSingleton || this.isSingleton === 'NO' || this.widgetData.items.length === 0)return<></>
    return <div style={{width:'100%'}}>
      <SubEntityCard
        renderFieldsRowwise={true}
        title={'Widget Data'} formDefinition={this.formDefinition}
        item={this.widgetData.items[0] || {}}
        openModalForEdit={(current) =>
          this.openModalForEdit(current,this.formDefinition)}
      />
    </div>
  }

  openModalForAdd(fieldDef, deleteFormDefValues = true) {
    const fieldDefinition = fieldDef || this.fieldDef || this.formDefinition
    if(this.state.items) {
      const newOrder = AppHelper.generateNewOrder(this.state.items)
      const orderField =  fieldDefinition.steps[0].fields.find(i => i.key === 'order')
      if (orderField) {
        orderField.defaultValue = newOrder;
      }
    }
    super.openModalForAdd(fieldDefinition, deleteFormDefValues)
  }

  onOrderEdit(orderEditData) {
    const { current, otherItem } = orderEditData
    const apiPayload = {
      entityAction: 'edit',
      entityAttrName: this.entityAttrName,
      id: current.id,
    }

    const currentItemId = JSON.parse(JSON.stringify(current)).id
    const otherItemId = JSON.parse(JSON.stringify(otherItem)).id

    const orderChangePayload = {
      current,
      otherItem,
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats', currentItemId], {}),
      otherApiEndPoint: ApiHelper.makeUrlPath(['cbdats', otherItemId], {}),
      apiPayload
    }
    this.onOrderChange(orderChangePayload)
  }

  onAdd() {
    this.customSubmit = undefined
    this.formDefinition = this.fieldDefWidgetItems
    this.formDefinition.formType = 'add'
    UiHelper.populateOptions(this.formDefinition, this.forms, 'form')
    UiHelper.populateOptions(this.formDefinition, this.coTypes, 'customObject')
    this._addFormHeaderTags()
    this.setState({
      apiMethod: 'POST',
      apiData:{cbId:this.cbScopeId},
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats'], {}),
    });
  }

  onEdit(current) {
    this.customSubmit = undefined
    this.formDefinition = this.fieldDefWidgetItems
    this.formDefinition.formType = 'edit'
    UiHelper.populateOptions(this.formDefinition, this.forms, 'form')
    UiHelper.populateOptions(this.formDefinition, this.coTypes, 'customObject')
    this._addFormHeaderTags()
    this.setState({
      apiMethod: 'PUT',
      apiData:{cbId:this.cbScopeId},
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats', current.id], {}),
    });
  }

  onDelete(current) {
    this.customSubmit = undefined
    this.formDefinition = this.fieldDefWidgetItems
    this.formDefinition.formType = 'delete'
    UiHelper.populateOptions(this.formDefinition, this.forms, 'form')
    UiHelper.populateOptions(this.formDefinition, this.coTypes, 'customObject')
    this._addFormHeaderTags()
    this.setState({
      apiMethod: 'DELETE',
      apiData:{cbId:this.cbScopeId},
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats', current.id], {}),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    if(!this.isSingleton || this.isSingleton !== 'YES') {
      actions.push(UiHelper.buttonDelete(this, current))
    }
    return actions
  }

  setCurrentHotpot = (item) => {
    this.currentHotpot = item
  }

  customSubmitForCb(options, callback) {
    const { formType, formData, caller } = options
    const { jsonBody, method, endPoint } = CbHelper.getParamsByCb({
      cb: this.cbdef,
      formType,
      formData,
      formDefinition: this.formDefinition,
      currentHotpot: this.currentHotpot,
      items: this.state.items
    })
    if(!method) {
      caller.setState({
        success: true,
        message: Helper.getString('unknownFieldType'),
        inProcess: false
      })
      return
    }
    ApiHelper.call(
      { method, endPoint, jsonBody },
      function (err, data) {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err);
          caller.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return;
        }
        caller.previousState = caller.state;
        if (Helper.inArray(['delete', 'del'], this.formDefinition.formType)) {
          caller.setState({
            success: true,
            message: '',
            inProcess: false
          })
          this.currentHotpot = null
        } else {
          caller.setState({
            success: true,
            message: Helper.getString('successful'),
            inProcess: false
          })
          this.currentHotpot = null
        }
        this.fetchItems()
        callback()
      }.bind(this));
  }

  // customSubmitForImageSpotsOld(options, callback) {
  //   const { formType, formData, caller } = options
  //   if (formData.image) {
  //     for (const f of this.formDefinition.steps[0].fields) {
  //       if (f.key === 'image') {
  //         formData.imageLink = f.link
  //         if (formType === 'add') {
  //           formData.imageHeight = f._uploadedImgdimensions.height
  //           formData.imageWidth = f._uploadedImgdimensions.width
  //         }
  //       }
  //     }
  //   }

  //   let jsonBody = {}
  //   let method = null
  //   let endPoint = null
  //   if (formType === 'add') {
  //     jsonBody = {
  //       order: '001',
  //       state: 'LIVE',
  //       cbId: formData.cbId,
  //       navLink: '',
  //       data: { ...formData }
  //     }
  //     method = 'POST'
  //     endPoint = ApiHelper.makeUrlPath(['cbdats'], {})
  //   } else if (formType === 'edit' || formType === 'delete') {
  //     const editData = {}
  //     if (formData.image) {
  //       for (const f of this.formDefinition.steps[0].fields) {
  //         if (f.key === 'image') {
  //           editData.imageLink = f.link
  //           editData.image = formData.image
  //         }
  //       }
  //     }
  //     if (formData.theme) {
  //       editData.theme = formData.theme
  //     }
  //     if (formType === 'edit') {
  //       const hotspots = this.state.items[0].data.hotspots || []
  //       if (this.currentHotpot) {
  //         let foundHs = false
  //         for (const h of hotspots) {
  //           if (h.id === this.currentHotpot.widget.options.id) {
  //             h.title = formData.title || ''
  //             h.subTitle = formData.subTitle || ''
  //             h.price = formData.price || ''
  //             h.priceDiscounted = formData.priceDiscounted || ''
  //             h.navLink = formData.navLink || ''
  //             h.marketPlace1 = formData.marketPlace1 || ''
  //             h.marketPlace2 = formData.marketPlace2 || ''
  //             h.marketPlace3 = formData.marketPlace3 || ''
  //             h.addToCartLink = formData.addToCartLink || ''
  //             h.bottomLeftCoordinates = this.currentHotpot.blc
  //             h.topRightCoordinates = this.currentHotpot.trc
  //             foundHs = true
  //             break
  //           }
  //         }
  //         if (!foundHs) {
  //           hotspots.push({
  //             id: this.currentHotpot.id,
  //             title: formData.title || '',
  //             subTitle: formData.subTitle || '',
  //             price: formData.price || '',
  //             priceDiscounted: formData.priceDiscounted || '',
  //             navLink: formData.navLink || '',
  //             marketPlace1: formData.marketPlace1 || '',
  //             marketPlace2: formData.marketPlace2 || '',
  //             marketPlace3: formData.marketPlace3 || '',
  //             addToCartLink: formData.addToCartLink || '',
  //             bottomLeftCoordinates: this.currentHotpot.blc,
  //             topRightCoordinates: this.currentHotpot.trc
  //           })
  //         }
  //         editData.hotspots = hotspots
  //       }
  //     } else {
  //       const hotspots = []
  //       for (const h of this.state.items[0].data.hotspots) {
  //         if (h.id === this.currentHotpot.widget.options.id) {
  //           continue
  //         }
  //         hotspots.push(h)
  //       }
  //       editData.hotspots = hotspots
  //     }
  //     jsonBody = {
  //       order: this.state.items[0].order,
  //       state: this.state.items[0].state,
  //       cbId: this.state.items[0].cbId,
  //       navLink: '',
  //       data: { ...this.state.items[0].data, ...editData }
  //     }
  //     method = 'PUT'
  //     endPoint = ApiHelper.makeUrlPath(['cbdats', this.state.items[0].id], {})
  //   }

  //   ApiHelper.call(
  //     { method, endPoint, jsonBody },
  //     function (err, data) {
  //       if (err) {
  //         const errorMessage = Helper.getErrorMsg(err);
  //         caller.setState({
  //           fetchState: ApiHelper.State.ERROR,
  //           errMsg: errorMessage,
  //           success: false,
  //           message: errorMessage,
  //           inProcess: false
  //         })
  //         return;
  //       }
  //       caller.previousState = caller.state;
  //       if (Helper.inArray(['delete', 'del'], this.formDefinition.formType)) {
  //         caller.setState({
  //           success: true,
  //           message: '',
  //           inProcess: false
  //         })
  //         this.currentHotpot = null
  //       } else {
  //         caller.setState({
  //           success: true,
  //           message: Helper.getString('successful'),
  //           inProcess: false
  //         })
  //         this.currentHotpot = null
  //       }
  //       this.fetchItems()
  //       callback()
  //     }.bind(this));
  // }

  renderTable() {
    if(this.isSingleton && this.isSingleton === 'YES')return<></>
    if (this.state.customCbRender) {
      return (
        <CustomCbs
          cb={this.cbdef}
          modalOpen={this.state.modalOpen}
          items={this.state.items}
          formDefinition={this.formDefinition}
          openModalForAdd={this.openModalForAdd.bind(this)}
          setCurrentHotpot={this.setCurrentHotpot.bind(this)}
          openModalForEdit={this.openModalForEdit.bind(this)}
          openModalForDelete={this.openModalForDelete.bind(this)} />

      )
    } else {
      return super.renderTable()
    }
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDatsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})