import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { css, StyleSheet } from 'aphrodite';

import async from 'async';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { Helper } from '../../../common/helpers/Helper';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { SearchBar } from '../../../common/components/SearchBar';
import { BarChartCard, PieChartCard } from '../../../common/components/charts/ChartCard';
import { StatCard } from '../../../common/components/StatCard';
import { Controller } from '../../../common/Controller';
import DynamicFeedOutlinedIcon from '@material-ui/icons/DynamicFeedOutlined';
import { TabChartCard } from '../../../common/components/charts/TabChartCard';

function getStartOfDayTs(currentTs) {
  const date = new Date(currentTs)
  date.setHours(0, 0, 0, 0)
  return date.getTime()
}

function getLastMonthsStartTs(currentTs, countOfMonths) {
  const result = [];
  const date = new Date(currentTs)

  date.setDate(1)
  date.setHours(0, 0, 0, 0)

  for (let i = 0; i < countOfMonths; i++) {
    result.unshift(date.getTime());
    date.setMonth(date.getMonth() - 1)
  }

  return result
}

function getLastDaysStartTs(currentTs, countOfDays) {
  const result = []
  const date = new Date(currentTs)

  date.setHours(0, 0, 0, 0)

  for (let i = 0; i < countOfDays; i++) {
    result.unshift(date.getTime())
    date.setDate(date.getDate() - 1)
  }

  return result
}

function getToday24HoursTs(currentTs) {
  const result = []
  const date = new Date(currentTs)

  date.setHours(0, 0, 0, 0)

  for (let i = 0; i < 24; i++) {
    result.push(date.getTime())
    date.setHours(date.getHours() + 1)
  }

  return result
}

function getYesterday24HoursTs(currentTs) {
  const result = []
  const date = new Date(currentTs)

  date.setDate(date.getDate() - 1)
  date.setHours(0, 0, 0, 0)

  for (let i = 0; i < 24; i++) {
    result.push(date.getTime())
    date.setHours(date.getHours() + 1)
  }

  return result
}

function generateColor(fraction) {
  return `rgba(66, 117, 245, ${fraction.toFixed(2)})`
}

function getPieChartColor(key, index, numKeys) {
  switch(key) {
  case 'UNQUALIFIED': return 'darkgray'
  case 'LOST': return 'silver'
  case 'BAD TIMING': return 'lightgray'
  }
  return generateColor(Number(index + 1) / numKeys)
}

function generateMultiChartData(stats, key, label, type, date) {
  let chartData = {}
  if(type === 'pieChart') {
    chartData = statsToPieData(stats,key,label)
  } else if(type === 'lineChart') {
    chartData = statsToLineData(stats,key,label, date)
  } else if(type === 'barChart') {
    const tagsBarChartStats = JSON.parse(JSON.stringify(stats))
    const tagsExcludedFromBarChart = ['WHATSAPP_MESSAGE']
    if(tagsBarChartStats && tagsBarChartStats.tags && tagsBarChartStats.tags.buckets ) {
      const filteredBuckets = tagsBarChartStats.tags.buckets.filter((bucket)=> !(tagsExcludedFromBarChart.includes(bucket.key)))
      tagsBarChartStats.tags.buckets = filteredBuckets
    }
    chartData = statsToBarData(tagsBarChartStats,key,label)
  }
  return chartData
}

function statsToPieData(stats, key, label) {
  if (!stats || !(key in stats) || !stats[key].buckets) return {}
  const buckets = stats[key].buckets
  const specialBucketKeyOrder = ['BAD TIMING', 'LOST', 'UNQUALIFIED']
  const progressiveBucketKeyOrder = ['PROSPECT', 'NEW', 'OPEN', 'ATTEMPTED TO CONTACT', 'CONNECTED', 'IN PROGRESS', 'OPEN DEAL', 'CUSTOMER']
  const specialBucketKeys = specialBucketKeyOrder.reduce((p, c) => p.concat(buckets.filter(b => b.key === c)), [])
  const progressiveBucketKeys = progressiveBucketKeyOrder.reduce((p, c) => p.concat(buckets.filter(b => b.key === c)), [])
  const sortedBucketKeys = progressiveBucketKeys.concat(specialBucketKeys)
  const numProgressiveBucketKeys = Number(progressiveBucketKeys.length)
  const labels = sortedBucketKeys.map(b => b.key)
  const data = sortedBucketKeys.map(b => b.doc_count)
  labels.forEach((label, index) => {
    if (label === 'ATTEMPTED TO CONTACT' && Helper.isMobileView()) {
      labels[index] = `ATC (${data[index]})`
    } else {
      labels[index] = `${label} (${data[index]})`
    }
  });
  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: sortedBucketKeys.map((b, i) => getPieChartColor(b.key, i, numProgressiveBucketKeys)),
        borderWidth: 1,
      },
    ],
  }
}

function statsToLineData(stats, key, label, date) {
  if (!stats || !(key in stats) || !stats[key].buckets) return {}
  const buckets = stats[key].buckets

  const fullData = [];
  let labels, dateTsList

  if(date === 'Today') {
    labels = Helper.getHoursIn12HourFormat()
    dateTsList = getToday24HoursTs(Helper.currentTs())
    console.log('dateTsList:: ',dateTsList)
  } else if(date === 'Yesterday') {
    labels = Helper.getHoursIn12HourFormat()
    dateTsList = getYesterday24HoursTs(Helper.currentTs())
  } else if(date === 'Last 7 days') {
    labels = Helper.generatePreviousMonthsOrDays('days', 7)
    dateTsList = getLastDaysStartTs(Helper.currentTs(), 7)
  } else if(date === 'Last 30 days') {
    labels = Helper.generatePreviousMonthsOrDays('days', 30)
    dateTsList = getLastDaysStartTs(Helper.currentTs(), 30)
  } else if(date === 'Last 90 days') {
    labels = Helper.generatePreviousMonthsOrDays('days', 90)
    dateTsList = getLastDaysStartTs(Helper.currentTs(), 90)
  } else if(date === 'Last 6 months') {
    labels = Helper.generatePreviousMonthsOrDays('months', 6)
    dateTsList = getLastMonthsStartTs(Helper.currentTs(), 6)
  } else if(date === 'Last 12 months') {
    labels = Helper.generatePreviousMonthsOrDays('months', 12)
    dateTsList = getLastMonthsStartTs(Helper.currentTs(), 12)
  }

  for(const dateTs of dateTsList) {
    const foundObj = buckets.find(obj => obj.key === dateTs)
    // eslint-disable-next-line camelcase
    const data = {key: dateTs, doc_count: 0}
    if(foundObj) {
      // eslint-disable-next-line camelcase
      data.doc_count = foundObj.doc_count
    }
    fullData.push(data)
  }

  let dataPoints = []
  if(date === 'Today') {
    const currentHour = new Date().getHours()
    dataPoints = fullData.map(b => b.doc_count).slice(0, currentHour + 1)
  } else {
    dataPoints = fullData.map(b => b.doc_count)
  }

  return {
    labels,
    datasets: [
      {
        label,
        data: dataPoints,
        borderWidth: 2,
        borderColor: 'rgba(90, 140, 245, 1)',
        segment: {
          borderDash: ctx => {
            return ((ctx.p1DataIndex === dataPoints.length - 1) && (date !== 'Yesterday')) ? [2,2] : []
          }
        },
      },
    ],
  }
}

function statsToBarData(stats, key, label) {
  if (!stats || !(key in stats) || !stats[key].buckets) return {}
  const topBuckets = stats[key].buckets.slice(0, 5);
  return {
    labels: topBuckets.map(b => b.key),
    datasets: [
      {
        label,
        data: topBuckets.map(b => b.doc_count),
        backgroundColor: topBuckets.map((b, i) => generateColor(Number(i + 1) / Number(topBuckets.length))),
        borderWidth: 1,
      },
    ],
  }
}

function updateWindowUrl(searchText = '', searchFilters = {}) {
  const queryParams = {}
  if (searchText) queryParams.query = searchText
  Object.keys(searchFilters).forEach(f => {
    queryParams[f] = searchFilters[f]
  })
  const _url = new URL(document.location.href)
  const urlSearchParamsKeys = [..._url.searchParams.keys()]
  for (const urlSearchParamsKey of urlSearchParamsKeys) {
    _url.searchParams.delete(urlSearchParamsKey)
  }
  Object.keys(queryParams).forEach(p => {
    if (Array.isArray(queryParams[p]) ? queryParams[p].length > 0 : queryParams[p]) {
      _url.searchParams.append(p, queryParams[p])
    }
  })
  window.history.replaceState(null, '', _url.toString());
}

function getImportFieldDef(staffUsers, currentStaffUser, orgId) {
  const fieldDef = {
    title: 'Import',
    ctaButtonText: 'Import',
    steps: [
      {
        fields: [
          {
            key: 'importCsv',
            linkName: 'importCsvLink',
            type: 'document',
            subType: 'document',
            label: 'Import CSV',
            tip: 'Select a csv file to bulk import up to 100 contacts; Supported fields are \'firstname\', \'lastname\', \'email\', \'phone\', \'company\', \'jobtitle\', \'linkedinurl\', \'tags\', \'state\'; By default all contacts will be imported with state PROSPECT',
            v_required: { value: true, err: 'Provide csv file' },
            placement: 'left',
            documentUpload: true,
            fileSizeLimit: 1048576,
            uploadAccept: 'text/csv',
          },
          {
            key: 'updateExisting',
            type: 'radio',
            label: 'Update Existing',
            tip: 'Disable this if you do not want existing CRM contacts to be updated',
            options: [{key: 'YES', label: 'YES'}, {key: 'NO', label: 'NO'}],
            v_required: {
              value: true,
              err: 'Choose a value'
            },
            placement: 'right',
            defaultValue: 'NO',
          },
          {
            condition: { key: 'updateExisting', value: 'NO', values: ['NO'] },
            key: 'assignee',
            type: 'radio',
            label: 'Assignee',
            hint: 'Assignee',
            options: [],
            v_required: {
              value: false,
              err: 'Provide Assignee'
            },
            placement: 'left'
          },
        ]
      }
    ]
  }
  UiHelper.populateOptions(fieldDef, {items: staffUsers}, 'assignee')
  if (currentStaffUser && currentStaffUser.id) {
    const assigneeField = fieldDef.steps[0].fields.find(f => f.key === 'assignee')
    assigneeField.defaultValue = `${orgId}-${currentStaffUser.id}`
  }
  return fieldDef
}

function getProspectFieldDef(staffUsers, currentStaffUser, orgId) {
  const fieldDef = {
    title: 'Prospect',
    ctaButtonText: 'Next',
    steps: [
      {
        fields: [
          {
            key: 'prospectSource',
            type: 'radio',
            label: 'Source',
            tip: 'Select a prospect source',
            v_required: { value: true, err: 'Provide prospect source' },
            placement: 'left',
            options: [
              {key: 'website', label: 'WEBSITE'},
              {key: 'linkedin', label: 'LINKEDIN'},
              {key: 'email', label: 'EMAIL'},
              {key: 'csv', label: 'CSV'},
            ],
            defaultValue: 'website',
          },
          {
            condition: { key: 'prospectSource', value: 'linkedin', values: ['linkedin'] },
            key: 'linkedinProfileUrl',
            type: 'edit_text',
            label: 'LinkedIn Profile Url',
            tip: 'Paste the link to the LinkedIn profile of the person you want to prospect',
            v_required: { value: true, err: 'Provide LinkedIn profile url' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'companyWebsiteUrl',
            type: 'edit_text',
            label: 'Company Website Url',
            tip: 'Paste the link to the company website of the person you want to prospect',
            v_required: { value: true, err: 'Provide company website url' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'givenname',
            type: 'edit_text',
            label: 'First Name',
            tip: 'Enter the first name of the person you want to prospect',
            v_required: { value: true, err: 'Provide first name' },
            placement: 'left'
          },
          {
            condition: { key: 'prospectSource', value: 'website', values: ['website'] },
            key: 'familyname',
            type: 'edit_text',
            label: 'Last Name',
            tip: 'Enter the last name of the person you want to prospect',
            v_required: { value: true, err: 'Provide last name' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'email', values: ['email'] },
            key: 'email',
            type: 'edit_text',
            label: 'Email',
            tip: 'Enter the email of the person you want to prospect',
            v_required: { value: true, err: 'Provide email' },
            v_email: { value: true, err: 'Provide a valid email' },
            placement: 'right'
          },
          {
            condition: { key: 'prospectSource', value: 'csv', values: ['csv'] },
            key: 'importCsv',
            linkName: 'importCsvLink',
            type: 'document',
            subType: 'document',
            label: 'Import CSV',
            tip: 'Select a csv file to bulk prospect up to 100 leads; Supported fields are \'email\', \'linkedinurl\', \'firstname\', \'lastname\', \'companyurl\'; Please re-import the generated CSV file after review',
            v_required: { value: true, err: 'Provide csv file' },
            placement: 'left',
            documentUpload: true,
            fileSizeLimit: 1048576,
            uploadAccept: 'text/csv',
          },
          {
            condition: { key: 'prospectSource', value: 'csv', values: ['csv'] },
            key: 'assignee',
            type: 'radio',
            label: 'Assignee',
            hint: 'Assignee',
            options: [],
            v_required: {
              value: false,
              err: 'Provide Assignee'
            },
            placement: 'right'
          },
        ]
      }
    ]
  }
  UiHelper.populateOptions(fieldDef, {items: staffUsers}, 'assignee')
  if (currentStaffUser && currentStaffUser.id) {
    const assigneeField = fieldDef.steps[0].fields.find(f => f.key === 'assignee')
    assigneeField.defaultValue = `${orgId}-${currentStaffUser.id}`
  }
  return fieldDef
}

function getFilterDialogFieldDef(fields) {
  const updatedFields = fields.map(field => {
    if (field.type === 'radio' && field.multiSelect) {
      field = { ...field, type: 'check_box' };
    }

    if (field.v_required && field.v_required.value === true) {
      // eslint-disable-next-line camelcase
      field.v_required = { ...field.v_required, value: false };
    }
    return field;
  });

  return {
    title: 'All Filters',
    ctaButtonText: 'Apply Filters',
    steps: [
      {
        fields: updatedFields
      }
    ]
  }
}

function getFilterOptions(fieldDef, crmSequences, crmInboxes, crmBulkImportJobs) {
  let searchText = ''
  const filterOptions = [], searchFilters = {}
  const _url = new URL(document.location.href)
  const showInternal = Helper.isShowInternal()
  if (!fieldDef) return { filterOptions, searchText, searchFilters, showInternal }

  const operators = [{ key: 'equals', label: 'equals' },{ key: 'not equals', label: 'not equals' }]

  const leadStatusField = JSON.parse(JSON.stringify(Helper.getField(fieldDef, 'state')))
  if (leadStatusField) {
    leadStatusField.multiSelect = true
    leadStatusField.operators = operators
    leadStatusField.defaultOperator = 'equals'
    leadStatusField.placement = 'left'
  }
  filterOptions.push(leadStatusField)

  const tagsField = JSON.parse(JSON.stringify(Helper.getField(fieldDef, 'tags')));
  if(tagsField) {
    tagsField.operators = operators
    tagsField.defaultOperator = 'equals'
    tagsField.placement = 'right'
    if (!showInternal) {
      tagsField.options = tagsField.options.filter(option => option.key !== 'INTERNAL');
    }
  }
  filterOptions.push(tagsField)

  const subscriptionsField = JSON.parse(JSON.stringify(Helper.getField(fieldDef, 'subscriptions')))
  subscriptionsField.options.unshift({key: 'none', text: 'NONE'})
  if(subscriptionsField) {
    subscriptionsField.operators = operators
    subscriptionsField.defaultOperator = 'equals'
    subscriptionsField.placement = 'left'
  }
  filterOptions.push(subscriptionsField)

  const assigneeField = JSON.parse(JSON.stringify(Helper.getField(fieldDef, 'assignee')))
  if (assigneeField) {
    assigneeField.multiSelect = true
    assigneeField.operators = operators
    assigneeField.defaultOperator = 'equals'
    assigneeField.placement = 'right'
  }
  filterOptions.push(assigneeField)

  const sequencesFieldDef = UiHelper.getSequencesFieldDef(crmSequences, crmInboxes)
  const sequencesField = JSON.parse(JSON.stringify(Helper.getField(sequencesFieldDef, 'campaignId')))
  if (sequencesField) {
    sequencesField.key = 'campaigns'
    sequencesField.label = 'In sequence'
    sequencesField.options.unshift({key: 'any', text: 'ANY'})
    sequencesField.operators = operators
    sequencesField.defaultOperator = 'equals'
    sequencesField.placement = 'left'
  }
  filterOptions.push(sequencesField)

  const bulkImportJobOptions = crmBulkImportJobs.map(j => ({key: j.id, label: Helper.formatTimestamp(j.createdTs)}))
  const bulkImportJobsField = {
    key: 'importJobId',
    type: 'radio',
    label: 'Import Job',
    options: bulkImportJobOptions,
    // eslint-disable-next-line camelcase
    v_required: { value: true, err: 'Provide Import Job Id' },
    placement: 'right',
    operators: operators,
    defaultOperator: 'equals'
  }
  filterOptions.push(bulkImportJobsField)

  const emailProviderField = {
    key: 'emailProvider',
    type: 'radio',
    label: 'Email Provider',
    options: [{label: 'GMAIL', key: 'GMAIL'}, {label: 'OUTLOOK', key: 'OUTLOOK'}, {label: 'ZOHO', key: 'ZOHO'}],
    // eslint-disable-next-line camelcase
    v_required: { value: true, err: 'Provide email provider' },
    placement: 'left',
    operators: operators,
    defaultOperator: 'equals',
    multiSelect: true,
  }
  filterOptions.push(emailProviderField)

  const sourceField = JSON.parse(JSON.stringify(Helper.getField(fieldDef, 'source')))
  if(sourceField) {
    sourceField.multiSelect = true
    sourceField.operators = operators
    sourceField.defaultOperator = 'equals'
    sourceField.placement = 'right'
    filterOptions.push(sourceField)
  }

  const dateField = {
    key: 'date',
    type: 'radio',
    label: 'Created',
    options: [{label: 'Today', key: 'Today'}, {label: 'Yesterday', key: 'Yesterday'}, {label: 'Last 7 days', key: 'Last 7 days'}, {label: 'Last 30 days', key: 'Last 30 days'}, {label: 'Last 90 days', key: 'Last 90 days'}, {label: 'Last 6 months', key: 'Last 6 months'}, {label: 'Last 12 months', key: 'Last 12 months'}],
    // eslint-disable-next-line camelcase
    v_required: { value: true, err: 'Provide created date' },
    placement: 'left',
  }

  filterOptions.push(dateField)

  const advancedFilters = ['date', 'source', 'emailProvider', 'importJobId', 'subscriptions']
  filterOptions.forEach((filterOption)=>{
    if(advancedFilters.includes(filterOption.key)) {
      filterOption.advanced = true
    } else {
      filterOption.advanced = false
    }
  })

  _url.searchParams.forEach((v, k) => {
    if (k === 'query') { searchText = v; return; }
    if(k === 'date') {
      searchFilters[k] = v
    } else {
      searchFilters[k] = v.split(',').map(e => e.trim())
    }
    const isNotEqualsOperator = k.endsWith('!')
    const normalizedK = isNotEqualsOperator ? k.slice(0, -1) : k;
    const filterOption = filterOptions.find(fo => fo.key === normalizedK)
    if (!filterOption) return;
    filterOption.selectedOperator = isNotEqualsOperator ? 'not equals' : 'equals'
  })
  return { filterOptions, searchText, searchFilters, showInternal }
}

function getTransformedKey(filterOption) {
  const operator = filterOption.selectedOperator ? filterOption.selectedOperator : filterOption.defaultOperator
  return operator && operator === 'not equals' ? (filterOption.key + '!') : filterOption.key
}

function getUpdatedSearchFilters(filterOptions, searchFilters) {
  const updatedSearchFilters = {}
  filterOptions.forEach(field => {
    const searchFilterKey = Object.keys(searchFilters).find(k => k.startsWith(field.key))
    const searchFilterVal = searchFilterKey ? searchFilters[searchFilterKey] : null
    if(searchFilterVal) {
      const finalKey = getTransformedKey(field)
      updatedSearchFilters[finalKey] = searchFilterVal
    }
  })
  return updatedSearchFilters
}

function getNormalizedSearchFilters(filterOptions, searchFilters) {
  const normalizedSearchFilters = {}
  filterOptions.forEach(field => {
    const searchFilterKey = Object.keys(searchFilters).find(k => k.startsWith(field.key))
    const searchFilterVal = searchFilterKey ? searchFilters[searchFilterKey] : null
    if (searchFilterVal) normalizedSearchFilters[field.key] = searchFilterVal
  })
  return normalizedSearchFilters
}
function getContactInfoCellSpan(contact) {
  let preSpanText = '', postSpanText = ''
  const name = Helper.formatName(contact)
  if (name) { preSpanText += `${name} ${contact.friendlyname ? `(${contact.friendlyname})` : ''}` }
  if (contact.friendlycompanyname) {
    preSpanText += `${preSpanText ? ' | ' : ''}${contact.friendlycompanyname}`
  } else if (contact.company) {
    preSpanText += `${preSpanText ? ' | ' : ''}${contact.company}`
  }
  if (contact.email) preSpanText += `${preSpanText ? ' | ' : ''}${contact.email}`
  if (contact.phone) postSpanText += `${preSpanText ? ' | ' : ''}${contact.phone}`
  return <span className={css(Styles.spanValue)}>{preSpanText}{postSpanText}</span>
}

function getContactInfoCellSpanMobile(contact) {
  const name = Helper.formatName(contact)
  let companyName = ''
  if (contact.friendlycompanyname) {
    companyName = contact.friendlycompanyname
  } else if (contact.company) {
    companyName = contact.company
  }

  return(
    <div className={css(Styles.spanValue, Styles.infoCellMobile)}>
      <span class={css(Styles.infoFieldMobile)}>{name && name}</span>
      <span class={css(Styles.infoFieldMobile)}>{companyName && companyName}</span>
      <span class={css(Styles.infoFieldMobile)}>{contact.email && contact.email}</span>
      {contact.phone && (<span className={css(Styles.phoneValue)} onClick={() => Helper.copyToClipboard(contact.phone)}>{contact.phone}</span>)}
      <span className={css(Styles.line3)}>{Helper.formatTimestamp(contact.createdTs)}</span>
    </div>
  )
}

export class CrmContacts2 extends BaseEntityPageSlixta {

  constructor(props) {
    super(props);
    // this.noAdd = true
    this.emptyText = 'No results. Check your search query or search filters.'
    this.pageKey = 'crm-contacts'
    this.pageTitle = Helper.getString('crm-contacts')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.isMobileView = Helper.isMobileView()
    this.apiData = {
      orgId: this.orgId,
    }
    this.staffUsers = []
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId

    this.xLabelsDifference = {
      Today: 2,
      Yesterday: 2,
      'Last 7 days': 1,
      'Last 30 days': 4,
      'Last 90 days': 6,
      'Last 6 months': 1,
      'Last 12 months': 2,
    }

    this.dateMap = {
      Today: '1h',
      Yesterday: '1h',
      'Last 7 days': '1d',
      'Last 30 days': '1d',
      'Last 90 days': '1d',
      'Last 6 months': '1M',
      'Last 12 months': '1M',
    }

    this.toFetch = [
      { key: 'crmcontacts', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 100, 'tags!': 'INTERNAL', _datetimezone: Helper.getLocalTimezone(), _dateinterval: this.dateMap['Last 12 months'] } },
      { key: 'crmcontactattrs', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
      { key: 'usersORG', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users'], { orgId: this.orgId }) },
      { key: 'usersME', method: 'GET', endPoint: ApiHelper.makeUrlPath(['users', 'me'], {}) },
      { key: 'crmsequences', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['campaigns'], queryParams: { orgId: this.orgId, iTypeState: 'DRIP-LIVE' } },
      { key: 'crminboxes', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      { key: 'crmemailtemplates', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmemailtemplates'], queryParams: { orgId: this.orgId, pb: 'fieldDef', includeShared: 'true', limit: 100 } },
      { key: 'crmusersettings', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['items', `settingscrmusersettings${this.orgId}-${this.staffUserId}-all`], queryParams: { groupId: `settingscrmusersettings${this.orgId}-${this.staffUserId}` } },
      { key: 'crmbulkimportjobs', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-crm-jobs'], queryParams: { orgId: this.orgId } },
    ]
    this.cellWidths = {
      Index: Styles.cellWidthSmall,
    }
    this.tableHeadersMap = {
      ...(this.isMobileView ? {} : {
        Index: (current) => current.__index + 1,
      }),
      Info: (current) => {
        return (
          <div>
            <Fragment>
              {!this.isMobileView && getContactInfoCellSpan(current)}
              {this.isMobileView && getContactInfoCellSpanMobile(current)}
              {!this.isMobileView && <br />}
            </Fragment>
            {!this.isMobileView && <Fragment>
              <span className={css(Styles.line3)}>{Helper.formatTimestamp(current.createdTs)}</span>
              <br />
            </Fragment>}
          </div>
        )
      },
      Info2: (current) => {
        return (
          <div>
            {!this.isMobileView && current.state &&
              <Fragment>
                <span className={css(Styles.line2)}>Status: {current.state}</span>
                <br />
              </Fragment>
            }
            {this.isMobileView && current.state &&
              <Fragment>
                <span className={css(Styles.line2, Styles.statusBadge)}>{current.state}</span>
              </Fragment>
            }
            {!this.isMobileView && current.source &&
              <Fragment>
                <span className={css(Styles.line2)}>Source: {current.source}</span>
                <br />
              </Fragment>
            }
            {!this.isMobileView && Array.isArray(current.tags) && current.tags.length > 0 &&
              <Fragment>
                <span className={css(Styles.line2)}>Tags: {current.tags.join(', ')}</span>
                <br />
              </Fragment>
            }
          </div>
        )
      },
      // Profile: (current) => { return Helper.formatName(current) }
    }
    this.noTableHead = true

    this.headerActions = []
    if (!this.isMobileView) {
      this.headerActions.push({
        label: 'Prospect',
        icon: 'search',
        onClick: () => this.openModalForProspect(),
      })
    }
    this.headerActions.push({
      label: 'Add',
      tooltip: 'Add Contact',
      icon: 'add',
      type: 'icon-label',
      onClick: () => this.openModalForAdd(),
    })
    if (!this.isMobileView) {
      this.headerActions.push({
        label: 'Import',
        tooltip: 'Import',
        icon: 'group_add',
        type: 'icon-label',
        onClick: () => {
          const fieldDef = getImportFieldDef(this.staffUsers, this.currentStaffUser, this.orgId)
          fieldDef.title = Helper.getString('importContacts')
          this.openModalForAdd(fieldDef)
        }
      })
    }
    this.headerActions.push({
      label: 'Refresh',
      tooltip: 'Refresh',
      icon: 'refresh',
      type: 'icon-label',
      onClick: () => this.fetchItems(),
    })

    this.state.searchFilters = {}
    this.state.filterOptions = []
    this.state.selectedTabData = {
      tabbedChart1: 0,
      tabbedChart2: 0,
    }

    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
    this.displayCheckBoxTable = !this.isMobileView
    this.checkboxTableItemNameSingular = 'contact'
    this.checkboxTableItemNamePlural = 'contacts'
  }

  componentDidUpdate() {
    if (this.props.location && this.props.location.state) {
      const { isMenuItemClicked } = this.props.location.state;
      if (isMenuItemClicked) {
        this.onList();
        this.props.history.replace({
          ...this.props.location,
          state: {}
        });
      }
    }
  }

  constructSearchFiltersQuery(searchFilters = {}) {
    const searchFiltersQuery = {}
    Object.keys(searchFilters).forEach(key => {
      let val = searchFilters[key]
      const isAny = val === 'any' || (Array.isArray(val) && val.length === 1 && val[0] === 'any')
      const isNone = val === 'none' || (Array.isArray(val) && val.length === 1 && val[0] === 'none')
      if (isAny || isNone) {
        if (isAny) {
          if (key.endsWith('!')) {
            key = key.slice(0, -1)
          } else {
            key = key + '!'
          }
        }
        val = ''
      }
      searchFiltersQuery[key] = Array.isArray(val) ? val.join(',') : val
    })

    if(!this.state.showInternal) {
      const tagsArray = (searchFiltersQuery['tags!'] || '').split(',').filter(t=>!!t)
      if (!tagsArray.includes('INTERNAL')) { tagsArray.push('INTERNAL') }
      searchFiltersQuery['tags!'] = tagsArray.join(',');
    }

    if('date' in searchFilters) {
      const val = searchFilters.date
      const currentTs = Helper.currentTs()
      searchFiltersQuery.lteCreatedTs = currentTs
      if(val === 'Today') {
        searchFiltersQuery.gteCreatedTs = getStartOfDayTs(currentTs)
      } else if(val === 'Yesterday') {
        searchFiltersQuery.gteCreatedTs = getStartOfDayTs(currentTs - (24 * 60 * 60 * 1000))
        searchFiltersQuery.lteCreatedTs = getStartOfDayTs(currentTs) - 1
      } else if(val === 'Last 7 days') {
        searchFiltersQuery.gteCreatedTs = currentTs - (7 * 24 * 60 * 60 * 1000)
      } else if(val === 'Last 30 days') {
        searchFiltersQuery.gteCreatedTs = currentTs - (30 * 24 * 60 * 60 * 1000)
      } else if(val === 'Last 90 days') {
        searchFiltersQuery.gteCreatedTs = currentTs - (90 * 24 * 60 * 60 * 1000)
      } else if(val === 'Last 6 months') {
        searchFiltersQuery.gteCreatedTs = currentTs - (6 * 30 * 24 * 60 * 60 * 1000)
      } else if(val === 'Last 12 months') {
        searchFiltersQuery.gteCreatedTs = currentTs - (12 * 30 * 24 * 60 * 60 * 1000)
      }
    }

    return searchFiltersQuery
  }

  fetchItem() {
    this.apiStartTs = Helper.currentTs()
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })
    const orgIdUserId = this.state.dialogApiData && this.state.dialogApiData.responseBody && this.state.dialogApiData.responseBody.id
    const searchFiltersQuery = this.constructSearchFiltersQuery(this.state.searchFilters)

    const queryParams = { orgId: this.orgId, query: this.state.searchText || '', pb: 'fieldDef', limit: 1, _datetimezone: Helper.getLocalTimezone(), _dateinterval: this.dateMap[this.state.searchFilters.date || 'Last 12 months'], ...searchFiltersQuery }
    const options = [
      { key: 'crmuser', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts', orgIdUserId], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { key: 'crmcontacts', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts'], queryParams }
    ]

    const fcns = [];
    for (const f of options) {
      fcns.push(function (callback) {
        ApiHelper.call(f, callback);
      })
    }

    async.parallel(fcns, async function (err, results) {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }

      const { fieldDef, _apiVersion, ...updatedResult } = results[0];
      const updatedItems = this.state.items.map(item => item.userId === results[0].userId ? updatedResult : item );
      this.setState({
        items: updatedItems,
        stats: results[1].stats,
        timezone: results[1].timezone,
        fetchState: ApiHelper.State.READY,
        loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
      })
    }.bind(this))
  }

  onCompleteAction(options) {
    if(this.formDefinition && this.formDefinition.loadingMode === ApiHelper.LOADING_MODE.INSTANT_LOAD) {
      this.setState({ modalOpen: false, dialogApiData: options.apiInvokeData, loadingMode: ApiHelper.LOADING_MODE.INSTANT_LOAD })
      this.fetchItem()
    } else {
      this.setState({ modalOpen: false, items: [], dialogApiData: options.apiInvokeData })
      this.fetchItems()
    }
  }

  onFetchSuccess(results, options) {
    const { fetchNext } = options || {}
    if (!fetchNext) {
      this.crmContactsResult = this.getResult(results, 'crmcontacts')
      this.fieldDef = this.formDefinition = this.crmContactsResult.fieldDef
      this.crmContactAttrs = this.getResult(results, 'crmcontactattrs')
      this.staffUsers = this.getResult(results, 'usersORG').items
      this.currentStaffUser = this.getResult(results, 'usersME')
      this.crmSequences = this.getResult(results, 'crmsequences').items.sort((a, b) => b.createdTs - a.createdTs)
      this.crmInboxes = this.getResult(results, 'crminboxes').items
      this.crmEmailTemplates = this.getResult(results, 'crmemailtemplates').items
      this.crmEmailTemplateFieldDef = this.getResult(results, 'crmemailtemplates').fieldDef
      this.crmUserSettings = this.getResult(results, 'crmusersettings').items
      this.crmBulkImportJobs = this.getResult(results, 'crmbulkimportjobs').items
      Helper.loadAttrOptions(this.fieldDef, this.crmContactAttrs, 'tags')
      UiHelper.populateOptions(this.fieldDef, {items: this.staffUsers}, 'assignee')
      if (this.currentStaffUser && this.currentStaffUser.id) {
        const assigneeField = this.fieldDef.steps[0].fields.find(f => f.key === 'assignee')
        assigneeField.defaultValue = `${this.orgId}-${this.currentStaffUser.id}`
      }
      this.apiData = {
        orgId: this.orgId,
        creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      }
    } else {
      this.crmContactsResult = results[0]
    }
    this.transform(this.crmContactsResult);
    this.handleNextpage(this.crmContactsResult)

    const { filterOptions, searchText, searchFilters, showInternal } = getFilterOptions(this.fieldDef, this.crmSequences, this.crmInboxes, this.crmBulkImportJobs)
    const p = new Promise((resolve, reject) => {
      try {
        this.setState({
          items: fetchNext ? this.state.items.concat(this.crmContactsResult.items) : this.crmContactsResult.items,
          stats: this.crmContactsResult.stats,
          timezone: this.crmContactsResult.timezone,
          filterOptions,
          searchText,
          searchFilters,
          showInternal: showInternal,
        }, () => {
          if (!fetchNext && (this.state.searchText || Object.keys(this.state.searchFilters).length > 0)) {
            resolve(this.onSearch(this.state.searchText, this.state.searchFilters, this.state.filterOptions))
          }
          resolve()
        })
      } catch (err) {
        reject(err)
      }
    })
    return p
  }

  transform(result) {
    result.items.forEach(i => {
      const displayLabels = [];
      if (i.cognitoVerified) displayLabels.push('OTP VERIFIED');
      if (i.createdOnFormSubmission) displayLabels.push('CREATED BY FORM');
      if (i.createdByImport) displayLabels.push('CREATED BY IMPORT');
      if (displayLabels.length <= 0 && (i.emailAdminVerified || i.phoneAdminVerified)) {
        displayLabels.push('CREATED ON CONSOLE');
      }
      if (i.source) displayLabels.push(`SOURCE: ${i.source}`)
      if (displayLabels.length > 0) {
        i.displayLabels = displayLabels;
      }
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  handleTabChange = (tab, chartType) => {
    this.setState({
      selectedTabData: {
        ...this.state.selectedTabData,
        [chartType]: tab
      }
    })
  }

  renderAboveTable() {

    const showStats = this.state.stats && this.state.stats.states && this.state.stats.tags
    const numContacts = this.state.stats && this.state.stats.states ? this.state.stats.states.buckets.reduce((p, c) => p + c.doc_count, 0) : 0
    const numOutreaches = this.state.stats && this.state.stats.tags ? (this.state.stats.tags.buckets.find(b => b.key === 'SEQSENT') || {}).doc_count || 0 : 0
    const numPropsects = this.state.stats && this.state.stats.states ? (this.state.stats.states.buckets.find(b => b.key === 'PROSPECT') || {}).doc_count || 0 : 0
    const numSubscriptions = this.state.stats && this.state.stats.subscriptions ? this.state.stats.subscriptions.buckets.reduce((p, c) => p + c.doc_count, 0) : 0
    const numLeads = numContacts - numPropsects

    const chartTabsData = {
      tabbedChart1: [
        {
          chart: 'contacts',
          title: 'Contacts',
          key: 'users_per_day',
          label: 'Contacts',
          type: 'lineChart'
        },
        {
          chart: 'outreaches',
          title: 'Outreaches',
          key: 'outreaches_per_day',
          label: 'Outreaches',
          type: 'lineChart'
        },
        {
          chart: 'leads',
          title: 'Leads',
          key: 'leads_per_day',
          label: 'Leads',
          type: 'lineChart'
        },
      ],
      tabbedChart2: [
        {
          chart: 'status',
          title: 'Status',
          key: 'states',
          label: 'Status',
          type: 'pieChart'
        },
        {
          chart: 'tags',
          title: 'Tags',
          key: 'tags',
          label: 'Tags',
          type: 'barChart'
        },
      ]
    }

    const xLabelsDifference = this.xLabelsDifference[this.state.searchFilters.date || 'Last 12 months'];
    let chartData1 = null
    if(chartTabsData.tabbedChart1) {
      const keyTabbedChart1 = chartTabsData.tabbedChart1[this.state.selectedTabData.tabbedChart1].key
      const labelTabbedChart1 = chartTabsData.tabbedChart1[this.state.selectedTabData.tabbedChart1].label
      const typeTabbedChart1 = chartTabsData.tabbedChart1[this.state.selectedTabData.tabbedChart1].type
      const date = this.state.searchFilters.date || 'Last 12 months'
      chartData1 = generateMultiChartData(this.state.stats, keyTabbedChart1, labelTabbedChart1, typeTabbedChart1, date)
    }
    let chartData2 = null
    if(chartTabsData.tabbedChart2) {
      const keyTabbedChart2 = chartTabsData.tabbedChart2[this.state.selectedTabData.tabbedChart2].key
      const labelTabbedChart2 = chartTabsData.tabbedChart2[this.state.selectedTabData.tabbedChart2].label
      const typeTabbedChart2 = chartTabsData.tabbedChart2[this.state.selectedTabData.tabbedChart2].type
      chartData2 = generateMultiChartData(this.state.stats, keyTabbedChart2, labelTabbedChart2, typeTabbedChart2)
    }
    return <>
      <div className={css(Styles.aboveTableContainer)}>
        {showStats &&
        <div className={css(Styles.statContainer)}>
          <div className={css(Styles.numericStatItem)}>
            <Grid container className={css(Styles.numericStatContainer)}>
              {numContacts > 0 && <StatCard
                xs={this.isMobileView ? ((numOutreaches === 0) ? 6 : 4) : 6}
                sm={this.isMobileView ? ((numOutreaches === 0) ? 6 : 4) : 12}
                key={'contacts'}
                text={numContacts}
                subText={'Contacts'}
                cardIndex={2}
                padding='small'
              />}
              {numOutreaches > 0 && <StatCard
                xs={this.isMobileView ? ((numContacts === 0) ? 6 : 4) : 6}
                sm={this.isMobileView ? ((numContacts === 0) ? 6 : 4) : 12}
                key={'outreaches'}
                text={numOutreaches}
                subText={'Outreaches'}
                cardIndex={3}
                padding='small'
              />}
              <StatCard
                xs={this.isMobileView ? ((numContacts === 0 && numOutreaches === 0) ? 12 : ((numContacts === 0 || numOutreaches === 0) && (numContacts !== numOutreaches)) ? 6 : 4) : 6}
                sm={this.isMobileView ? ((numContacts === 0 && numOutreaches === 0) ? 12 : ((numContacts === 0 || numOutreaches === 0) && (numContacts !== numOutreaches)) ? 6 : 4) : 12}
                key={'leads'}
                text={numLeads}
                subText={'Leads'}
                cardIndex={1}
                padding='small'
              />
            </Grid>
          </div>
          {chartData1 && (<div className={css(Styles.pieStatItem)}>
            <TabChartCard chartType={'tabbedChart1'} tabsData={chartTabsData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData} handleTabChange={this.handleTabChange} chartData={chartData1} isMultiChart={true}/>
          </div>)}
          {chartData2 && (<div className={css(Styles.barStatItem)}>
            <TabChartCard chartType={'tabbedChart2'} tabsData={chartTabsData} xLabelsDifference={xLabelsDifference} selectedTabData={this.state.selectedTabData} handleTabChange={this.handleTabChange} chartData={chartData2} isMultiChart={true}/>
          </div>)}
        </div>
        }
        <SearchBar
          hint={'Type Name, Email or Phone'}
          onSearch={this.onSearch.bind(this)}
          onList={this.onList.bind(this)}
          onFiltersBtnClick={this.onFiltersBtnClick.bind(this)}
          getUpdatedSearchFilters={getUpdatedSearchFilters}
          getTransformedKey={getTransformedKey}
          searchText={this.state.searchText}
          searchFilters={this.state.searchFilters}
          filterOptions={this.state.filterOptions}
          searchButtonLabel='Search'
          listButtonLabel='Reset Filters'
          isQuickFilterDisabled={true}
          autoRefresh={false}>
        </SearchBar>
      </div>
    </>
  }

  onSearch = (searchText = '', searchFilters = {}, filterOptions = []) => {
    Object.keys(searchFilters).forEach(k => {
      if (Array.isArray(searchFilters[k]) && searchFilters[k].length === 0) {
        delete searchFilters[k]
      } else if (!Array.isArray(searchFilters[k]) && searchFilters[k] === '') {
        delete searchFilters[k]
      }
    })
    updateWindowUrl(searchText, searchFilters)
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: searchText,
      searchFilters: searchFilters,
      filterOptions: filterOptions,
    })
    const searchFiltersQuery = this.constructSearchFiltersQuery(searchFilters)
    const queryParams = { orgId: this.orgId, query: searchText, pb: 'fieldDef', limit: 100, _datetimezone: Helper.getLocalTimezone(), _dateinterval: this.dateMap[searchFilters.date || 'Last 12 months'], ...searchFiltersQuery }
    const options = { key: 'crmcontacts', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts'], queryParams }
    this.toFetch[0] = options

    return ApiHelper.callAwait(options).then(result => {
      this.transform(result)
      this.handleNextpage(result)
      this.setState({
        items: (result && result.items) || [],
        stats: result.stats,
        fetchState: ApiHelper.State.READY,
      })
    }).catch(err => {
      console.log('Search Error:', err)
    })
  }

  onFiltersBtnClick = (searchText = '', searchFilters = {}, filterOptions = [], isFilterChanged = false, isSearchTextChanged = false) => {
    this.setState({
      searchText: searchText,
      searchFilters: searchFilters,
      filterOptions: filterOptions,
    }, () => {
      const filtersFieldDef = getFilterDialogFieldDef(filterOptions)
      const normalizedSearchFilters = getNormalizedSearchFilters(filterOptions, searchFilters)
      this.openModalForFilter(normalizedSearchFilters, filtersFieldDef);
    })
  }

  onList = () => {
    updateWindowUrl()
    this.toFetch[0] =  { key: 'crmcontacts', ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['crmcontacts'], queryParams: { orgId: this.orgId, pb: 'fieldDef', limit: 100, 'tags!': 'INTERNAL', _datetimezone: Helper.getLocalTimezone(), _dateinterval: this.dateMap['Last 12 months'] } }
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      searchText: '',
      searchFilters: {},
    }, () => this.fetchItems())
  }

  openModalForProspect() {
    this.apiData = {
      orgId: this.orgId,
      creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      isProspect: true,
    }
    this.formDefinition = getProspectFieldDef(this.staffUsers, this.currentStaffUser, this.orgId)
    this.formDefinition.formType = 'add'
    this.formDefinition.title = Helper.getString('prospectContacts')
    this.setState({
      modalOpen: true
    });
  }

  openModalForAdd(fieldDef, apiData = {}) {
    this.apiData = {
      orgId: this.orgId,
      creatorId: `${this.orgId}-${this.currentStaffUser.id}`,
      ...apiData,
    }

    let fieldDefinition = null
    if(fieldDef) {
      fieldDefinition = JSON.parse(JSON.stringify(fieldDef))
    } else if(this.fieldDef) {
      fieldDefinition = JSON.parse(JSON.stringify(this.fieldDef))
      fieldDefinition.title = Helper.getString('addContact')
    }

    super.openModalForAdd(fieldDefinition)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'create-crm-contact'],
        queryParams: { orgId: this.orgId }
      }),
    });
  }

  openModalForEdit(current) {
    this.apiData = {
      orgId: this.orgId,
    }
    const fieldDef = JSON.parse(JSON.stringify(this.crmContactsResult.fieldDef))
    fieldDef.title = Helper.getString('editContact')
    fieldDef.loadingMode = ApiHelper.LOADING_MODE.INSTANT_LOAD
    super.openModalForEdit(current, fieldDef)
  }

  openModalForInfo(current) {
    this.apiData = {
      orgId: this.orgId,
    }
    const fieldDef = JSON.parse(JSON.stringify(this.crmContactsResult.fieldDef))
    fieldDef.title = (current.givenname || current.familyname)  ? Helper.formatName(current)  : (current.email || current.phone || Helper.getString('moreDetails') )
    super.openModalForInfo(current,fieldDef)
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'update-crm-contact'],
        queryParams: { id: current.id, orgId: this.orgId }
      }),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonInfo(this, current, Helper.getString('moreinfo')))
    actions.push(UiHelper.buttonEdit(this, current))
    if(!this.isMobileView) {
      actions.push(
        <Link to={this.baseRoute() + '/crm-contacts/' + current.userId} key={current.userId}>
          <Tooltip title='View Contact' placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>arrow_forward_ios</i>
            </span>
          </Tooltip>
        </Link>
      )
    }
    return actions
  }

  onMenuItems = (current) => {
    const actions = []
    const menuItems = []
    if(!this.isMobileView) {
      if (current.email) {
        menuItems.push(
          {
            current: current,
            key: current.id + 'enroll',
            action: () => UiHelper.openModalForSequences.call(this, current, this.crmSequences, this.crmInboxes),
            title: 'Enroll',
            icon: <DynamicFeedOutlinedIcon/>,
          }
        )
      }
      menuItems.push(
        {
          current: current,
          key: current.id + 'enrich',
          action: () => UiHelper.openModalForEnrich.call(this, current),
          title: 'Enrich',
          icon: 'dynamic_form',
        }
      )
      menuItems.map(item => {
        actions.push(UiHelper.actionButton(item))
      })
    }
    return actions
  }

  checkBoxMenuActions = (checkedItems) => {
    const actions = []
    const menuItems = []
    menuItems.push(
      {
        key: 'enroll',
        action: () => UiHelper.openModalForSequences.call(this, checkedItems, this.crmSequences, this.crmInboxes),
        title: 'Enroll',
        icon: <DynamicFeedOutlinedIcon/>,
      }
    )
    menuItems.push(
      {
        key: 'enrich',
        action: () => UiHelper.openModalForEnrich.call(this, checkedItems),
        title: 'Enrich',
        icon: 'dynamic_form',
      }
    )
    menuItems.push(
      {
        key: 'update',
        action: () => UiHelper.openModalForUpdate.call(this, checkedItems),
        title: 'Update',
        icon: 'edit',
      }
    )
    menuItems.map(item => {
      actions.push(UiHelper.actionButton(item))
    })
    return actions
  }

  checkBoxActions = (checkedItems) => {
    const headerActions = []
    headerActions.push(
      <Tooltip title={'Enroll'} placement='top-start' key={'enroll'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <DynamicFeedOutlinedIcon onClick={() => UiHelper.openModalForSequences.call(this, checkedItems, this.crmSequences, this.crmInboxes)}/>
        </span>
      </Tooltip>
    )
    return headerActions
  }

  async customSubmitForProspecting(options) {
    const { formType, formData, caller } = options

    delete formData.isProspect

    ApiHelper.callAwait({
      method: 'POST',
      endPoint: ApiHelper.makeUrlPath2({
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'create-prospect'],
        queryParams: {},
      }),
      jsonBody: formData,
    }).then(response => {
      if (formData.prospectSource === 'csv') {
        if (response.csvUrl) {
          window.open(response.csvUrl, '_blank')
          caller.setState({
            fetchState: ApiHelper.State.READY,
          })
          this.setState({
            modalOpen: false,
          })
          return
        } else if (response.action === 'BULK_PROSPECT_CRM_CONTACTS') {
          window.open(this.baseRoute() + '/jobscrm', '_self')
          return
        } else {
          throw new Error('Csv not found')
        }
      }
      const fieldDef = JSON.parse(JSON.stringify(this.fieldDef))
      Object.keys(response.prospect).forEach(key => {
        const field = fieldDef.steps[0].fields.find(f => f.key === key)
        if (field) {
          field.defaultValue = response.prospect[key]
        }
      })
      const sourceKey = fieldDef.steps[0].fields.find(f => f.key === 'source')
      sourceKey.defaultValue = 'PROSPECTING'
      const stateKey = fieldDef.steps[0].fields.find(f => f.key === 'state')
      stateKey.defaultValue = 'PROSPECT'
      this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
        const fieldDefinition = JSON.parse(JSON.stringify(fieldDef))
        fieldDefinition.title = Helper.getString('addContact')
        this.openModalForAdd(fieldDefinition, { createdBy: 'PROSPECT', prospectSource: formData.prospectSource })
      })
    }).catch(err => {
      const errorMessage = Helper.getErrorMsg(err);
      caller.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: errorMessage,
        success: false,
        message: errorMessage,
        inProcess: false
      })
    })
  }

  async customSubmitForEnrich(options) {
    const { formType, formData, caller } = options

    delete formData.enrichFields

    const apiMethod = 'POST'
    const apiEndpoint = ApiHelper.makeUrlPath2({
      ms: AppConfig.CDP_MS.ENDPOINT,
      paths: ['miscs', 'enrich-crm-contact'],
      queryParams: { orgId: this.orgId },
    })
    ApiHelper.callAwait({
      method: apiMethod,
      endPoint: apiEndpoint,
      jsonBody: formData,
    }).then(response => {
      if (response._enrichResponse) {
        this.setState({
          success: true,
          message: Helper.getString('successful'),
          inProcess: false
        })
        this.onCompleteAction({
          apiInvokeData: {
            method: apiMethod,
            endPoint: apiEndpoint,
            reqBody: formData,
            responseBody: response,
          }
        })
      } else {
        window.open(this.baseRoute() + '/jobscrm', '_self')
      }
    }).catch(err => {
      const errorMessage = Helper.getErrorMsg(err);
      caller.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: errorMessage,
        success: false,
        message: errorMessage,
        inProcess: false
      })
    })
  }

  async customSubmitForUpdate(options) {
    const { formType, formData, caller } = options

    formData.values = {tags: formData.updateTags}
    delete formData.updateTags
    const apiMethod = 'POST'
    const apiEndpoint = ApiHelper.makeUrlPath2({
      ms: AppConfig.CDP_MS.ENDPOINT,
      paths: ['miscs', 'update-crm-contact-bulk'],
      queryParams: { orgId: this.orgId },
    })
    ApiHelper.callAwait({
      method: apiMethod,
      endPoint: apiEndpoint,
      jsonBody: formData,
    }).then(response => {
      if (response._enrichResponse) {
        this.setState({
          success: true,
          message: Helper.getString('successful'),
          inProcess: false
        })
        this.onCompleteAction({
          apiInvokeData: {
            method: apiMethod,
            endPoint: apiEndpoint,
            reqBody: formData,
            responseBody: response,
          }
        })
      } else {
        window.open(this.baseRoute() + '/jobscrm', '_self')
      }
    }).catch(err => {
      const errorMessage = Helper.getErrorMsg(err);
      caller.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: errorMessage,
        success: false,
        message: errorMessage,
        inProcess: false
      })
    })
  }

  async onFilterSubmit(options) {
    const { formType, formData, filterOptions, caller } = options
    const searchFilters = JSON.parse(JSON.stringify(formData));
    const updatedSearchFilters = getUpdatedSearchFilters(filterOptions, searchFilters)
    this.setState({modalOpen: false })
    return this.onSearch(this.state.searchText, updatedSearchFilters, filterOptions)
  }

  async customSubmit(options) {
    const { formType, formData, caller } = options
    if ('seqEnrolmentNextStepIndex' in formData) { return UiHelper.customSubmitForSequences.call(this, options) }
    if (formData.isProspect) { return this.customSubmitForProspecting(options) }
    if (formData.enrichFields) { return this.customSubmitForEnrich(options) }
    if (formData.updateTags) { return this.customSubmitForUpdate(options) }
    if (formData.importCsv) {
      try {
        await ApiHelper.callAwait({
          method: 'POST',
          endPoint: ApiHelper.makeUrlPath2({
            ms: AppConfig.CDP_MS.ENDPOINT,
            paths: ['miscs', 'create-crm-contact'],
            queryParams: { orgId: this.orgId },
          }),
          jsonBody: formData,
        })
        window.open(this.baseRoute() + '/jobscrm', '_self')
        return
      } catch(err) {
        const errorMessage = Helper.getErrorMsg(err);
        caller.setState({
          fetchState: ApiHelper.State.ERROR,
          errMsg: errorMessage,
          success: false,
          message: errorMessage,
          inProcess: false
        })
        return
      }
    }

    return caller.submit.call(caller, formData, true);
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 14
  },
  infoCellMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 2,
  },
  infoFieldMobile: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '50vw'
  },
  cellWidthSmall: {
    width: '6%',
  },
  line2: {
    fontSize: 10,
    color: 'grey'
  },
  statusBadge: {
    backgroundColor: AppTheme.primaryBg,
    // color: 'white',
    padding: 5,
    borderRadius: 10,
    margin: 5,
    wordBreak: 'keep-all',
    display: 'block',
    textAlign: 'center'
  },
  line3: {
    fontSize: 12,
    color: 'grey'
  },
  aboveTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  statContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
    },
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  numericStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      height: '100%',
    },
    width: '20%',
  },
  pieStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '42%',
  },
  barStatItem: {
    '@media (max-width: 767px)': {
      width: '90%',
      alignSelf: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
    },
    width: '35%',
  },
  numericStatContainer: {
    height: '100%',
    maxHeight: '100%',
    maxWidth: AppTheme.viewPort,
    textAlign: 'center',
    display: 'flex',
    border: '1px solid ' + AppTheme.borderColor,
    padding: 16,
    margin: 0,
    borderRadius: AppTheme.borderRadius,
    //justifyContent: 'left',
    '@media (max-width: 767px)': {
      padding: 8
    },
  },
  barChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  phoneValue: {
    cursor: 'pointer',
    color: AppTheme.primaryColor,
  }
})