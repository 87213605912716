import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { AppConfig } from '../AppConfig';
import { AppTheme } from '../styles/AppTheme';
import { PageAnalytics } from '../common/helpers/PageAnalytics'
import { CommonStyles } from '../styles/Styles';

export class PublicFooter extends Component {
  render() {
    return (
      <footer className={css(Styles.footer)} style={(window.location.pathname === '/' || window.location.pathname === '/login') ? { width: '55%' } : { width: '100%' }} >
        <div className={css(Styles.container, CommonStyles.pagePadding)}>
          <div className={css(Styles.leftContainer)}>
            <p className={css(Styles.cName)}>{AppConfig.COMPANY_NAME}</p>
            <a className={css(Styles.email)} href={'mailto:' + AppConfig.SUPPORT_EMAIL}>{AppConfig.SUPPORT_EMAIL}</a>
          </div>

          <div className={css(Styles.rightContainer)}>
            <div className={css(Styles.rightInnerContainer)}>
              <Link
                onClick={() => PageAnalytics.gaTrkEvent(
                  'footer',
                  'terms-click',
                  'footer-terms',
                  null)}
                className={css(Styles.footerListitem)}
                target='_blank'
                to={{ pathname: AppConfig.TERMS }}>
                <p className={css(Styles.footerListlink)}>Terms</p>
              </Link>
              <div className={css(Styles.separator)}>|</div>
              <Link
                onClick={() => PageAnalytics.gaTrkEvent(
                  'footer',
                  'privacy-click',
                  'footer-privacy',
                  null)}
                className={css(Styles.footerListitem)}
                target='_blank'
                to={{ pathname: AppConfig.PRIVACY_POLICY }}>
                <p className={css(Styles.footerListlink)}>Privacy</p>
              </Link>
            </div>
            <div className={css(Styles.rightInnerContainer)}>
              <p className={css(Styles.version)}>Version {AppConfig.VERSION} | Copyright © {(new Date()).getFullYear()}</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }

}

const Styles = StyleSheet.create({
  leftContainer: {
    flex: 1,
    '@media (max-width: 767px)': {
      display: 'none'
    }
  },
  footer: {
    backgroundColor: '#12214E',
    position: 'absolute',
    bottom: 0,
    '@media (max-width: 767px)': {
      width: '100%',
      // position: 'unset'
      backgroundColor: '#ffffff',
    }
  },
  container: {
    fontSize: 16,
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    textAlign: 'left',
    '@media (max-width: 767px)': {
      textAlign: 'center',
      flexDirection: 'column',
      paddingTop: 10,
      paddingBottom: 10,
    }
  },
  cName: {
    padding: 0,
    margin: 0,
    marginBottom: 5,
    color: AppTheme.secondaryBg,
    fontSize: 16,
  },
  email: {
    padding: 0,
    margin: 0,
    marginBottom: 5,
    color: AppTheme.secondaryBg,
    textDecoration: 'none',
    fontSize: 16,
  },
  rightContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
      marginTop: 0
    }
  },
  footerListitem: {
    fontSize: 16,
    padding: 0,
    marginBottom: 5,
    textDecoration: 'none',
    '@media (max-width: 767px)': {
      textAlign: 'center'
    }
  },
  footerListlink: {
    cursor: 'pointer',
    margin: 0,
    color: AppTheme.secondaryBg,
    fontSize: 16,
    textDecoration: 'none',
    '@media (max-width: 767px)': {
      color: '#000000'
    }
  },
  version: {
    color: AppTheme.secondaryBg,
    fontSize: 16,
    margin: 0,
    '@media (max-width: 767px)': {
      display: 'none',
      color: '#000000'
    }
  },
  rightInnerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
    }
  },

  separator: {
    margin: '0px 5px',
    color: AppTheme.secondaryBg,
    '@media (max-width: 767px)': {
      color: '#000000',
    }
  }
})
