import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { AppConfig } from '../../AppConfig';

export class JobsCrm extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = 'Jobs'
    this.pageKey = 'jobs'
    this.orgId = this.props.match.params.orgId
    this.breadCrumbs = [
      { title: Helper.getString('backToCRM'), to: this.baseRoute() + '/crm-contacts', type: 'button', icon: 'arrow_back_ios' },
    ]
    this.toFetch = [
      { ms: AppConfig.CDP_MS.ENDPOINT, method: 'GET', paths: ['miscs', 'get-crm-jobs'], queryParams: { orgId: this.orgId } },
    ]
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Job: (current) => {
        return (
          <div className={css(Styles.jobsColumn)}>
            <span className={css(Styles.spanValue)}>{current.action}</span>
            <br/>
            <span className={css(Styles.spanValue)}>Job started at: {Helper.formatTimestamp(current.createdTs)}</span>
            <br/>
          </div>
        )
      },
      Status: (current) => {
        const errorMessage = current.result && current.result.payload ? current.result.payload.message : current.result && current.result.message ? current.result.message : 'Unknown'
        return (
          <div>
            <span className={css(Styles.spanValue)}>{current.state}</span>
            <br/>
            {current.state === 'SUCCESS' &&
              <>
                {current.result.message && <><span className={css(Styles.spanValue)}>{current.result.message}</span><br/></>}
                {Array.isArray(current.result.links) && current.result.links.map(l => <><span className={css(Styles.spanValue)}><a href={l.url}>{l.label}</a></span><br/></>)}
              </>
            }
            {current.state === 'ERROR' &&
              <span>
                <span className={css(Styles.spanValue)}>
                  {errorMessage}
                </span>
                <br/>
              </span>
            }
          </div>
        )
      },
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.noAdd = true
    this.apiData = {
      groupId: this.orgId
    }
    this.headerActions = [
      {
        label: 'Refresh',
        icon: 'refresh',
        onClick: () => this.fetchItems()
      }
    ]
  }

  onFetchSuccess(results) {
    // this.fieldDef = this.formDefinition = results[0].fieldDefJob
    // const fieldAction = this.formDefinition.steps[0].fields.find(i => i.key === 'action')
    // fieldAction.options = fieldAction.options.filter(i => !i.key.startsWith('_'))
    this.setState({
      items: results[0].items,
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions(current) {
    const actions = []
    return actions
  }
}

const Styles = StyleSheet.create({
  spanValue: {
    fontSize: 12
  },
  jobsColumn: {
    minWidth: 300,
    '@media (max-width: 767px)': {
      minWidth: 150,
    },
  }
})