import React, { Component } from 'react';

import { StyleSheet, css } from 'aphrodite';
import { dataItem } from '../data/data';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AppTheme, AppStyles } from '../styles/AppTheme';
import { Controller } from '../common/Controller';
import { Helper } from '../common/helpers/Helper';
import { CommonTheme } from '../styles/Styles';
import { Banner } from '../components/Banner';
import { AppButton } from '../common/components/AppButton';
import { CommonStyles } from '../styles/Styles';
import { HelmetComponent } from '../common/components/HelmetComponent';
import { AppConfig } from '../AppConfig';
import { Link } from 'react-router-dom';

export class Home extends Component {
  constructor(props) {
    super(props)
    this.data = dataItem.homePage
  }

  render() {
    return (
      <Banner>
        <HelmetComponent page={'home'} />
        <h1 className={css(Styles.titleL1)}>{this.data.mainTitle}</h1>
        <h2 className={css(Styles.titleL2)}>{this.data.subTitle}</h2>
        {/* <p className={css(Styles.titleL3)}>{this.data.subTitle2}</p> */}
        <div className={css(Styles.landingButtonConatiner)}>
          <AppButton
            fullWidth
            buttonStyle={CommonStyles.buttonStyle}
            buttonTextStyle={CommonStyles.buttonTextStyle}
            onClick={() => this.getSupport()}
            buttonText={Helper.getString('ctaButtonLabel')}
          />
          <Link target='_blank' to={{pathname: AppConfig.SLIXTA_WEB}} className={css(Styles.link)}>
            <AppButton
              buttonStyle={Styles.buttonHomeStyle}
              buttonTextStyle={Styles.buttonTextHomeStyle}
              buttonText={Helper.getString('knowAboutSlixta')}
            />
          </Link>
        </div>
      </Banner>
    );
  }

  getSupport() {
    const pathname = '/login'
    Controller.get().pushRoute(pathname);
  }
}

const Styles = StyleSheet.create({
  titleL1: {
    fontSize: 36,
    margin: 0,
    fontWeight: 700,
    color: CommonTheme.textColor,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      fontSize: 22,
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
      fontSize: 42,
    }
  },
  titleL2: {
    fontSize: 22,
    margin: 0,
    marginTop: 28,
    color: CommonTheme.textColor,
    fontWeight: 600,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      fontSize: 16,
    },
    // '@media(min-width: 768px) and (max-width: 1024px)': {
    //   fontSize: 20,
    // }
  },
  titleL3: {
    fontSize: 18,
    margin: 0,
    marginTop: 12,
    color: CommonTheme.textColor,
    textAlign: 'center',
  },
  landingButtonConatiner: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    marginTop: 20
  },
  buttonHomeStyle: {
    backgroundColor: AppTheme.secondaryBg,
    border: '1px solid #0246E5',
    borderRadius: AppTheme.borderRadius,
    minWidth: 150,
    padding: 19,
    height: 75,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      height: 54,
    }
  },
  buttonTextHomeStyle: {
    fontSize: 20,
    fontWeight: 500,
    color: AppTheme.primaryColor,
    textTransform: 'none',
  },
})