import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles, AppTheme } from '../../../styles/AppTheme';
import { AppUiHelper } from '../../../helpers/AppUiHelper';
import { Controller } from '../../../common/Controller';
import { SubEntityCard } from '../../../common/components/SubEntity/SubEntityCard';
import { SubEntityList } from '../../../common/components/SubEntity/SubEntityList';
import { CommonStyles } from '../../../styles/Styles';
import { StatCard } from '../../../common/components/StatCard';
import { CbSelection } from '../../../common/components/CbSelection';
import { UploadHelper } from '../../../common/helpers/UploadHelper';
import { AppHelper } from '../../../helpers/AppHelper';

function getCampaignFields(obj) {
  const transformField = (field, iType) => {
    const outField = JSON.parse(JSON.stringify(field))
    if (field.condition && ((field.condition.value && field.condition.value === iType) || (field.condition.values && field.condition.values.includes(iType)))) {
      delete outField.condition
    }
    return outField
  }
  return {
    AD: obj.fieldDefCampaignAd.steps[0].fields.map(f => transformField(f, 'AD')),
    BROADCAST: obj.fieldDefCampaignBroadcast.steps[0].fields.map(f => transformField(f, 'BROADCAST')),
    TRANSACTIONAL: obj.fieldDefCampaignTransactional.steps[0].fields.map(f => transformField(f, 'TRANSACTIONAL')),
    NOTIFICATION: obj.fieldDefCampaignNotification.steps[0].fields.map(f => transformField(f, 'NOTIFICATION')),
    DRIP: obj.fieldDefCampaignDrip.steps[0].fields.map(f => transformField(f, 'DRIP')),
  }[obj.campaignType] || []
}
function restrictChannels(fieldDef, channels, defaultValue) {
  const channelField = fieldDef.steps[0].fields.find(f => f.key === 'channel')
  channelField.options = channelField.options.filter(o => channels.includes(o.key))
  channelField.defaultValue = defaultValue
}

export class CampaignDetail extends BaseEntityPageSlixta {
  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.pageTitle = 'Campaign Detail'
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.campaignId = this.props.match.params.id
    this.staffUserId = Controller.get().getUserId()
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('backToBroadcasts'), to: this.baseRoute() + '/campaigns/broadcasts', type: 'button', icon: 'arrow_back_ios' },
    ]
    this.toFetch = [
      { key: 'campaign', ms: this.ms, method: 'GET', paths: ['campaigns', this.campaignId], queryParams: { orgId: this.orgId, pb: 'fieldDefCampaignAd,fieldDefCampaignBroadcast,fieldDefCampaignTransactional,fieldDefCampaignNotification,fieldDefCampaignDrip,fieldDefCampaignSchedule' } },
      //{ key: 'crminboxes', ms: this.ms, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      //{ key: 'crmcontactattrs', ms: this.ms, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
    ]
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = this.pageTitle
    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
    this.noAdd = true
    this.entityAttrName = 'blocks'
  }

  async onFetchSuccess(results) {
    this.campaign = this.getResult(results, 'campaign')
    this.campaignType = this.campaign.iType

    if(this.campaign.state === 'DRAFT') {
      this.state.orderUIMode = ApiHelper.ORDER_UI_MODE.INTERACTIVE
    }

    if (this.campaignType === 'BROADCAST') this.csegments = await ApiHelper.callAwait({ key: 'csegments', ms: this.ms, method: 'GET', paths: ['csegments'], queryParams: { orgId: this.orgId, limit: 100 } })
    if (['BROADCAST', 'NOTIFICATION', 'TRANSACTIONAL'].includes(this.campaignType)) {
      this.emailpagesOrg = await ApiHelper.callAwait({ key: 'emailpages', method: 'GET', paths: ['emailpages'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } })
      this.emailpagesPRODUCT = await ApiHelper.callAwait({ key: 'emailpages-PRODUCT', method: 'GET', paths: ['emailpages'], queryParams: { orgId: 'PRODUCT' } })
    }
    //this.sharedCrmInboxes = this.getResult(results, 'crminboxes').items.filter(i => i.sharedInbox === 'YES')
    //this.crmContactAttrs = this.getResult(results, 'crmcontactattrs')

    this.fieldDefCampaignAd = this.campaign.fieldDefCampaignAd
    this.fieldDefCampaignBroadcast = this.campaign.fieldDefCampaignBroadcast
    this.fieldDefCampaignTransactional = this.campaign.fieldDefCampaignTransactional
    this.fieldDefCampaignNotification = this.campaign.fieldDefCampaignNotification
    this.fieldDefCampaignDrip = this.campaign.fieldDefCampaignDrip
    this.fieldDefCampaignSchedule = this.campaign.fieldDefCampaignSchedule
    if (this.campaignType === 'BROADCAST') this.fieldDef = this.fieldDefCampaignBroadcast
    else if (this.campaignType === 'NOTIFICATION') this.fieldDef = this.fieldDefCampaignNotification
    else if (this.campaignType === 'TRANSACTIONAL') this.fieldDef = this.fieldDefCampaignTransactional
    else if (this.campaignType === 'DRIP') this.fieldDef = this.fieldDefCampaignDrip
    else if (this.campaignType === 'AD') this.fieldDef = this.fieldDefCampaignAd

    restrictChannels(this.fieldDefCampaignAd, ['OOH'], 'OOH')
    restrictChannels(this.fieldDefCampaignBroadcast, ['EMAIL'], 'EMAIL')
    restrictChannels(this.fieldDefCampaignTransactional, ['EMAIL'], 'EMAIL')
    restrictChannels(this.fieldDefCampaignNotification, ['EMAIL'], 'EMAIL')

    this.fieldDefCampaignBroadcast = UiHelper.removeFields(this.fieldDefCampaignBroadcast, ['templateId'])
    this.fieldDefCampaignBroadcast.steps[0].fields.push({
      type: 'edit_text',
      key: 'state',
      label: 'Status',
      tip: 'Current state of the campaign',
      disabled: true,
      placement: 'left',
      v_required: { value: true, err: 'Provide status' },
    })
    this.fieldDefCampaignBroadcast.steps[0].fields.find(f => f.key === 'channel').disabled = true
    this.fieldDefCampaignBroadcast = UiHelper.reflowFields(this.fieldDefCampaignBroadcast)

    if (this.campaignType === 'AD') {
      this.fieldDefCampaignSchedule.disableAlwaysFormFields = ['scheduleType']
    } else {
      UiHelper.fieldDefDeleteField(this.fieldDefCampaignSchedule, 'scheduleEndTs')
    }

    if (this.csegments) {
      this.segments = this.csegments.items.filter(i => i.state === 'LIVE')
      UiHelper.populateOptions(this.fieldDefCampaignBroadcast, {items: this.segments}, 'segmentId')
    } else {
      this.segments = []
    }

    if (this.emailpagesOrg && this.emailpagesPRODUCT) {
      this.emailPages = [
        ...this.emailpagesOrg.items,
        ...this.emailpagesPRODUCT.items.filter(i => i.state === 'LIVE').map(i => ({...i, title: `[${i.title}]`})),
      ]
      UiHelper.populateOptions(this.fieldDefCampaignBroadcast, {items: this.emailPages}, 'templateId')
      UiHelper.populateOptions(this.fieldDefCampaignTransactional, {items: this.emailPages}, 'templateId')
      UiHelper.populateOptions(this.fieldDefCampaignNotification, {items: this.emailPages}, 'templateId')
    } else {
      this.emailPages = []
    }

    //UiHelper.populateOptions(this.fieldDefCampaignDrip, {items: this.sharedCrmInboxes.map(i => ({id: i.id, title: i.email}))}, 'triggerInboxId')
    //UiHelper.populateOptions(this.fieldDefCampaignDrip, {items: Helper.extractAttrs(this.crmContactAttrs, 'tags').map(t => ({id: t, title: t}))}, 'triggerOnTags')

    if (this.campaignType === 'BROADCAST') {
      this.emailTemplate = await ApiHelper.callAwait({ method: 'GET', paths: ['emailpages', this.campaign.templateId], queryParams: { orgId: this.orgId, pb: 'fieldDef,fieldDefPageBlock' } })
      this.emailTemplate.subject = this.emailTemplate.subject || ''
      this.emailTemplate.previewText = this.emailTemplate.previewText || ''
      this.emailTemplate.titleText = this.emailTemplate.titleText || ''
      this.emailTemplateFieldDef = this.emailTemplate.fieldDef
      this.emailTemplateFieldDef = UiHelper.removeFields(this.emailTemplateFieldDef, ['title', 'state'])
      this.emailTemplateFieldDef = UiHelper.reflowFields(this.emailTemplateFieldDef)
      this.emailTemplateSubject = this.emailTemplateFieldDef.steps[0].fields.find(o => o.key === 'subject')
      this.emailTemplateSubject.tip = 'The subject of the email'
      this.emailTemplatePreviewText = this.emailTemplateFieldDef.steps[0].fields.find(o => o.key === 'previewText')
      this.emailTemplatePreviewText.tip = 'The inbox preview of the email (shown by some email clients like Gmail)'
      this.emailTemplatePreviewText.advanced = true
      this.emailTemplateTitleText = this.emailTemplateFieldDef.steps[0].fields.find(o => o.key === 'titleText')
      this.emailTemplateTitleText.tip = 'The title of the email (shown by some web email clients in the title bar or page tab)'
      this.emailTemplateTitleText.advanced = true
      this.cbSource = this.emailTemplate.fieldDefPageBlock.steps[0].fields.find(o => o.key === 'cbSource')
      if(this.cbSource && this.cbSource.options) {
        this.cbSource.options = this.cbSource.options.filter(o => o.key === 'GLOBAL')
      }
      this.cbGlobal = await ApiHelper.callAwait({ method: 'GET', paths: ['emailcbdefs'], queryParams: { orgId: 'PRODUCT', pb: 'fieldDef' } })
      this.cbGlobalById = Helper.itemsToMap(this.cbGlobal.items)
      UiHelper.populateOptions(this.emailTemplate.fieldDefPageBlock, this.cbGlobal, 'cbGlobalId')
      const widgets = Helper.sortDictArray(Helper.dictToArray(this.emailTemplate.blocks))
      widgets.forEach(i => {
        if(i.cbGlobalId && this.cbGlobalById[i.cbGlobalId]) {
          i._widgetName = this.cbGlobalById[i.cbGlobalId].title
          i._cbstyle = this.cbGlobalById[i.cbGlobalId].cbstyle
          i._singleton = this.cbGlobalById[i.cbGlobalId].singleton
          i._cbScopeId = 'p' + this.orgId + '-' + i.id + '-' + i.cbGlobalId
        }
      })
      this.emailTemplateHeaders = {
        Order: current => current.order,
        Section: current => <div>
          <span className={css(Styles.spanValue)}>{current.title || '(no nickname)'}</span>
          <br/>
          <span className={css(Styles.spanValue, Styles.line2)}>{current._widgetName}</span>
        </div>,
        Status: current => UiHelper.itemStatusContainer(current),
      }
    }

    this.pageTitle = this.campaign.title
    this.headerActions = [
      {
        label: 'Preview',
        tooltip: 'Preview',
        icon: 'desktop_windows',
        type: 'icon-label',
        onClick: () => window.open('/admin/orgs/' + this.orgId + '/preview?templateId=' + this.emailTemplate.id, 'slixtaPreivew'),
      },
      {
        label: 'Refresh',
        tooltip: 'Refresh',
        icon: 'refresh',
        type: 'icon-label',
        onClick: () => this.fetchItems(),
      }
    ]
    if (['DRAFT', 'PAUSED'].includes(this.campaign.state)) {
      this.headerActions.unshift({
        label: this.campaign.state === 'PAUSED' ? 'Resume' : 'Start',
        tooltip: this.campaign.state === 'PAUSED' ? 'Resume' : 'Start',
        icon: 'send',
        onClick: () => this.updateCampaignState(this.campaign, this.campaign.state === 'PAUSED' ? 'resume' : 'start')
      })
    }
    if (['LIVE'].includes(this.campaign.state)) {
      this.headerActions.unshift({
        label: 'Pause',
        tooltip: 'Pause',
        icon: 'pause',
        onClick: () => this.updateCampaignState(this.campaign, 'pause')
      })
    }
    /*this.setState(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState.headerActions = this.headerActions
      return newState
    })*/

    /*this.setState({
      items: ,//this.campaigns.items.sort((a, b) => b.createdTs - a.createdTs)
    })*/
    // this.setState({
    //   item: this.campaign,
    // })
  }

  renderAboveTable() {
    let tiles = []
    if (this.campaignType === 'BROADCAST' && this.campaign.state !== 'DRAFT') {
      const r = this.campaign._extCampaignPerformance ? this.campaign._extCampaignPerformance.kpis : null
      if (r) {
        tiles = [
          { title: 'Delivery', value: Math.round(r['delivery-rate'].percent) + '%' },
          { title: 'Bounce', value: Math.round(r['hard-bounce-rate'].percent) + '%' },
          { title: 'Opens', value: Math.round(r['open-rate'].percent) + '%' },
          { title: 'Clicks', value: Math.round(r.clicks.count) },
          { title: 'Attempted', value: Math.round(r['attempted-deliveries'].count) },
          { title: 'Successful', value: Math.round(r['successful-deliveries'].count) },
          { title: 'Recipients', value: Math.round(r['unique-deliveries'].count) },
          { title: 'Opens', value: Math.round(r['email-opens'].count) },
        ]
      }
    } else if (this.campaignType === 'AD') {
      tiles = [
        { title: 'Screens', value: '1,241' },
        { title: 'Impressions', value: '1,304,432' },
      ]
    }
    return <div className={css(Styles.containerNarrow)}>
      {tiles.length > 0 && <Grid container spacing={2} className={css(CommonStyles.statContainer)}>
        {tiles.map((tile, index) => (
          <StatCard
            // sm={5}
            key={tile.title}
            text={tile.value}
            subText={tile.title.toLocaleUpperCase()}
            cardIndex={index}
          />
        ))}
      </Grid>}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <SubEntityCard
            renderFieldsRowwise={true}
            title={'Campaign Detail'} formDefinition={this.fieldDefCampaignBroadcast}
            item={this.campaign || {}}
            openModalForEdit={(current) => this.openModalForEditCampaign(current)}
          />
        </Grid>
        {this.emailTemplate && <Grid item xs={12} sm={6}>
          <SubEntityCard
            renderFieldsRowwise={true}
            title={'Email Metadata'} formDefinition={this.emailTemplateFieldDef}
            item={this.emailTemplate || {}}
            openModalForEdit={(current) => this.openModalForEditEmailPage(current)}
          />
        </Grid>}
      </Grid>
      {this.emailTemplate && <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <SubEntityList
            title={'Email Content'}
            tableHeadersMap={this.emailTemplateHeaders}
            items={Helper.sortDictArray(Helper.dictToArray(this.emailTemplate.blocks))}
            subEntityActions={this.emailTemplateSubEntityActions.bind(this)}
            noAdd={this.campaign.state !== 'DRAFT'}
            currentOrderChangeIndex={this.state.currentOrderChangeIndex}
            orderChangeEvent={this.state.orderChangeEvent}
            fetchState={this.state.fetchState}
            isOrderChanged={this.state.isOrderChanged}
            orderUp={this.orderUp.bind(this)}
            orderDown={this.orderDown.bind(this)}
            orderUIMode={this.state.orderUIMode}
            // openModalForEdit={(current) => this.openModalForTemplateEdit(current)}
            // openModalForDelete={(current) => this.openModalForTemplateDelete(current)}
            openModalForAdd={() => this.setState({ blockSelectionModal: true})}
            openPreview={() => window.open('/admin/orgs/' + this.orgId + '/preview?templateId=' + this.emailTemplate.id, 'slixtaPreivew')}
          />
        </Grid>
      </Grid>}
    </div>
  }

  onOrderEdit(orderEditData) {
    const { current, otherItem } = orderEditData
    const apiPayload = {
      entityAction: 'edit',
      entityAttrName: this.entityAttrName,
      id: current.id,
    }

    const orderChangePayload = {
      current,
      otherItem,
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.emailTemplate.id], {}),
      apiPayload
    }
    this.onOrderChange(orderChangePayload)
  }


  emailTemplateSubEntityActions = (current) => {
    function patchFields(fieldDef) {
      if (this.campaign.state !== 'DRAFT') {
        fieldDef.disableCTAButton = true
        fieldDef.steps[0].fields.forEach(f => f.disabled = true)
      }
    }

    const isDisabled = (this.campaign.state !== 'DRAFT') ? true : false

    const actions = []
    actions.push(<Tooltip title={'Edit section'} placement='top-start' key={current.id + 'editTT'}>
      <span className={css(AppStyles.entityTableLinkIcon)}>
        <i className='material-icons-outlined' onClick={() => this.openModalForEditTemplateWidget(current)}>edit</i>
      </span>
    </Tooltip>)
    if (this.campaign.state === 'DRAFT') actions.push(<Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
      <span className={css(AppStyles.entityTableLinkIcon)}>
        <i className='material-icons-outlined' onClick={() => this.openModalForDeleteTemplateWidget(current)}>delete</i>
      </span>
    </Tooltip>)
    if (current.cbSource === 'GLOBAL' || current.cbSource === 'TENANT') {
      if(current._singleton  === 'YES') {
        // Dont go to another page for convenience of only one item to be edited
        actions.push(
          <Tooltip title={Helper.getString('emailcbdats')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'
                onClick={() => AppUiHelper.handleEmailSingletonWidgetData(this, current, null, patchFields.bind(this), isDisabled)}>dataset</i>
            </span>
          </Tooltip>
        )
        if (current._cbstyle === 'ITEMBLOCK1' && this.campaign.state === 'DRAFT') {
          actions.push(
            <Tooltip title={Helper.getString('quickfill')} placement='top-start'>
              <span className={css(AppStyles.entityTableLinkIcon)}>
                <i className='material-icons-outlined'
                  onClick={() => this.openModalForScrapeBlog(current)}>dynamic_form</i>
              </span>
            </Tooltip>
          )
        }
      }
    }
    return actions
  }

  openModalForEditCampaign(current) {
    const fields = getCampaignFields(this)
    fields.find(f => f.key === 'title')
    const disableAlwaysFormFields = current.state === 'DRAFT' ? [] : ['channel', 'templateId', 'transactionalEvent', 'notificationEvent', 'segmentId']
    this.formDefinition = {steps: [{ fields }], disableAlwaysFormFields}
    this.formDefinition.formType = 'edit'
    this.formDefinition.title = 'Campaign Detail'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'PUT',
      apiData: {orgId: this.orgId},
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', current.id], queryParams: {} }),
    });
    this.setState({
      modalOpen: true
    });
  }

  openModalForEditEmailPage(current) {
    this.formDefinition = JSON.parse(JSON.stringify(this.emailTemplateFieldDef))
    this.formDefinition.disableAlwaysFormFields = this.campaign.state === 'DRAFT' ? [] : ['subject', 'previewText', 'titleText']
    this.formDefinition.formType = 'edit'
    this.formDefinition.title = 'Email Metadata'
    if (this.campaign.state !== 'DRAFT') {
      this.formDefinition.disableCTAButton = true
    }
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'PUT',
      apiData: {orgId: this.orgId},
      apiEndPoint: ApiHelper.makeUrlPath2({ paths: ['emailpages', current.id], queryParams: {} }),
    });
    this.setState({
      modalOpen: true
    });
  }

  openModalForAddTemplateWidget(fieldDef) {
    this.formDefinition = JSON.parse(JSON.stringify(fieldDef))
    this.formDefinition.formType = 'add'
    Helper.deleteFormDefinitionValues(this.formDefinition)
    this.formDefinition.disableAlwaysFormFields = ['cbSource', 'cbTenantId', 'cbGlobalId', 'cbSiteBlockId']
    if (this.state.selectedBlockSource === 'GLOBAL') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').value = this.state.selectedBlockType
    // } else if (this.state.selectedBlockSource === 'TENANT') {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').value = this.state.selectedBlockType
    // } else if (this.state.selectedBlockSource === 'SITE') {
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
    //   this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').value = this.state.selectedBlockType
    }

    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').value = this.state.selectedBlockSource
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').defaultValue = this.state.selectedBlockSource

    if(this.emailTemplate && this.emailTemplate.blocks) {
      const emailTemplateBlocks = Object.values(this.emailTemplate.blocks)
      const newOrder = AppHelper.generateNewOrder(emailTemplateBlocks)
      this.formDefinition.steps[0].fields.find(i => i.key === 'order').defaultValue = newOrder
    }

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: 'blocks', orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.emailTemplate.id], {}),
      modalOpen: true,
    });
  }

  openModalForEditTemplateWidget(current) {
    this.formDefinition = JSON.parse(JSON.stringify(this.emailTemplate.fieldDefPageBlock))
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
    if (this.campaign.state !== 'DRAFT') this.formDefinition.steps[0].fields.forEach(f => f.disabled = true)
    // this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    // this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
    if (this.campaign.state !== 'DRAFT') {
      this.formDefinition.disableCTAButton = true
    }
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: 'blocks', id: current.id, orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.emailTemplate.id], {}),
      modalOpen: true,
    });
  }

  openModalForDeleteTemplateWidget(current) {
    this.formDefinition = JSON.parse(JSON.stringify(this.emailTemplate.fieldDefPageBlock))
    this.formDefinition.formType = 'delete'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: 'blocks', id: current.id, orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath(['emailpages', this.emailTemplate.id], {}),
      modalOpen: true,
    });
  }

  openModalForCampaignUpdateAction(current, action, fieldDef) {
    this.formDefinition = fieldDef
    this.formDefinition.formType = 'edit'
    this.formDefinition.title = 'Edit Campaign State'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.setState({
      apiMethod: 'PUT',
      apiData: { updateAction: action, orgId: this.orgId },
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', current.id], queryParams: {} }),
      modalOpen: true
    });
  }

  async updateCampaignState(current, action) {
    if (['AD', 'BROADCAST'].includes(current.iType) && action === 'start') {
      this.fieldDefCampaignSchedule.ctaButtonText = 'Start'
      this.openModalForCampaignUpdateAction(current, action, this.fieldDefCampaignSchedule)
    } else {
      const fields = [{
        key: 'updateAction',
        label: 'Update action',
        type: 'edit_text',
        hint: 'Update action',
        // eslint-disable-next-line camelcase
        v_required: { value: true, err: 'Provide update action' },
        placement: 'left',
        value: action,
        disabled: true,
      }]
      current.updateAction = action
      this.openModalForCampaignUpdateAction(current, action, {steps: [{ fields }]});
    }
  }

  doActionNext = (selectedBlockSource, selectedBlockType) => {
    this.setState({
      blockSelectionModal: false,
      selectedBlockSource: selectedBlockSource,
      selectedBlockType: selectedBlockType
    }, () => this.openModalForAddTemplateWidget(this.emailTemplate.fieldDefPageBlock, this.cbGlobalById[selectedBlockType].cbstyle))
  }

  renderBelowTable() {
    return this.state.blockSelectionModal ? this.renderBlockSelectionModal() : ''
  }

  renderBlockSelectionModal() {
    return (
      <CbSelection
        title={Helper.getString('emailblockSelection')}
        isOpen={this.state.blockSelectionModal}
        onClosed={() => this.setState({ blockSelectionModal: false})}
        cbOrg={this.cbOrg}
        selectedBlock={'GLOBAL'}
        cbGlobal={this.cbGlobal}
        cbSource={this.cbSource}
        cbSite={this.cbSite}
        doActionNext={this.doActionNext}
        page={this.emailTemplate}
        compactView={true}
      />
    );
  }

  openModalForScrapeBlog(current) {
    this.formDefinition = {
      steps: [
        {
          fields: [
            {
              key: 'contentUrl',
              type: 'edit_text',
              label: 'Content Url [Optional]',
              v_required: { value: false }, // eslint-disable-line camelcase
              placement: 'left'
            },
          ]
        }
      ],
      formType: 'add',
      title: `Quick Fill ${(current && (current.title || current._widgetName)) || 'Widget'}`,
      ctaButtonText: 'Next',
    }
    let _linkPreview, _signedUploadInfo
    this.customSubmit = (options) => {
      this.customSubmit = null
      //const fieldDef = JSON.parse(JSON.stringify(this.emailTemplate.fieldDefPageBlock))
      const { formData } = options
      const _this = this
      //const _openModalForAdd = this.openModalForAddTemplateWidget.bind(this)
      function enhanceFields(fieldDef) {
        if (_linkPreview) {
          const titleField = fieldDef.steps[0].fields.find(f => f.key === 'title')
          titleField.defaultValue = _linkPreview.title || ''
          titleField.value = titleField.defaultValue
          const descriptionField = fieldDef.steps[0].fields.find(f => f.key === 'description')
          descriptionField.defaultValue = _linkPreview.description || ''
          descriptionField.value = descriptionField.defaultValue
        }
        const navLinkField = fieldDef.steps[0].fields.find(f => f.key === 'navLink')
        navLinkField.defaultValue = formData.contentUrl || ''
        navLinkField.value = navLinkField.defaultValue
        if (_signedUploadInfo) {
          const imageField = fieldDef.steps[0].fields.find(f => f.key === 'image')
          imageField.link = _signedUploadInfo.link
          imageField.value = _signedUploadInfo.fields.key
        }
      }
      function loadForm() {
        _this.setState({ fetchState: ApiHelper.State.READY, modalOpen: false }, () => {
          //_openModalForAdd(fieldDef)//, false)
          AppUiHelper.handleEmailSingletonWidgetData(_this, current, enhanceFields.bind(_this))
        })
      }
      if (formData.contentUrl) {
        ApiHelper.callAwait({
          ms: AppConfig.CDP_MS.ENDPOINT,
          method: 'get',
          paths: ['miscs', 'get-link-preview'],
          queryParams: {link: formData.contentUrl}}
        ).then(linkPreview => {
          _linkPreview = linkPreview
          if (linkPreview.imageData) {
            const imageBlob = Helper.b64toBlob(linkPreview.imageData.base64, linkPreview.imageData.type);
            const options = {
              uploadLocation: this.orgId,
              type: 'media',
              //contentType: linkPreview.imageData.contentType,
              ext:  linkPreview.imageData.extension,
            }
            return UploadHelper.fetchUploadUrlAwait(null, options).then(signedUploadInfo => {
              _signedUploadInfo = signedUploadInfo
              const file = new File([imageBlob], 'untitled', { type: linkPreview.imageData.contentType })
              return UploadHelper.uploadFormDataAwait({
                signedUploadInfo,
                pickerResult: file,
              })
            })
          }
        }).then(() => loadForm()).catch(err => {
          console.log(err)
          loadForm()
        })
      } else {
        loadForm()
      }
    }
    this.setState({
      modalOpen: true
    });
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'gray'
  },
  line3: {
    fontSize: 12,
    color: '#AAAAAA'
  },
  spanValue: {
    fontSize: 12
  },
  containerNarrow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: AppTheme.viewPort,
    paddingBottom: 50,
    width: '100%',
    '@media (max-width: 767px)': {//TODO: next?
      //width: window.innerWidth - 20 //TODO: fix it, in product screen whitespace nowrap creating issue
    },
  },
})