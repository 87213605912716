import React, { Component, Fragment } from 'react';
import { ApiHelper } from '../helpers/ApiHelper';
import { Helper } from '../helpers/Helper';
import { StyleSheet, css } from 'aphrodite';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ImageUploader } from './ImageUploader';
import { DocumentUploader } from './DocumentUploader';
import { RichtextEditor } from './RichtextComponent/RichtextEditor';
import { AppTheme } from '../../styles/AppTheme';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import { AppConfig } from '../../AppConfig';
import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import { DateTimePicker } from 'material-ui-pickers'
import { CustomDynamicFormStyles } from '../../styles/CustomStyles';
import { deleteFormField } from '../data/data';
import { AppButton } from './AppButton';
import { InputAdornment, Tooltip } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { ColourPicker } from './ColourPicker';
import { Controller } from '../Controller';
import { SubEntityCard } from './SubEntity/SubEntityCard';

export class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.submitOnlyChanges = false
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.doAction = this.doAction.bind(this);
    this.isColourPickerPresent = false
    this.state = {
      success: true,
      message: '',
      inProcess: false,
      formFieldUpdateProgress: null,
      colorPickerLoading:true,
      filterOperatorOpenModalKey: null,
      brandColors:[],
      selectedColor:{
        color:'',
        title:''
      },
      isAdvancedOpen: false,
    };
    this.locations = {}
    this.setFormState();
    this.currOrgId = Controller.get().roleHelper().getOrgId()
    this.groupId = 'settings' + this.currOrgId
  }

  setFormState() {
    this.fields = this.props.formDefinition.steps[0].fields;
    for (let i = 0; i < this.fields.length; i++) {
      const current = this.fields[i];
      if (current.type === 'radio') {
        current.multiSelect = false
        if (current.value && Array.isArray(current.value) && current.value.length > 0) {
          // incase the cloud stores a radio as singleton array
          current.value = current.value[0]
        }
      } else if (current.type === 'check_box') {
        current.multiSelect = true
      }
      if ((this.props.formDefinition.formType === 'add')  && current.defaultValue) {
        current.value = current.defaultValue
      }
      if (!current.value) {
        if (current.multiSelect) {
          current.value = [];
        } else {
          current.value = '';
        }
      }
      if (!this.state[current.key]) {
        this.state[current.key] = current.value;
        if (current.valueDisplayScale && this.state[current.key]) {
          this.state[current.key] = (this.state[current.key] / current.valueDisplayScale).toFixed(2)
        }
      }
      this.fields[i].valid = true;
      // if (current.imageUpload || current.documentUpload) { //TODO: why whas this introduced? commenting now since we are honouring 'disabled' attributes now
      //   current.disabled = true
      // }
    }
    this.previousState = this.state;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const id = target.id;
    this.fields.find(o => o.key === id).valid = true;
    this.setState({
      [id]: value
    });
  }

  handleDeleteFieldChange = (event) => {
    const target = event.target;
    const value = target.value;
    const id = target.id;
    this.setState({
      [id]: value
    });
  }

  handleRichtextInputChange = (value, id) => {
    this.setState({
      [id]: value
    });
  }

  handleSelectChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.fields.find(o => o.key === name).valid = true;
    this.setState({
      [name]: value
    });
  }

  handleUploadSuccess(fieldKey, signedUploadInfo) {
    const field = this.fields.find(o => o.key === fieldKey);
    if (field) {
      field.valid = true
      field.link = signedUploadInfo.link
      field._uploadedImgdimensions = {
        height: signedUploadInfo.height,
        width: signedUploadInfo.width
      }
      this.setState({
        [fieldKey]: signedUploadInfo.fields.key
      })
    }
  }

  handleColorChange = (color, id) => {
    this.setState({ [id]: color })
    this.setState({selectedColor:{color,title:color}})
  }

  checkValidityIfDeleteMode = () => {
    if (this.props.formDefinition.formType === 'del' || this.props.formDefinition.formType === 'delete') {
      if (this.state.delete !== 'delete') {
        return true
      }
    }
    return false
  }

  doAction() {
    this.setState({
      inProcess: true, // Nothing should change while sumitting
      message: ''
    });

    const apiData = this.processValuesBeforeAction()
    if (!apiData) return
    this.submit(apiData)

    if(this.isColourPickerPresent) {
      this.updateColors()
    }
  }

  updateColors() {
    const colors = [...this.state.brandColors]
    const currIdx =  colors.findIndex(col=>col.code.toLowerCase() === this.state.selectedColor.color.toLowerCase())

    if(currIdx === -1 ) {
      colors.push({code:this.state.selectedColor.color, title:this.state.selectedColor.color})
    }

    if(colors.length > 25) {
      colors.splice(6,1) //NOTE: The first 6 are default colors coming from backend, so not deleting the same.
    }

    ApiHelper.call({
      method: 'PUT',
      paths:['items',this.groupId + '-all'],
      queryParams: {
        groupId:this.groupId
      },
      jsonBody:{colourhistory:colors}
    },function(err,data) {
      console.log(err,'COLOR_UPDATE_ERROR');
      console.log(data,'COLOR_UPDATE_DATA');
    })

  }

  isConditionMatch(current) {
    if (current.condition) {
      const statements = Array.isArray(current.condition.and) ? current.condition.and : [current.condition]
      for (let i = 0; i < statements.length; ++i) {
        const condition = statements[i]
        const conditionState = this.state[condition.key]
        const conditionValues = (condition.values || [condition.value])
        const isConditionStateInValues = Array.isArray(conditionState) ?
          conditionState.some(cs => Helper.inArray(conditionValues, cs)) :
          Helper.inArray(conditionValues, conditionState)
        const conditionOp = (condition.op || 'is')
        if (conditionState === undefined ||
          (
            (conditionOp === 'is' && !isConditionStateInValues) ||
            (conditionOp === 'not' && isConditionStateInValues) ||
            (conditionOp === 'notnull' && !(Array.isArray(conditionState) ? conditionState.length > 0 : conditionState))
          )
        ) {
          return false
        }
      }
    }
    return true
  }

  isValid(current) {
    let valid = true;
    current.valid = true;
    if (current.v_required.value) {
      if (!this.isConditionMatch(current)) {
        // If condition doesnt match we can ignore this field.
        current.valid = true;
        // continue;
      } else if (current.multiSelect) {
        if (!this.state[current.key].length) {
          valid = false;
          current.valid = false;
        }
      } else {
        if (this.state[current.key] === '') {
          valid = false;
          current.valid = false;
        }
      }
    }
    return valid
  }

  processValuesBeforeAction() {
    const apiData = Object.assign({}, this.props.apiData)
    let hasChange = false;
    let valid = true;
    delete apiData.REMOVE_ATTRS
    const REMOVE_ATTRS = []
    for (const current of this.fields) {
      if(this.props.formDefinition.formType !== 'info') {
        this.isValid(current)
        if (!current.valid) {
          valid = false
          if(current.advanced) {
            this.setState({isAdvancedOpen : true})
          }
        }
      }
      apiData[current.key] = this.state[current.key];
      if (current.valueDisplayScale && apiData[current.key]) {
        apiData[current.key] =
          Math.round(apiData[current.key] * current.valueDisplayScale)
      }
      if (this.submitOnlyChanges) {
        if (this.state[current.key] !== this.previousState[current.key]) {
          hasChange = true;
        } else {
          delete apiData[current.key]
        }
      }
      const value = this.state[current.key];
      if (!current.v_required.value) {
        if (value !== this.previousState[current.key]) {
          if (value === '' || value === null || value === undefined) {
            REMOVE_ATTRS.push(current.key)
            delete apiData[current.key]
          }
        }
      }
    }
    if (REMOVE_ATTRS.length) {
      apiData.REMOVE_ATTRS = REMOVE_ATTRS
    }

    if (!valid) {
      this.setState({
        success: false,
        inProcess: false,
      });
      return null;
    }

    if (this.submitOnlyChanges && !hasChange && this.props.formDefinition.formType === 'edit') {
      this.setState({
        success: false,
        message: 'No changes',
        inProcess: false
      });
      return null;
    }

    if (this.checkValidityIfDeleteMode()) {
      this.setState({
        success: false,
        inProcess: false,
        message: Helper.getString('confirmDeleteMsg')
      });
      return null;
    }

    if (this.locations) {
      for (const key in this.locations) {
        apiData[key] = this.locations[key]
      }
    }
    return apiData;
  }

  submit(apiData, ignoreCustomSubmit = false) {
    if((this.props.formDefinition.formType === 'filter') && this.props.onFilterSubmit) {
      this.props.onFilterSubmit({
        formType: this.props.formDefinition.formType,
        formData: apiData,
        filterOptions: JSON.parse(JSON.stringify(this.fields)),
        caller: this
      }, () => {
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      return
    }
    if (this.props.customSubmit && !ignoreCustomSubmit) {
      this.props.customSubmit({
        formType: this.props.formDefinition.formType,
        formData: apiData,
        caller: this
      }, () => {
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      })
      return
    }
    ApiHelper.call(
      { method: this.props.apiMethod, endPoint: this.props.apiEndPoint, jsonBody: apiData },
      function (err, data) {
        if (err) {
          const errorMessage = Helper.getErrorMsg(err);
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: errorMessage,
            success: false,
            message: errorMessage,
            inProcess: false
          })
          return;
        }
        this.previousState = this.state;
        if (Helper.inArray(['delete', 'del'], this.props.formDefinition.formType)) {
          this.setState({
            success: true,
            message: '',
            inProcess: false
          })
          // if (this.props.onClosed) {
          //   this.props.onClosed();
          // }
          // if (this.props.onCompleteAction) {
          //   this.props.onCompleteAction()
          // }
        } else {
          this.setState({
            success: true,
            message: Helper.getString('successful'),
            inProcess: false
          })
        }
        if (this.props.onCompleteAction) {
          this.props.onCompleteAction({
            apiInvokeData: {
              method: this.props.apiMethod,
              endPoint: this.props.apiEndPoint,
              reqBody: apiData,
              responseBody: data
            }
          })
        }
      }.bind(this));
  }

  setFormControlParams(current, fromControlParams) {
    if (current.v_required.value) {
      fromControlParams.required = true;
    }
    if (!current.valid) {
      fromControlParams.error = true;
    }
  }

  handleFilterOperatorChange = (event, current) => {
    const newValue = event.target.value;
    const existingFields = JSON.parse(JSON.stringify(this.fields));
    const updatedFields = existingFields.map(field => {
      if (field.key === current.key) {
        field.selectedOperator = newValue
      }
      return field;
    });

    this.fields = updatedFields;
    this.forceUpdate()
  }


  _preFillTip(current) {
    if(current.type !== 'rich_text') return
    if(current.subType === 'h1') {
      current.tip = Helper.getString('h1Tip')
    }
    if(current.subType === 'h2') {
      current.tip = Helper.getString('h2Tip')
    }
  }

  renderLabel(current, options) {
    this._preFillTip(current)
    return (
      <div className={css(Styles.labelContainer)}>
        <div className={css(Styles.labelInnerContainer)}>
          {/* <p className={css(Styles.label)}>{current.label}{options && options.required && <span className={css(Styles.requiredIndicator)}>*</span>}</p> */}
          <p className={css(Styles.label)}>{current.label}</p>
          {options && options.required && <img className={css(Styles.requiredIndicator)} height='7' width='7' src={require('../../assets/images/emergency.svg')} />}
          {current.tip &&
          <Tooltip title={current.tip} classes={{ popper: css(Styles.tooltip) }}>
            <i className='material-icons-outlined' style={{ fontSize: 14, color: '#7d7d7d', cursor: 'pointer', marginLeft: 5, marginTop: 0, alignSelf: 'center' }}>info</i>
          </Tooltip>
          }

          {(this.props.formDefinition.formType === 'filter') && current.operators && (<Select
            value={current.selectedOperator || current.defaultOperator}
            open={this.state.filterOperatorOpenModalKey === current.key}
            onOpen={() => this.setState({ filterOperatorOpenModalKey: current.key })}
            onClose={()=> this.setState({ filterOperatorOpenModalKey: null })}
            onChange={(event)=>{
              this.handleFilterOperatorChange(event,current)
              this.setState({ filterOperatorOpenModalKey: null })
            }}
            disableUnderline
            MenuProps={{
              disablePortal: true,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              classes: { paper: css(Styles.isEqualSelectMenu) },
            }}
            classes={{ select: css(Styles.isEqualSelection) }}
            className={css(Styles.isEqualSelect)}
          >
            {current.operators.map((option) => (
              <MenuItem key={option.key.toString()} value={option.label.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>)}
        </div>
      </div>
    )
  }

  // Following function can be used to call submit function when user presses enter on form inputs
  // But we need to fix the behaviour in cases where another dialog comes over form dialog, hence commenting this code
  // keyActions(e) {
  //   if (!e) return
  //   const key = e.key
  //   const tagName = e.target.tagName

  //   if (key && key === 'Enter') {
  //     const ariaLabel = e.target.getAttribute('aria-label')
  //     if (tagName && (tagName === 'TEXTAREA' || (tagName === 'DIV' && ariaLabel === 'rdw-editor'))) return
  //     e.preventDefault()
  //     this.doAction()
  //   }
  //   if (key && key === 'Escape' && this.props.onClosed) this.props.onClosed()
  // }

  setStateKeyValue(options) {
    this.setState({ [options.key]: options.value });
  }

  render() {
    if(this.props.formDefinition.formType === 'info') {
      return(
        <div className={css(Styles.containerNarrow)}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <SubEntityCard
                renderFieldsRowwise={true}
                formDefinition={{steps: this.props.formDefinition.steps}}
                item={this.processValuesBeforeAction() || {}}
                noEdit={true}
              />
            </Grid>
          </Grid>
        </div>
      )
    }
    const leftFields = [];
    const rightFields = [];
    const advancedLeftFields = [];
    const advancedRightFields = [];
    this.generateFields(leftFields, rightFields, advancedLeftFields, advancedRightFields)
    return (
      <form key='form' autoComplete='off' className={css(Styles.form)}>
        {this.generateContent(leftFields, rightFields)}
        {(advancedLeftFields.length > 0 || advancedRightFields.length > 0) && this.generateAdvancedContent(advancedLeftFields, advancedRightFields)}
        {this.generateActions()}
      </form>
    );
  }

  generateDeleteField() {
    const formControlParams = {};
    const current = {
      key: 'delete',
      label: 'Type "delete" for deletion',
    }
    const fieldParams = {
      id: current.key,
      value: this.state[current.key],
      fullWidth: true
    };

    return (
      <div className={(css(Styles.delFieldContainer))} key={current.key}>
        <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
          {this.renderLabel(current, formControlParams)}
          <TextField
            error={formControlParams.error}
            required={formControlParams.required}
            autoComplete='off'
            className={css(Styles.formControl)}
            {...fieldParams}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: css(Styles.textInputStyles),
                input: css(Styles.formInputStyle),
              }
            }}
            onChange={this.handleDeleteFieldChange} />
        </FormControl>
      </div>
    )
  }

  generateFields(leftFields, rightFields, advancedLeftFields, advancedRightFields) {
    for (const current of this.fields) {
      const formControlParams = {};
      let field;
      let errorTag = '';

      this.setFormControlParams(current, formControlParams);
      if (!current.valid) {
        errorTag = <FormHelperText className={css(Styles.errorMsg)}>{current.v_required.err}</FormHelperText>;
      }
      if (!this.isConditionMatch(current)) {
        continue
      }
      switch (current.type) {
      case 'document':
      case 'edit_text':
        if(current.subType === 'image') {
          field = this.generateImageUploader(current, formControlParams, errorTag)
        } else if(current.subType === 'document') {
          field = this.generateDoucmentUploader(current, formControlParams, errorTag)
        } else if(current.subType === 'colour') {
          this.isColourPickerPresent = true
          field = this.generateColourPicker(current, formControlParams, errorTag)
        } else {
          field = this.generateEditText(current, formControlParams, errorTag)
        }
        break;
      case 'rich_text':
        field = this.generateRichtext(current, formControlParams, errorTag)
        break;
      case 'radio':
      case 'check_box':
      case 'select':
        field = this.generateAutoCompleteSelection(current, formControlParams, errorTag)
        break;
      case 'dateTime':
        field = this.generateDateTimePicker(current, formControlParams, errorTag)
        break
      case 'date':
        field = this.generateDatePicker(current, formControlParams, errorTag)
        break
      case 'year':
        field = this.generateYearPicker(current, formControlParams, errorTag)
        break
      case 'time':
        field = this.generateTimePicker(current, formControlParams, errorTag)
        break
      default:
        console.error(Helper.getString('unknownFieldType'), current.type)
        continue;
      }
      if (!current.placement) {
        current.placement = 'left' // default
      }
      if(current.advanced) {
        if (current.placement === 'left') {
          advancedLeftFields.push(field);
        } else if (current.placement === 'right') {
          advancedRightFields.push(field);
        }
      } else {
        if (current.placement === 'left') {
          leftFields.push(field);
        } else if (current.placement === 'right') {
          rightFields.push(field);
        }
      }
    }
  }

  generateContent(leftFields, rightFields) {
    if (leftFields.length !== 0 && rightFields.length === 0) {
      return (
        <Grid container spacing={2}>
          {leftFields.map(item => {
            return (
              <Grid item xs={12}>
                {item}
              </Grid>
            )
          })
          }
        </Grid>
      )
    } else if (rightFields.length !== 0 && leftFields.length === 0) {
      return (
        <Grid container spacing={2}>
          {rightFields.map(item => {
            return (
              <Grid item xs={12}>
                {item}
              </Grid>
            )
          })
          }
        </Grid>
      )
    }

    const combinedArray = []
    const lMin = Math.min(leftFields.length, rightFields.length);
    const lMax = Math.max(leftFields.length, rightFields.length);
    if (lMin === leftFields.length && lMin !== rightFields.length) {
      for (let i = 0; i < lMin - rightFields.length; i++) {
        leftFields.push('')
      }
    } else if (lMin === rightFields.length && lMin !== leftFields.length) {
      for (let i = 0; i < lMin - leftFields.length; i++) {
        rightFields.push('')
      }
    }
    for (let i = 0; i < lMax; i++) {
      combinedArray.push(leftFields[i], rightFields[i]);
    }

    // combinedArray.push(...leftFields.slice(l), ...rightFields.slice(l));

    return (
      <Grid container spacing={2}>
        {combinedArray.map(item => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {item}
            </Grid>
          )
        })
        }
      </Grid>
    )
  }

  generateAdvancedContent(advancedLeftFields, advancedRightFields) {
    return( <div><Accordion
      elevation={0}
      expanded={this.state.isAdvancedOpen}
      onChange={()=> this.setState((prev) => ({
        isAdvancedOpen: !prev.isAdvancedOpen,
      }))
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={css(Styles.accordionIcon)}/>}
        className={css(Styles.accordionSummary)}
      >
        <Typography className={css(Styles.advancedTitle)}>Advanced</Typography>
      </AccordionSummary>
      <AccordionDetails className={css(Styles.accordionDetails)}>
        {this.generateContent(advancedLeftFields, advancedRightFields)}
      </AccordionDetails>
    </Accordion></div>)
  }

  generateContentOld(leftFields, rightFields) {
    if (rightFields.length > 0) {
      return <div>
        <Grid container>
          <Grid className={css(Styles.leftGridPadding)} item xs={12} sm={6}>
            {leftFields}
          </Grid>
          <Grid className={css(Styles.rightGridPadding)} item xs={12} sm={6}>
            {rightFields}
          </Grid>
        </Grid>
      </div>
    } else {
      return <div>
        <Grid container>
          <Grid item xs={12}>
            {leftFields}
          </Grid>
        </Grid>
      </div>
    }
  }

  generateActions() {
    let buttonText = this.props.formDefinition.ctaButtonText || 'Delete'
    let buttonStyle = Styles.buttonDelete
    let buttonTextStyle = Styles.buttonText
    if (Helper.inArray(['add', 'edit', 'filter'], this.props.formDefinition.formType)) {
      buttonText = this.props.formDefinition.ctaButtonText || 'Save'
      buttonStyle = Styles.button
    } else if ('approve' === this.props.formDefinition.formType) {
      buttonText = 'Approve'
      buttonStyle = Styles.button
    }
    const isButtonDisabled = this.props.formDefinition.disableCTAButton || false

    if(isButtonDisabled) {
      buttonStyle = Styles.buttonDisabled
      buttonTextStyle = Styles.buttonDisabledText
    }

    const dialogAction = <div className={css(Styles.actions)}>
      <span className={this.state.success ? css(Styles.successMsg) : css(Styles.errorMsg)}>
        {this.state.message}
      </span>
      {(this.props.formDefinition.formType === 'del' || this.props.formDefinition.formType === 'delete') &&
        this.generateDeleteField()
      }
      <AppButton
        buttonStyle={buttonStyle}
        buttonTextStyle={buttonTextStyle}
        disabled={this.state.inProcess || isButtonDisabled}
        inProcess={this.state.inProcess}
        onClick={this.doAction}
        buttonText={buttonText}
      />
    </div>

    return dialogAction
  }

  componentDidMount() {
    for (const current of this.fields) {
      if (current.pickLoc) {
        this.initGooglePlaces(current)
      }
    }
    this.getColors()
  }

  getColors() {
    this.setState({colorPickerLoading:true})

    ApiHelper.call({
      authWithKey: true,
      method: 'GET',
      paths:['miscs','appconfig'],
      queryParams: {
        orgId:this.currOrgId
      }
    },function(err,data) {
      if(err) {
        console.log(err,'LOADING_ERR_COLORS');
        this.setState({
          colorPickerLoading:false,
        })
        return
      }
      this.setState({
        colorPickerLoading:false,
        brandColors: (data.__appConfig && data.__appConfig.colourhistory) || []
      })
    }.bind(this))
  }

  initGooglePlaces(current) {
    const inputNode = document.getElementById(current.key);
    const autoComplete = new window.google.maps.places.Autocomplete(inputNode);
    autoComplete.setFields(['address_component', 'adr_address', 'name', 'formatted_address', 'geometry'])
    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      this.locations[current.locName] = {
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      this.setState({
        [current.key]: place.formatted_address
      })
      // console.log('place:', place);
      //console.log(place.geometry.location.lat());
      //console.log(place.geometry.location.lng());
    });
  }

  generateRichtext(current, formControlParams, errorTag) {
    return (
      <div key={current.key}>
        {this.renderLabel(current, formControlParams)}
        <RichtextEditor
          current={current}
          fullEditor={current.fullEditor}
          colorPicker={current.colorPicker}
          variablePicker= {current.variablePicker}
          value={this.state[current.key]}
          disabled={this.shouldDisable(current)}
          stripPastedStyles={false}
          stripPastedImages={true}
          clearFormatting={true}
          handleRichtextInputChange={this.handleRichtextInputChange} />
        {errorTag}
      </div>
    )
  }

  renderImageUploader(current) {
    return (
      <Fragment>
        {this.props.customFormComponents && this.props.customFormComponents.imageUpload ?
          <this.props.customFormComponents.imageUpload.comp
            uploadId={current.key}
            url={current.link}
            onUploadSuccess={this.handleUploadSuccess.bind(this)}
            width={current.imageWidth}
            height={current.imageHeight}
            uploadType={current.uploadType}
            uploadAccept={current.uploadAccept}
            uploadLocation={this.props.uploadLocation}
            orgId={this.props.orgId}
            uploadExt={current.uploadExt}
            mode={current.mode}
            borderRadius={0}
            disabled={this.shouldDisable(current)}
            disableDeletion={current.disableDeletion}
            {...this.props.customFormComponents.imageUpload.props}
          />
          :
          <ImageUploader
            uploadId={current.key}
            url={current.link}
            onUploadSuccess={this.handleUploadSuccess.bind(this)}
            width={current.imageWidth}
            height={current.imageHeight}
            uploadType={current.uploadType}
            uploadAccept={current.uploadAccept}
            uploadLocation={this.props.uploadLocation}
            orgId={this.props.orgId}
            uploadExt={current.uploadExt}
            mode={current.mode}
            borderRadius={0}
            disableDeletion={current.disableDeletion}
            disabled={this.shouldDisable(current)}
            setStateKeyValueHandler={this.setStateKeyValue.bind(this)}
            autoFillTitleKey={current.autoFillTitleKey || ''}
          />
        }
      </Fragment>
    )
  }

  generateEditText(current, formControlParams, errorTag) {
    const fieldParams = {
      id: current.key,
      value: this.state[current.key],
      fullWidth: true
    };
    if (current.multiline) {
      fieldParams.multiline = current.multiline;
      fieldParams.rows = current.rows || 5;
    }
    fieldParams.disabled = this.shouldDisable(current)

    return <div key={current.key}>
      <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
        {this.renderLabel(current, formControlParams)}
        {(!current.imageUpload && !current.documentUpload && !current.colourPicker) && //retained for backward compatibility
          <TextField
            error={formControlParams.error}
            required={formControlParams.required}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !fieldParams.multiline) {
                event.preventDefault();
              }
            }}
            variant={'standard'}
            className={css(Styles.formControl)}
            InputProps={{
              disableUnderline: true,
              type: current.subType === 'password' ? 'password' : 'text',
              classes: {
                root: this.shouldDisable(current) ? css([Styles.textInputStyles, Styles.textInputStylesDisabled]) : css(Styles.textInputStyles),
                input: css(Styles.formInputStyle, Styles.textInput),
              }
            }}
            autoComplete='off'
            {...fieldParams}
            onChange={this.handleInputChange} />
        }
        {current.imageUpload && //retained for backward compatibility
          this.renderImageUploader(current)
        }
        {current.documentUpload && //retained for backward compatibility
          <DocumentUploader
            label={current.label}
            uploadId={current.key}
            link={current.link}
            uploadType={current.uploadType}
            uploadAccept={current.uploadAccept}
            uploadLocation={this.props.uploadLocation}
            uploadExt={current.uploadExt}
            onUploadSuccess={this.handleUploadSuccess.bind(this)}
            value={this.state[current.key]}
            disabled={this.shouldDisable(current)}
            fileSizeLimit={current.fileSizeLimit}
          />
        }
        {current.colourPicker && //retained for backward compatibility
          <ColourPicker
            defaultValue={current.defaultValue}
            colorPickerData={{isLoading:this.state.colorPickerLoading,data:this.state.brandColors}}
            id={current.key}
            value={this.state[current.key]}
            handleColorChange={this.handleColorChange} />
        }
        {errorTag}
      </FormControl>
      {this.generatePendingApproval(current)}
    </div>
  }

  generateImageUploader(current, formControlParams, errorTag) {
    const fieldParams = {
      id: current.key,
      value: this.state[current.key],
      fullWidth: true
    };
    if (current.multiline) {
      fieldParams.multiline = current.multiline;
      fieldParams.rows = current.rows || 5;
    }
    fieldParams.disabled = this.shouldDisable(current)

    return <div key={current.key}>
      <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
        {this.renderLabel(current, formControlParams)}
        {this.renderImageUploader(current)}
        {errorTag}
      </FormControl>
      {this.generatePendingApproval(current)}
    </div>
  }

  generateDoucmentUploader(current, formControlParams, errorTag) {
    const fieldParams = {
      id: current.key,
      value: this.state[current.key],
      fullWidth: true
    };
    if (current.multiline) {
      fieldParams.multiline = current.multiline;
      fieldParams.rows = current.rows || 5;
    }
    fieldParams.disabled = this.shouldDisable(current)

    return <div key={current.key}>
      <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
        {this.renderLabel(current, formControlParams)}
        <DocumentUploader
          label={current.label}
          uploadId={current.key}
          link={current.link}
          forceAllowFileOpen={current.forceAllowFileOpen}
          uploadType={current.uploadType}
          uploadAccept={current.uploadAccept}
          uploadLocation={this.props.uploadLocation}
          uploadExt={current.uploadExt}
          uploadMs={current.uploadMs || this.props.uploadMs}
          onUploadSuccess={this.handleUploadSuccess.bind(this)}
          value={this.state[current.key]}
          disabled={this.shouldDisable(current)}
        />
        {errorTag}
      </FormControl>
      {this.generatePendingApproval(current)}
    </div>
  }

  generateColourPicker(current, formControlParams, errorTag) {
    const fieldParams = {
      id: current.key,
      value: this.state[current.key],
      fullWidth: true
    };
    if (current.multiline) {
      fieldParams.multiline = current.multiline;
      fieldParams.rows = current.rows || 5;
    }
    fieldParams.disabled = this.shouldDisable(current)

    return <div key={current.key}>
      <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
        {this.renderLabel(current, formControlParams)}
        <ColourPicker
          defaultValue={current.defaultValue}
          colorPickerData={{isLoading:this.state.colorPickerLoading,data:this.state.brandColors}}
          id={current.key}
          value={this.state[current.key]}
          handleColorChange={this.handleColorChange} />
        {errorTag}
      </FormControl>
      {this.generatePendingApproval(current)}
    </div>
  }

  generateDateTimePicker(current, formControlParams, errorTag) {
    return (
      <Fragment key={current.key}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          {this.renderLabel(current, formControlParams)}
          <DateTimePicker
            keyboard
            variant={'standard'}
            keyboardIcon={<i className='material-icons-outlined' >calendar_today</i>}
            format='DD/MM/YYYY hh:mm A'
            placeholder='DD/MM/YYYY hh:mm A'
            value={this.state[current.key] || null}
            onChange={this.handleDateChange(current)}
            animateYearScrolling={false}
            clearable={current.clearable}
            margin='normal'
            fullWidth
            disabled={this.shouldDisable(current)}
            className={css(Styles.pickerStyle)}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: this.shouldDisable(current) ? css([Styles.pickerInputStyles, Styles.textInputStylesDisabled]) : css(Styles.pickerInputStyles),
                input: css(Styles.formInputStyle),
              }
            }}
            DialogProps={{
              className: css(Styles.dialog),
              PaperProps: {
                className: css(Styles.paper)
              }
            }}
            {...formControlParams}
          />
        </MuiPickersUtilsProvider>
        {errorTag}
      </Fragment>
    )
  }

  generateDatePicker(current, formControlParams, errorTag) {
    return (
      <Fragment key={current.key}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} >
          {this.renderLabel(current, formControlParams)}
          <DatePicker
            variant={'standard'}
            keyboard
            keyboardIcon={<i className='material-icons-outlined' >calendar_today</i>}
            format='DD-MMM-YYYY'
            placeholder='DD-MMM-YYYY'
            value={this.state[current.key] || null}
            onChange={this.handleDateChange(current)}
            animateYearScrolling={false}
            clearable={current.clearable}
            margin='normal'
            fullWidth
            disabled={this.shouldDisable(current)}
            className={css(Styles.pickerStyle)}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: this.shouldDisable(current) ? css([Styles.pickerInputStyles, Styles.textInputStylesDisabled]) : css(Styles.pickerInputStyles),
                input: css(Styles.formInputStyle),
              }
            }}
            DialogProps={{
              className: css(Styles.dialog),
              PaperProps: {
                className: css(Styles.paper)
              }
            }}
            {...formControlParams}
          />
        </MuiPickersUtilsProvider>
        {errorTag}
      </Fragment>
    )
  }

  generateYearPicker(current, formControlParams, errorTag) {
    return (
      <Fragment key={current.key}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          {this.renderLabel(current, formControlParams)}
          <DatePicker
            variant={'standard'}
            views={['year']}
            keyboard
            keyboardIcon={<i className='material-icons-outlined' >calendar_today</i>}
            format='YYYY'
            placeholder='YYYY'
            value={this.state[current.key] || null}
            onChange={this.handleDateChange(current)}
            animateYearScrolling={false}
            clearable={current.clearable}
            margin='normal'
            fullWidth
            disabled={this.shouldDisable(current)}
            className={css(Styles.pickerStyle)}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: this.shouldDisable(current) ? css([Styles.pickerInputStyles, Styles.textInputStylesDisabled]) : css(Styles.pickerInputStyles),
                input: css(Styles.formInputStyle),
              }
            }}
            DialogProps={{
              className: css(Styles.dialog),
              PaperProps: {
                className: css(Styles.paper)
              }
            }}
            {...formControlParams}
          />
        </MuiPickersUtilsProvider>
        {errorTag}
      </Fragment>
    )
  }

  generateTimePicker(current, formControlParams, errorTag) {
    return (
      <Fragment key={current.key}>
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
          {this.renderLabel(current, formControlParams)}
          <TimePicker
            keyboard
            variant={'standard'}
            keyboardIcon={<i className='material-icons-outlined' >schedule</i>}
            placeholder='hh:mm A'
            value={this.state[current.key] || null}
            onChange={this.handleDateChange(current)}
            disableOpenOnEnter
            clearable={current.clearable}
            margin='normal'
            fullWidth
            disabled={this.shouldDisable(current)}
            className={css(Styles.pickerStyle)}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: this.shouldDisable(current) ? css([Styles.pickerInputStyles, Styles.textInputStylesDisabled]) : css(Styles.pickerInputStyles),
                input: css(Styles.formInputStyle),
              }
            }}
            DialogProps={{
              className: css(Styles.dialog),
              PaperProps: {
                className: css(Styles.paper)
              }
            }}
            {...formControlParams}
          />
        </MuiPickersUtilsProvider>
        {errorTag}
      </Fragment>
    )
  }

  handleDateChange = current => date => {
    current.valid = true;
    if (!date) {
      return this.setState({
        [current.key]: null
      });
    }
    this.setState({
      [current.key]: date.utc().valueOf()
    });
  }

  generateSelection(current, formControlParams, errorTag) {
    const options = [];
    for (const option of current.options) {
      let cbOption = '';
      if (current.multiSelect) {
        cbOption = <Checkbox color='primary' checked={this.state[current.key].indexOf(option.key) > -1} />
      }
      options.push(
        <MenuItem key={option.key} value={option.key}>
          {cbOption}
          {option.text || option.label}
        </MenuItem>
      );
    }
    let renderParams = {};
    if (current.multiSelect) {
      const searchOptions = current.options;
      renderParams = {
        renderValue: selected => (
          <div className={css(Styles.chips)}>
            {selected.map(value => { return this.findValueInList(value, searchOptions) })}
          </div>
        )
      }
    }
    return <FormControl className={css(Styles.formControlMain)} key={current.key} fullWidth {...formControlParams} variant='outlined'>
      <InputLabel htmlFor={current.label}>{current.label}</InputLabel>
      <Select
        multiple={current.multiSelect}
        value={this.state[current.key] ? this.state[current.key] : current.value}
        onChange={this.handleSelectChange}
        {...renderParams}
        input={<OutlinedInput name={current.key} id={current.key} disabled={this.shouldDisable()} label={current.label} />}
      >
        {options}
      </Select>
      {errorTag}
      {this.generatePendingApproval(current)}
    </FormControl>
  }

  findValueInList(value, searchOptions) {
    const found = searchOptions.find(o => o.key === value)
    if (found) {
      return (<Chip key={value} label={found.text || found.label} />)
    }
  }

  generateAutoCompleteSelection(current, formControlParams, errorTag) {
    const value = this.processValueToPreFill(current)
    const filter = createFilterOptions();
    const fieldParams = {
      fullWidth: true,
      multiple: current.multiSelect,
      options: current.options,
      value: value
    };
    fieldParams.disabled = this.shouldDisable(current)

    // Enable adding option dynamically
    if (current.addOptions === true) {
      fieldParams.closeIcon = null
      fieldParams.loading = this.state.formFieldUpdateProgress !== current.key
      fieldParams.loadingText = Helper.getString('optionListLoadingText')
      fieldParams.filterOptions = (options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === (option.text || option.label));
        if (inputValue !== '' && !isExisting) {
          filtered.unshift({
            value: inputValue,
            text: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }
    }
    return <form autoComplete='off'>
      <FormControl className={css(Styles.formControlMain)} fullWidth {...formControlParams} >
        {this.renderLabel(current, formControlParams)}
        <Autocomplete
          {...fieldParams}
          onChange={(e, val) => this.onAutoCompleteChange(val, current)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          getOptionLabel={(option) => option.label || option.text}
          ChipProps={{
            deleteIcon: <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
              <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
            </svg>
          }}
          renderOption={(option) => (
            <div className={(option && !option.key) ? css(Styles.addOptionStyle) : ''} >{option.text || option.label}</div>
          )}
          // renderOption={option => {
          //   return (
          //     <React.Fragment>
          //       {current.showThumbnail &&
          //         <React.Fragment>
          //           {option.img ?
          //             <div key={option.key} className={css(Styles.optionImageContainer, Styles.optionImageContainerSpec)}>
          //               <img width={20} height={20} src={option.img} className={css(Styles.optionImage)} />
          //             </div>
          //             :
          //             <div key={option.key} className={css(Styles.optionImageContainer, Styles.optionImageContainerSpec)}>
          //               <ImageIcon className={css(Styles.optionImage)} />
          //             </div>
          //           }
          //         </React.Fragment>
          //       }
          //       {option.label || option.text}
          //     </React.Fragment>
          //   )
          // }}
          classes={{
            tag: css(Styles.tag),
            popupIndicator: css(Styles.popupIndicator),
            popper: css(Styles.dialog)
          }}
          renderInput={params =>
            <TextField
              error={formControlParams.error}
              required={formControlParams.required}
              {...params}
              variant={'standard'}
              className={css(Styles.formControl)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: {
                  root: this.shouldDisable(current) ? css([Styles.textInputStyles, Styles.textInputStylesDisabled, Styles.autoCompleteInputRoot]) : css([Styles.textInputStyles, Styles.autoCompleteInputRoot]),
                  input: css(Styles.formInputStyle, Styles.textInput),
                },
                endAdornment: (
                  <React.Fragment>
                    {(this.state.formFieldUpdateProgress && this.state.formFieldUpdateProgress === current.key) ? <CircularProgress className={css(Styles.optionLoaderSpinner)} color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                // startAdornment: (
                //   current.showThumbnail &&
                //   <InputAdornment position='start' className={css(Styles.inputAdornment)}>
                //     {fieldParams.options.map((item, index) => {
                //       if (item.img && item.key === fieldParams.value.key) {
                //         return (
                //           <div key={item.key} className={css(Styles.optionImageContainer)}>
                //             <img width={20} height={20} src={item.img} className={css(Styles.optionImage)} />
                //           </div>
                //         )
                //       } else if (item.key === fieldParams.value.key) {
                //         return (
                //           <div key={item.key} className={css(Styles.optionImageContainer)}>
                //             <ImageIcon className={css(Styles.optionImage)} />
                //           </div>
                //         )
                //       }
                //     })}
                //     {params.InputProps.startAdornment}
                //   </InputAdornment>
                // )

              }} />}
        />
        {errorTag}
        {this.generatePendingApproval(current)}
      </FormControl>
    </form>
  }

  processValueToPreFill = (current) => {
    let value
    if (current.multiSelect) {

      if (!this.state[current.key] || !current.options) return []

      value = []
      for (const k of this.state[current.key]) {
        for (const o of current.options) {
          if (o.key === k) {
            value.push({
              key: o.key,
              text: o.label || o.text
            })
          }
        }
      }
    } else {

      if (!this.state[current.key] || !current.options) return ''

      for (const o of current.options) {
        if (o.key === this.state[current.key]) {
          value = {
            key: o.key,
            text: o.label || o.text
          }
        }
      }
    }
    return value
  }
  onAutoCompleteChange = async(value, current) => {
    // Calling the dynamic option add hook
    let errorMessage = ''
    const newlyAddedOption = current.multiSelect ? value.find(obj => !obj.key) : (value && !value.key) ? value : undefined
    if (newlyAddedOption && current.addOptions && this.props.onFormFieldOptionAdd) {
      current.disabled = true
      this.setState({formFieldUpdateProgress:current.key})
      // NOTE: We're assigning an '  ' value to the text box since there's no key present.
      // This ensures that autocomplete displays nothing until we replace the option after the API call.
      // This issue arises with single-select, as the default behavior selects the value when the key is an empty string ('').
      if (!current.multiSelect) this.setState({[current.key]:'   '})
      try {
        const updatedOptionsList = await this.props.onFormFieldOptionAdd({
          formField: current,
          value: newlyAddedOption.value,
          formId: this.props.formDefinition.formId
        })
        // console.log('updatedOptionsList', updatedOptionsList)
        // Removing temp added option and geting rest options
        const currentSelectedOptions = current.multiSelect ? value.filter(obj => obj.key) : {}
        // console.log('currentSelectedOptions', currentSelectedOptions)
        // Find out the new added option object
        let newOption = {}
        if (updatedOptionsList && updatedOptionsList.length > 0) {
          updatedOptionsList.forEach((x) => {
            if ((x.label || x.text || x.title) === newlyAddedOption.value) newOption = { key: x.key || x.id, text: (x.label || x.text || x.title) }
          })
        }
        // console.log('newOption', newOption)
        // Updating the existing selected options list with newly added obj
        value = current.multiSelect ? (Object.keys(newOption).length > 0 ? [...currentSelectedOptions, newOption] : [...currentSelectedOptions]) : newOption;
        // console.log('Final selected options', value)
        if (this.state.formFieldUpdateProgress !== null) this.setState({formFieldUpdateProgress:null})
        current.disabled = false
      } catch(err) {
        if(err) console.log(err)
        errorMessage = Helper.getErrorMsg(err)
        if (this.state.formFieldUpdateProgress !== null) this.setState({formFieldUpdateProgress:null})
        current.disabled = false
      }
    }
    this.fields.find(o => o.key === current.key).valid = true;
    if (!current.multiSelect) {
      this.setState({
        [current.key]: (value && value.key) || ''
      })
    } else if (value) {
      const valDictByKey = {}
      const vals = []
      for (const v of value) {
        if (!valDictByKey[v.key]) {
          valDictByKey[v.key] = true
          vals.push(v.key)
        }
      }
      if (errorMessage) {
        current.valid = false
        current.v_required.err = errorMessage
      }
      this.setState({
        [current.key]: vals
      })
    }
  }

  generatePendingApproval(current) {
    return current.pendingApproval ? <p className={css(Styles.pendingApprovalLabel)}>{Helper.getString('pendingAppr')}</p> : ''
  }

  shouldDisable(current) {
    // if (Helper.inArray(['add'], this.props.formDefinition.formType)) return false
    if (current && current.disabled && this.props.formDefinition.formType !== 'add') {
      return true
    }
    if (this.props.formDefinition.disableAlwaysFormFields && this.props.formDefinition.disableAlwaysFormFields.indexOf(current.key) > -1) {
      return true
    }
    if (Helper.inArray(['delete', 'del'], this.props.formDefinition.formType)) {
      return true
    }
    return Helper.inArray(['delete', 'del', 'approve'], this.props.formDefinition.formType) ||
      this.state.inProcess
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomDynamicFormStyles : StyleSheet.create({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // padding: AppTheme.pagePadding,
    '@media (max-width: 320px)': {
      maxWidth: 300,
    }
  },
  leftGridPadding: {
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  rightGridPadding: {
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  errorMsg: {
    paddingRight: 20,
    color: '#f44336'
  },
  successMsg: {
    paddingRight: 20,
    color: 'green'
  },
  marginLeft: {
    color: 'white'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 30
  },
  pendingApprovalLabel: {
    marginTop: 0,
    marginBottom: 0,
    color: 'orange',
    fontSize: 10
  },
  openDoc: {
    marginTop: 0,
    marginBottom: 0,
    color: 'black',
    fontSize: 10,
    cursor: 'pointer',
  },
  uploadFieldLabel: {
    marginTop: 10,
  },
  buttonDelete: {
    backgroundColor: '#FF0D0D'
  },
  label: {
    fontSize: 14,
    color: 'grey',
    margin: 0,
    // marginBottom: 8,
    display: 'flex',
  },
  formControl: {
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  textInput: {
    padding: '4px 0px'
  },
  textInputStyles: {
    padding: '11px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16
  },
  textInputStylesDisabled: {
    backgroundColor: '#f5f5f5',
  },
  pickerStyle: {
    marginTop: 0
  },
  pickerInputStyles: {
    padding: '8px 20px',
    flex: 1,
    textAlign: 'left',
    color: '#222222',
    fontSize: 16,
    borderRadius: AppTheme.borderRadius,
    border: '1px solid #E5E5E5',
    overflow: 'hidden'
  },
  formInputStyle: {
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tag: {
    backgroundColor: AppTheme.secondaryColor,
    color: '#222222',
    fontSize: 13,
    borderRadius: 8,
    height: 26
  },
  popupIndicator: {
    marginRight: 5,
  },
  dialog: {
    zIndex: 130000,
    fontSize: 16,
    color: '#222222',
  },
  paper: {
    borderRadius: AppTheme.borderRadius,
  },

  modalCloseIcon: {
    width: 12,
    height: 12,
    padding: 1,
    borderRadius: 6
  },

  fieldOuterContainer: {
    display: 'flex'
  },
  fieldLeftInnerContainer: {
    flex: 1,
    paddingRight: 10,
    '@media (max-width: 767px)': {
      paddingRight: 0,
    }
  },
  fieldRightInnerContainer: {
    flex: 1,
    paddingLeft: 10,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  delFieldContainer: {
    width: '25%',
    marginRight: 20
  },
  formControlMain: {
    marginTop: 0,
    marginBottom: 0
  },
  optionImageContainer: {
    width: 20,
    height: 20,
  },
  optionImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  optionImageContainerSpec: {
    marginRight: 9,
    marginLeft: 5
  },
  // requiredIndicator: {
  //   display: 'flex',
  //   color: 'grey',
  //   lineHeight: 0,
  //   alignItems: 'center',
  //   fontSize: 13,
  //   justifyContent: 'flex-end',
  //   marginLeft: 4
  // },
  requiredIndicator: {
    opacity: 0.5,
    height: 7,
    width: 7
  },
  labelContainer: {
    display: 'flex',
    marginBottom: 8
  },
  tooltip: {
    zIndex: 9999999999
  },
  autoCompleteInputRoot: {
    paddingRight: 56
  },
  optionLoaderSpinner:{
    marginRight: 15
  },
  addOptionStyle: {
    color: AppTheme.buttonBg
  }
})
