import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import async from 'async';

import { AppTheme, AppStyles } from '../../../styles/AppTheme';
import { BaseEntityPage } from '../../../common/components/BaseEntityPage';
import { Helper } from '../../../common/helpers/Helper';

import { Controller } from '../../../common/Controller';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { AppConfig } from '../../../AppConfig';
import { UiHelper } from '../../../common/helpers/UiHelper';

export class ReportsOrg extends BaseEntityPage {
  constructor(props) {
    super(props);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.pageTitle = 'Reports'
    this.pageKey = 'reportsOrg'
    this.toFetch = [
      { ms: this.ms, method: 'GET', paths: ['miscs', 'cdp-settings'], queryParams: { orgId: this.orgId } },
    ]
    this.noAdd = true
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: this.pageTitle },
    ]
  }

  fetchItems() {
    this.apiStartTs = Helper.currentTs()
    this.setState({
      fetchState: ApiHelper.State.LOADING,
      isOrderChanged: false
    })
    const fcns = [];
    for (const f of this.toFetch) {
      fcns.push(function (callback) {
        ApiHelper.call(f, callback);
      })
    }
    async.parallel(fcns, async function (err, results) {
      if (err) {
        this.setState({
          fetchState: ApiHelper.State.ERROR,
          loadingMode: ApiHelper.LOADING_MODE.PAGE_LOAD,
          errMsg: Helper.getErrorMsg(err)
        })
        return
      }
      if (this.onFetchSuccess) {
        if (this.toFetch.length > 0) {
          Helper.amplifyEvent({
            name: 'apiLoad',
            attributes: { path: this.props.match.path, orgId: this.orgId },
            metrics: { apiTimeMs: Helper.currentTs() - this.apiStartTs }
          })
        }
        await this.onFetchSuccess(results);
      }
    }.bind(this))
  }

  onFetchSuccess(results) {
    const resp = results[0]
    if (!resp || !resp.report) {
      this.setState({
        fetchState: ApiHelper.State.ERROR,
        errMsg: Helper.getString('reportsNotConfigured')
      })
    } else {
      this.setState({
        reportUrl: AppConfig.REPORTS_BASE_URL + resp.report,
        fetchState: ApiHelper.State.READY,
      })
    }
  }

  renderCustomReady() {
    if (!this.state.reportUrl) return
    return (
      <div className={css(AppStyles.container, Styles.container)}>
        <iframe
          id='report'
          width='100%'
          height='100%'
          className={css(Styles.iframe)}
          frameBorder={0}
          src={this.state.reportUrl}>
        </iframe>
      </div>
    );
  }
}

export const Styles = StyleSheet.create({
  iframe: {
    marginTop: -120
  },
})
