import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Controller } from '../Controller';
import { OrgSwitcher } from './OrgSwitcher';
import { DrawerMenuDashboardMobile } from './DrawerMenuDashboardMobile';

export function MobileMoreMenu(props) {

  const [state, setState] = useState({
    settingPopover: null,
    orgSwitcherDialog: false,
    selectedOrgId: Controller.get().roleHelper().getOrgId()
  })

  useEffect(() => {
  }, [])

  const orgSwitcherDialogOpen = () => {
    setState({ ...state, orgSwitcherDialog: true, settingPopover: null });
  }

  const orgSwitcherDialogClose = () => {
    setState({ ...state, orgSwitcherDialog: false, settingPopover: null });
  }

  const onOrgChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const doActionSave = () => {
    Controller.get().roleHelper().setCurrentOrgInfo(state.selectedOrgId)
    setState({ ...state, orgSwitcherDialog: false })
    const pathname = '/admin/orgs/' + state.selectedOrgId + '/crm-contacts'
    window.location.href = pathname
  }

  const menuItems = []

  return (
    <Fragment>
      <DrawerMenuDashboardMobile
        orgSwitcherDialogOpen={orgSwitcherDialogOpen}
        menuItems={menuItems} />
      <OrgSwitcher
        orgSwitcherDialog={state.orgSwitcherDialog}
        orgSwitcherDialogClose={orgSwitcherDialogClose}
        inProcess={state.inProcess}
        selectedOrgId={state.selectedOrgId}
        onOrgChange={onOrgChange}
        doActionSave={doActionSave} />
    </Fragment>
  );
}